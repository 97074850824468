

<section  fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout.gt-sm="row" fxLayout.lt-sm="column">

  <div class="container" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.lt-sm="column">

    <mat-card fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout="column">

      <div class="block-one" fxLayoutAlign="center center" fxLayoutAlign="space-between">
        <div class="blank-space"></div>
        <h2> Terms & Conditions </h2>

        <span>

          <mat-dialog-actions>
              <div class="close-icon" [mat-dialog-close]="true" cdkFocusInitial> <mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></div>
          </mat-dialog-actions>

        </span>
      </div>

      <div class="scroll-block">
        <cdk-virtual-scroll-viewport class="example-viewport" itemSize="20">
          <dl class="example-dl">
            <ng-container *cdkVirtualFor="let term of terms">
              <dt class="heading-text">{{term.heading}}</dt>
              <dd class="paragraph-text">{{term.paragraph}}</dd>
            </ng-container>
          </dl>
        </cdk-virtual-scroll-viewport>
      </div>
      <div class="block-two" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
        <p class="accept" fxFlex="start"> By clicking " I Accept", you agree to the terms and conditions of this contract</p>
        <button class="i-accept-btn" [mat-dialog-close]="false">I ACCEPT</button>
      </div>

    </mat-card>

  </div>
</section>



