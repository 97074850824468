
<section class="container"  fxLayoutAlign="center center"  fxLayoutGap="12px">

  <div class="secondary" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign="space-between">

    <!-- personal  details-->
    <form  autofocusInvalidInput class="est-signup-form" fxLayout="column"  fxLayoutGap="12px" #estateForm="ngForm" (ngSubmit)="(submitForm(estateForm.value, estateForm))">

      <div fxLayoutAlign="start center"><h2>Personal Details</h2></div>


      <div>
        <!-- FIRST NAME-->
        <mat-form-field  appearance="fill">
          <mat-label>First Name</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.first_Name"
            #first_Name="ngModel"
            name="firstName"
            id="firstName"
            minlength="4"/>
          <mat-error> Please enter a valid first name</mat-error>
        </mat-form-field>

        <!-- LAST NAME-->
        <mat-form-field  appearance="fill">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.lastname"
            #lastname="ngModel"
            name="lastName"
            id="lastName"
            minlength="2" />
          <mat-error> Please enter a valid last name</mat-error>
        </mat-form-field>
      </div>


      <div>
        <!-- Personal ID-->
        <mat-form-field  appearance="fill">
          <mat-label>Personal ID Number</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.id_number"
            #id_number="ngModel"
            name="idNumber"
            id="idNumber"
            minlength="13" />
          <mat-error> Please enter an ID number</mat-error>
        </mat-form-field>

        <!-- EMAIL ADDRESS-->
        <mat-form-field appearance="fill">
          <mat-label>Email Address</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.email"
            #email="ngModel"
            name="email"
            id="email"
            minlength="10" />
          <mat-error>Please enter a valid email address</mat-error>
        </mat-form-field>
      </div>

      <div>
        <!-- CELL NUMBER-->
        <mat-form-field  appearance="fill">
          <mat-label>Cell Number</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.cell_number"
            #cell_number="ngModel"
            name="cellNumber"
            id="cellNumber"
            minlength="10" />
          <mat-error>Please enter a cell number</mat-error>
        </mat-form-field>

        <!-- OTHER NUMBER-->
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Other Number</mat-label>
          <input type="text" matInput ngModel name="otherNumber"  minlength="10"/>
        </mat-form-field>
      </div>

      <div>
        <!-- DATE OF BIRTH -->
        <mat-form-field appearance="fill">
          <mat-label>Enter a date of birth</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.date_of_birth"
            #date_of_birth="ngModel"
            name="dateOfBirth"
            id="dateOfBirth"
            [max]="today"
            [matDatepicker]="picker"
            ngModel name="dateOfBirth"/>
          <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>Please enter or choose a valid date of birth</mat-error>
        </mat-form-field>

        <!-- UNIT NO-->
        <mat-form-field  appearance="fill">
          <mat-label>Unit Number</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.address"
            #address="ngModel"
            name="address"
            id="address"
            minlength="1" />
          <mat-error> Please enter a valid unit number</mat-error>
        </mat-form-field>
      </div>


      <div>
        <!-- SELECTED PACKAGE-->
        <mat-form-field  appearance="fill">
          <mat-label>Package Selection</mat-label>
          <textarea matInput disabled style="margin-right: 5px; resize: none;">{{newSubscription.selectedPackage.title}} R{{newSubscription.selectedPackage.price}} </textarea>
          <input matInput disabled ngModel name="selectedPackage" >
        </mat-form-field>
      </div>

     <div *ngIf="!isPackagesCostFree">
      <!-- Banking details section-->
      <div fxLayoutAlign="start center"><h2>Bank Details</h2></div>

      <div>
        <!-- ACCOUNT HOLDER NAME -->
        <mat-form-field  appearance="fill">
          <mat-label>Account Holder Name</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.acc_holder_name"
            #acc_holder_name="ngModel"
            name="accHolderName"
            id="accHolderName"
            minlength="4" />
          <mat-error>Please enter a valid Account holder name</mat-error>
        </mat-form-field>

        <!-- BANK NAME -->
        <mat-form-field  appearance="fill">
          <mat-label>Bank Name</mat-label>
          <input
            matInput
            class="form-control"
            type="text"
            required
            [(ngModel)]="formPayload.bank_name"
            #bank_name="ngModel"
            name="bankName"
            id="bankName"
            minlength="2" />
          <mat-error>Please enter a valid bank name</mat-error>
        </mat-form-field>
        </div>

          <!-- ACCOUNT NUMBER-->
          <div>
            <mat-form-field  appearance="fill">
              <mat-label>Account Number</mat-label>
              <input
                matInput
                class="form-control"
                type="text"
                required
                [(ngModel)]="formPayload.acc_no"
                #acc_no="ngModel"
                name="accNo"
                id="baccNo"
                minlength="10" />
              <mat-error>Please enter a valid account number</mat-error>
            </mat-form-field>

            <!--ACCOUNT TYPE-->
            <mat-form-field appearance="fill">
              <mat-label>Account Type</mat-label>
              <mat-select ngModel name="accType" required>
                <mat-option *ngFor="let bankAccountType of bankAccountTypes" [value]="bankAccountType" >
                  {{ bankAccountType }}
                </mat-option>
              </mat-select>
              <mat-error>Please select a valid bank account type</mat-error>
            </mat-form-field>
          </div>

          <!--BRANCH CODE-->
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Branch Code</mat-label>
              <input
                matInput
                class="form-control"
                type="text"
                required
                [(ngModel)]="formPayload.branch_code"
                #branch_code="ngModel"
                name="branchCode"
                id="branchCode"
                minlength="2" />
              <mat-error>Please enter a valid bank branch number</mat-error>
            </mat-form-field>
          </div>
     </div>
          <div class="verify-notice" >
            <p>*To change your package please go back.</p>
            <p>Please ensure that all details are correct as you will be required to verify them shortly.</p>
          </div>
          <div class="button-container">
            <button class="submit" type="submit"  mat-button>SIGN UP</button>
          </div>


    </form>
  </div>
</section>

