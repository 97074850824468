import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {EstDeviceService} from '../../../services/estDeviceService';

@Component({
  selector: 'app-lev-est-device-options',
  templateUrl: './lev-est-device-options.component.html',
  styleUrls: ['./lev-est-device-options.component.scss']
})
export class LevEstDeviceOptionsComponent implements OnInit {

  public estateLevDevice = [];

  constructor(
    private pick: EstatePickerComponent,
    private estDeviceService: EstDeviceService,
    private Shared: SharedService,
    ) { }

  ngOnInit(): void {
    // load data to be displayed and subcribe to use later
    this.estDeviceService.getCustomerDeviceSelectionInfo()
      .subscribe(data => {
        this.estateLevDevice = data;
      });
  }
  // tslint:disable-next-line:typedef
  onClicked(device) {
    this.returnDevices();
    // setDevice set selected device value to be displayed on form
    this.Shared.setDevice(device);
    this.pick.goToNextLev();
  }
  //  return  string format data from server
  returnDevices(): string {
    return `${this.estateLevDevice[1].img}-${this.estateLevDevice[1].description}-${this.estateLevDevice[1].cost}`;
  }
}
