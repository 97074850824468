import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounce, debounceTime, filter, first, flatMap, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PlanService } from '../services/plans.service';
import { FibreHome } from '../interfaces/fibre-home';
import { CustomerService } from '../services/customer.service';
import { Select, Store } from '@ngxs/store';
import { SetProviders } from '../components/shared/map.actions';
import { SetTariffs, SetTariff, SetSelectedProvider, FilterTariffsByFunc } from '../store/tariffs/tariffs.actions';
import moment from 'moment';
import { Tariff } from '../interfaces/Tariff';
import { TariffsStateModel, TariffsState } from '../store/tariffs/tariffs.state';
import { ProviderStateModel, ProviderState } from '../components/shared/map.state';
import { Address } from '../store/address/Address';
import { Observable, of } from 'rxjs';
import { Promotion } from '../interfaces/Promotion';
import { Providers } from '../interfaces/Providers';
import { TariffWithPromotions } from '../interfaces/TariffWithPromotions';
import { SetError, ClearErrorsByTypeAndStack } from '../store/app/app.actions';
import { AppError, AppState, AppErrors } from '../store/app/app.state';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Error } from '../interfaces/Errors';
import { interval, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
import ResizeObserver from 'resize-observer-polyfill';
import { DialogPosition, MatDialog, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DebitOrderComponent } from '../components/residential/residential/debit-order/debit-order.component';
import { TermsConditionsComponent } from '../components/residential/residential/terms-conditions/terms-conditions.component';
import { SnackBarService } from '../services/snack-bar/snack-bar.service';
import { DocumentsService } from '../services/documents-service/documents.service';
import { BreakpointService } from '../services/breakpoint/breakpoint.service';
import { StepService } from '../services/step.service';
@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss'],
})
export class GenericFormComponent implements OnInit, OnDestroy {
  fno = [];
  showResidential = false;
  showBusiness = false;
  loading = false;
  addressComponents;
  @Select('providers') providers$: Observable<ProviderStateModel>;
  @Select('address') address$: Observable<Address>;
  @Select('tariffs') tariffs$: Observable<TariffsStateModel>;
  // @Select((state) => state.providers.selectedProvider) selectedProvider$: Observable<string>;
  @Select(TariffsState.tariffsByArrayAndFilteredBySelectedProvider) tariffsFilteredBySelectedProvider$: Observable<Tariff[]>;
  @Select(ProviderState.connectivityProviders) connectivityProviders$: Observable<{ home: string[]; business: string[] }>;
  @Select(AppState.errors) errors$: Observable<AppErrors>;
  @Select(TariffsState.selectedProviderUserModified) selectedProviderUserModified$: Observable<boolean>;
  promotionErrors$: Observable<AppError[]>;
  tariffs: TariffsStateModel;
  address: Address;
  providers: ProviderStateModel;
  connectivityProviders: string[];
  promoObject: Promotion;
  tariffsFilteredBySelectedProvider: Tariff[];
  dateOfBirthFormattedSignupForm = null;
  dateOfBirthFormattedBusinessSignupForm = null;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  signupForm = this.fb.group({
    providerSelected: ['', [Validators.required]],
    packageSelectedId: [null, [Validators.required]],
    // packageInstallSelectedIndex: ['', [Validators.required]],
    firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    dateOfBirth: ['', [Validators.required]],
    idNumber: [
      '',
      [Validators.required, Validators.minLength(8), Validators.maxLength(13)],
      // [
      //   Validators.required,
      //   Validators.pattern(
      //     '(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])'
      //   ),
      // ],
    ],
    email: ['', [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(100)]],
    cellNumber: ['', [Validators.required, Validators.pattern(RegExp('[0-9]*')), Validators.minLength(10), Validators.maxLength(10)]],
    otherNumber: [null],
    address: ['', Validators.required],
    hasPromoCode: [false],
    promoCode: [''],
    acceptedTermsAndConditions: [false, [Validators.requiredTrue]],
    acceptedDebitOrderMandate: [false, [Validators.requiredTrue]],
  });

  // tc's and debit order mandate form
  // agreementsForm = this.fb.group({
  //   termsAndConditionsDocument: [null, Validators.required],
  //   debitOrderMandateDocument: [null, Validators.required]
  // });

  bankingForm = this.fb.group({
    accountType: ['', [Validators.required]],
    bankName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    accountHolderName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    accountBranch: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
    accountNumber: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
  });

  documentsForm = this.fb.group({
    hasDocuments: [true, [Validators.required]],
    personalIdentificationDocument: [null],
    proofOfBankAccountDocument: [null],
    proofOfAddressDocument: [null],
  });

  businessSignupForm = this.fb.group({
    providerSelected: ['', [Validators.required]],
    packageSelectedId: [null, [Validators.required]],
    // packageInstallSelectedIndex: [null, [Validators.required]],
    companyRegistrationNumber: [null, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
    companyName: [null, [Validators.required]],
    vatNumber: [null, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
    firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    idNumber: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(100)],
      // [
      //   Validators.required,
      //   Validators.pattern(
      //     '(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])'
      //   ),
      // ],
    ],
    dateOfBirth: ['', [Validators.required]],
    email: ['', [Validators.required]],
    cellNumber: ['', [Validators.required, Validators.pattern(RegExp('[0-9]*')), Validators.minLength(10), Validators.maxLength(10)]],
    otherNumber: [null],
    address: [''],
    acceptedTermsAndConditions: [false, [Validators.requiredTrue]],
    acceptedDebitOrderMandate: [false, [Validators.requiredTrue]],
  });

  businessBankingForm = this.fb.group({
    accountType: ['', [Validators.required]],
    bankName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    accountHolderName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    accountBranch: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
    accountNumber: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(100)]],
  });

  businessDocumentsForm = this.fb.group({
    hasDocuments: [true, [Validators.required]],
    personalIdentificationDocument: [null],
    proofOfBankAccountDocument: [null],
    proofOfAddressDocument: [null],
    companyRegistrationDocument: [null],
  });

  apiError = null;

  preferenceArr = [
    'Clear Access',
    'Vumatel',
    'Vumatelcore',
    'Octotel',
    'MetroFibre Networx',
    'LinkAfrica',
    'Frogfoot',
    'SADV',
    'DNATel',
    'TTConnect',
    'Vodacom',
    'Mtn',
    'OpenServe',
  ];

  preference = {
    residential: [
      { name: 'Clear Access', code: 'clearaccess' },
      { name: 'Vumatel', code: 'vumatelcore' },
      { name: 'Octotel', code: 'octotel' },
      { name: 'MFN / MetroFibre Networx', code: 'metrofibre' },
      { name: 'Link Africa', code: 'linkafrica' },
      { name: 'Frogfoot', code: 'frogfoot' },
      { name: 'SADV', code: 'sadv' },
      { name: 'DNATel', code: 'dnatel' },
      { name: 'TT Connect', code: 'ttconnect' },
      { name: 'Vodacom', code: 'vodacom' },
      { name: 'Supersonic / MTN', code: 'mtn' },
      { name: 'OpenServe', code: 'openserve' },
    ],
    business: [
      { name: 'Clear Access', code: '' },
      { name: 'Link Africa', code: '' },
      { name: 'DFA GPON (ARC International)', code: '' },
      { name: 'DFA Helios', code: '' },
      { name: 'DFA Magellan', code: '' },
      { name: 'MFN', code: '' },
      { name: 'Octotel', code: 'octotel' },
      { name: 'Frogfoot', code: 'frogfoot' },
      { name: 'Liquid Telecoms', code: 'liquidtelecoms' },
      { name: 'Openserve', code: 'openserve' },
      { name: 'Waterfall Access Network', code: 'waterfallaccess' },
    ],
  };

  success = null;
  promoLoading = false;
  loadingProviders = false;
  bankAccountTypes = ['Cheque', 'Savings', 'Transactional'];
  promoPlansIsAdded = false;
  public smallScreen = false;

  public signupSubscription: Subscription;
  public breakpointSubscription: Subscription;

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
    this.signupSubscription.unsubscribe();
  }

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private planService: PlanService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private snackBarService: SnackBarService,
    private documentsService: DocumentsService,
    private breakpointService: BreakpointService,
    private stepService: StepService
  ) {

    // Breakpoint
    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

    // Listen for signup click
    this.signupSubscription = this.stepService
      .signup
      .subscribe(() => {
        this.handleSignup();
      });

    // this.signupForm.controls.providerSelected.valueChanges.subscribe(() => {
    //   this.signupForm.controls.packageSelectedId.reset();
    // });

    // this.businessSignupForm.controls.providerSelected.valueChanges.subscribe(() => {
    //   this.businessSignupForm.controls.packageSelectedId.reset();
    // });

    // Check signup form date and set formatted date for use
    this.signupForm.controls.dateOfBirth.valueChanges.subscribe((dateOfBirth) => {
      const date = new Date(dateOfBirth);
      const formattedDate = date.toISOString().substring(0, date.toISOString().indexOf('T')).split('-').join('/');
      if (!new RegExp('[0-9][0-9][0-9][0-9]/[0-9][0-9]?/[0-9][0-9]?').test(formattedDate)) {
        this.signupForm.controls.dateOfBirth.setErrors({ valid: false });
      }
      this.dateOfBirthFormattedSignupForm = formattedDate;
    });

    // Check business signup form date and set formatted date for use
    this.businessSignupForm.controls.dateOfBirth.valueChanges.subscribe((dateOfBirth) => {
      const date = new Date(dateOfBirth);
      const formattedDate = date.toISOString().substring(0, date.toISOString().indexOf('T')).split('-').join('/');
      if (!new RegExp('[0-9][0-9][0-9][0-9]/[0-9][0-9]?/[0-9][0-9]?').test(formattedDate)) {
        this.businessSignupForm.controls.dateOfBirth.setErrors({ valid: false });
      }
      this.dateOfBirthFormattedBusinessSignupForm = formattedDate;
    });

    this.errors$.subscribe((errors: AppErrors) => {
      Object.keys(errors).forEach((key) => {
        for (let i = 0; i < errors[key].length; i++) {
          this.snackBar.open(errors[key][i].message, 'Dismiss', { duration: errors[key][i].duration });
        }
      });
    });

    this.tariffsFilteredBySelectedProvider$.subscribe((tariffsFiltered) => {
      this.tariffsFilteredBySelectedProvider = tariffsFiltered;
    });

    this.providers$.subscribe((provider28EastInfo) => {
      this.providers = provider28EastInfo;
      if (provider28EastInfo && provider28EastInfo.providers && provider28EastInfo.providers.place) {
        this.signupForm.controls.address.patchValue(provider28EastInfo.providers.place.formatted_address);
        this.businessSignupForm.controls.address.patchValue(provider28EastInfo.providers.place.formatted_address);
      }
    });

    this.tariffs$.subscribe((tariffs) => {
      this.tariffs = tariffs;
    });

    this.address$.subscribe((address) => {
      this.address = address['address'];

      console.log('INPUT OR SELECTED ADDRESS' , address);
    });

    // const services = this.providers.providers.services.filter((service) => service.type === this.providers.providers.selected_service);

    this.route.data.subscribe((routeData) => {
      console.log('Route data!', routeData);
      if (routeData && typeof routeData === 'object' && routeData.hasOwnProperty('formType')) {
        if (routeData.formType === 'residential') {
          this.showResidential = true;
        }
        if (routeData.formType === 'business') {
          this.showBusiness = true;
        }
      } else {
      }
    });
    console.log('set providers... generic form');
    // this.signupForm.controls.packageSelectedId.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   console.log('set providers... generic form subscribe');
    //   //console.log('setproviders packageselected change.');
    //   this.store.dispatch(new SetProviders(this.providers.providers as any));
    //   this.promoObject = null;
    //   this.signupForm.controls.promoCode.setValue('');
    //   this.signupForm.controls.hasPromoCode.setValue(false);
    // });

    this.signupForm.controls.promoCode.valueChanges
      .pipe(
        tap(() => {
          this.promoPlansIsAdded = false;
          // console.log('Promotion value checked');
          // if (!this.signupForm.controls.promoCode.value) {
          //   this.signupForm.get('promoCode').reset(null);
          // }
          this.promoLoading = true;
        }),
        debounceTime(2500)
      )
      .subscribe(
        async (value) => {
          // console.log('subscriptionnn');
          this.promoLoading = false;
          if (value) {
            try {
              await this.applyPromo({ type: 'home' });
              this.promoPlansIsAdded = true;
            } catch (e) {
              this.promoObject = null;
              this.store.dispatch(new FilterTariffsByFunc((tariff: Tariff) => tariff.only_promo === false));
            }
          } else {
            this.promoObject = null;
            this.store.dispatch(new FilterTariffsByFunc((tariff: Tariff) => tariff.only_promo === false));
          }
        },
        (e) => {
          this.promoLoading = false;
        }
      );

    // setTimeout(() => {
    //   console.log('zqc');
    //   if (!(this.tariffs && this.tariffs && this.tariffs.byArray && this.tariffs.byArray.length > 0)) {
    //     const err: AppError = {
    //       color: 'red',
    //       duration: 5000,
    //       message: 'Having trouble contacting the server.. Please try again',
    //       status_code: 0,
    //       type: 'providers',
    //       style: 'snackbar',
    //       title: 'Data',
    //     };
    //     this.store.dispatch(new SetError({ stack: 'default', error: err }));
    //     this.router.navigate(['signup', 'coverage']);
    //   }
    // }, 5000);
  }

  handleIsValidForSignup(): boolean {
    if (this.showBusiness) {
      return this.businessBankingForm.valid && this.businessSignupForm.valid && this.businessDocumentsForm.valid;
    } else if (this.showResidential) {
      return this.bankingForm.valid && this.signupForm.valid && this.documentsForm.valid;
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (
        !(this.tariffs && this.tariffs.byArray.length > 0) ||
        !(
          this.providers &&
          this.providers.connectivityProviders &&
          (this.providers.connectivityProviders.business.length > 0 || this.providers.connectivityProviders.home.length > 0)
        )
      ) {
        this.router.navigate(['signup', 'coverage']);
      }
    }, 5000);

    if (!environment.production) {
      const providers = localStorage.getItem('providers');
      if (providers) {
        const addressObj: Providers = JSON.parse(providers);
        this.signupForm.get('address').setValue(addressObj.place.formatted_address);
      }
      try {
        this.signupForm.setValue(JSON.parse(localStorage.getItem('signupForm')));
        this.bankingForm.setValue(JSON.parse(localStorage.getItem('bankingForm')));
      } catch (e) {
        // no localstorage entry or json parse error.. ignore
      }

    }
    // Only do this the first emit of the observable
    // Since the user could have already changed the value on any future emission.

    this.connectivityProviders$.subscribe((connProviders) => {
      // console.log('connectivityProviders subscribe');
      this.selectedProviderUserModified$.subscribe((isUserModified) => {
        if (connProviders && !isUserModified) {
          // console.log('this connectivity providers zz', connProviders);
          if (this.showResidential && connProviders.home.length > 0) {
            this.signupForm.get('providerSelected').setValue(connProviders.home[0]);
            // console.log('dispatch set selected provider 1');
            // this.store.dispatch(new SetSelectedProvider(connProviders.home[0]));
          } else if (this.showBusiness && connProviders.business.length > 0) {
            // console.log('dispatch set selected provider 2');
            this.businessSignupForm.get('providerSelected').setValue(connProviders.business[0]);
            // this.store.dispatch(new SetSelectedProvider(connProviders.business[0]));
          }
        }
      });
    });

    const observerForm = new ResizeObserver((mutations) => {
      window.parent.postMessage(
        JSON.stringify({ type: 'iframeheight', height: mutations[0].contentRect.height + 60 }),
        window.location !== window.parent.location ? document.referrer : document.location.href
      );
    });

    // start observing

    // console.log(document.getElementById('clearaccess-angular-form'));
    observerForm.observe(document.getElementById('clearaccess-angular-form'));
  }

  async handleSignup(): Promise<void> {
    await this.signup(this.showResidential);
  }
  // Util for showing all errors.
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      // if (control.controls) {
      //   this.markFormGroupTouched(control);
      // }
    });
  }

  private checkIfAllDocumentsAreAdded(documentsForm): boolean {
    for (const key in documentsForm.value) {
      // console.log('documentsform.value: ', documentsForm.value, 'key:', key);
      if (documentsForm.value[key] === null) {
        // console.log('documentsForm.value', documentsForm.value[key]);
        return false;
      }
    }
    return true;
  }

  viewTermsAndConditions(): Promise<boolean> {
    this.dialogRef = this.dialog
    .open(TermsConditionsComponent, {
      disableClose: true,
      // width: '80%',
      height: '80%',
      maxHeight: '475px',
      autoFocus: false
    });

    return new Promise((resolve, reject) => {
      this.dialogRef
      .afterClosed()
      .subscribe((accepted: boolean) => {
        resolve(accepted);
      }, (err) => {
        reject(err);
      });
    });
  }

  viewDebitOrderMandate(): Promise<boolean> {
    this.dialogRef = this.dialog
    .open(DebitOrderComponent, {
      disableClose: true,
      // width: '80%',
      height: '80%',
      maxHeight: '475px',
      autoFocus: false,
      // TODO: assign form data, prob add a debit order date field, save to splynx on banking api or something
      data: {
        accountHolder: this.bankingForm.controls.accountHolderName.value,
        address: this.signupForm.controls.address.value,
        bankName: this.bankingForm.controls.bankName.value,
        branchName: this.bankingForm.controls.accountBranch.value,
        postalCode: this.address.postalCode,
        accountNumber: this.bankingForm.controls.accountNumber.value,
        accountType: this.bankingForm.controls.accountType.value,
        date: moment().format('LL'),
        contactNumber: this.signupForm.controls.cellNumber.value,
        amount: this.tariffs.byId[this.signupForm.controls.packageSelectedId.value].price,
        beneficiaryName: `${this.signupForm.controls.firstName.value} ${this.signupForm.controls.lastName.value}`,
        // contractReferenceNumber: ,
        debitOrderDate: 'on the first date',
        debitOrderDate2: 'on the first day'
      }
    });

    // signupForm = this.fb.group({
    //   providerSelected: ['', [Validators.required]],
    //   packageSelectedId: [null, [Validators.required]],
    //   packageInstallSelectedIndex: ['', [Validators.required]],
    //   firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    //   lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
    //   dateOfBirth: ['', [Validators.required]],
    //   idNumber: [
    //     '',
    //     [Validators.required, Validators.minLength(8), Validators.maxLength(13)],
    //     // [
    //     //   Validators.required,
    //     //   Validators.pattern(
    //     //     '(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])'
    //     //   ),
    //     // ],
    //   ],
    //   email: ['', [Validators.required, Validators.email, Validators.minLength(2), Validators.maxLength(100)]],
    //   cellNumber: ['', [Validators.required, Validators.pattern(RegExp('[0-9]*')), Validators.minLength(10), Validators.maxLength(10)]],
    //   otherNumber: [null],
    //   address: ['', Validators.required],
    //   hasPromoCode: [false],
    //   promoCode: [''],
    //   acceptedTermsAndConditions: [false, [Validators.requiredTrue]],
    //   acceptedDebitOrderMandate: [false, [Validators.requiredTrue]],
    // });

    return new Promise((resolve, reject) => {
      this.dialogRef
      .afterClosed()
      .subscribe((accepted: boolean) => {
        resolve(accepted);
      }, (err) => {
        reject(err);
      });
    });
  }

  continueSignup(signupForm, bankingForm, documentsForm, isHome: boolean): void {
    if (!signupForm.valid || !bankingForm.valid || !documentsForm.valid) {
      const err: AppError = {
        color: 'red',
        duration: 5000,
        message: 'Invalid form. Please fill in all the required fields.',
        status_code: 0,
        type: 'signup',
        style: 'snackbar',
        title: 'Signup error',
      };
      this.store.dispatch(new SetError({ stack: 'default', error: err }));
      this.stepService
      .setLoading(false);
      this.markFormGroupTouched(signupForm);
      this.markFormGroupTouched(bankingForm);
      this.markFormGroupTouched(documentsForm);
      // this.markFormGroupTouched(agreementsForm);
      return;
    }

    // console.log('documentsForm', documentsForm);

    this.loading = true;

    this.address$.subscribe((addr) => {});

    if (!this.address && this.address.city && this.address.province && this.address.geometry) {
      this.stepService
      .setLoading(false);
      throw new Error('Invalid data. Please try again or restart.');
    }

    try {
      const customer = {
        name: signupForm.value.firstName + ' ' + signupForm.value.lastName,
        email: signupForm.value.email,
        phone: signupForm.value.cellNumber,
        login: signupForm.value.email,
        category: 'person',
        hasDocuments: documentsForm.value.hasDocuments,
        street_2: this.address.streetNumber + ' ' + this.address.streetName,
        street_1: this.address.streetNumber + ' ' + this.address.streetName,
        city: this.address.city,
        zip_code: this.address.postalCode,
        streetNumber: this.address.streetNumber,
        streetName: this.address.streetName,
        province: this.address.province,
        postalCode: this.address.postalCode,
        country: this.address.country,
        lat: this.address.geometry.location.lat,
        lng: this.address.geometry.location.lng,
        acceptedTermsAndConditions: signupForm.value.acceptedTermsAndConditions,
        acceptedDebitOrderMandate: signupForm.value.acceptedDebitOrderMandate,
        // termsAndConditionsDocument: agreementsForm.value.termsAndConditionsDocument,
        // debitOrderMandateDocument: agreementsForm.value.debitOrderMandateDocument,
        gps: `${this.address.geometry.location.lat},${this.address.geometry.location.lng}`,
        date_add: moment().format('YYYY-MM-DD'),
        additional_attributes: {
          address_1: this.address.streetNumber + ' ' + this.address.streetName,
          address_2: this.address.streetNumber + ' ' + this.address.streetName,
          full_address: this.signupForm.controls.address.value,
          client_account: this.bankingForm.controls.bankName.value,
          // client_city: this.addressComponents[3].long_name,
          client_city: this.address.city,
          client_company_name: '',
          // client_country: this.addressComponents[6].short_name,
          client_country: this.address.country,
          client_group: 'test',
          client_id: signupForm.controls.idNumber.value,
          client_notes: '',
          // client_postcode: this.addressComponents[7].long_name,
          client_postcode: this.address.postalCode,
          // client_state: this.addressComponents[5].long_name,
          client_state: this.address.province,
          contact_number: signupForm.controls.cellNumber.value,
          contract_id: '',
          createdon: new Date().toLocaleDateString(),
          first_name: signupForm.controls.firstName.value,
          last_name: signupForm.controls.lastName.value,
          mobile: signupForm.controls.cellNumber.value,
          porta_customer_id: '',
          pppoe_login: '',
          pppoe_password: '',
          service_plan: '',
          staticipcpe: '',
          // suburb: this.addressComponents[2].long_name,
          suburb: this.address.suburb,
          taxid: '',
        },
        // custom for email
        // installationOption: signupForm.value.packageInstallSelectedIndex.trim(),
        fibreNetwork: this.tariffs.byId[signupForm.value.packageSelectedId].connectivity_provider,
        packageSelected: this.tariffs.byId[signupForm.value.packageSelectedId].title,
        // address: JSON.stringify(this.address),
        // fibreNetwork: this.currentObject.connectivity_provider,
        // installationOption: this.currentObject.install_options,
        // packageSelected: this.currentObject.title,
      };

      const customerMultiPartFormData = new FormData();

      if (documentsForm.value.hasDocuments) {
        // tslint:disable-next-line:forin
        for (const key in documentsForm.value) {
          customerMultiPartFormData.set(key, documentsForm.value[key]);
        }
      }

      // tc's and debit order mandate documents
      // if (agreementsForm.valid) {
      //   // tslint:disable-next-line:forin
      //   for (const key in agreementsForm.value) {
      //     customerMultiPartFormData.set(key, documentsForm.value[key]);
      //   }
      // }


      const customerInfo = {
        birthday: this.dateOfBirthFormattedSignupForm,
        company_id: '',
        passport: signupForm.controls.idNumber.value,
        vat_id: '',
      };

      const customerPaymentAccounts = {
        field_1: '',
        field_2: '',
        field_3: '',
        field_4: '',
        account_number: bankingForm.controls.accountNumber.value,
        account_holder: bankingForm.controls.accountHolderName.value,
        account_type: bankingForm.controls.accountType.value,
        account_branch: bankingForm.controls.accountBranch.value,
      };

      const customerServices = {
        // description: signupForm.controls.packageSelectedIndex.value,
        // discount: signupForm.controls.promoCode.value,
        // discount: '0', // promo code,
        discount_end_date: '0000-00-00',
        discount_percent: 0,
        discount_start_date: '0000-00-00',
        discount_text: '',
        end_date: '0000-00-00',
        // ipv4: '',
        // ipv4_pool_id: '',
        // ipv4_route: '',
        // ipv6: '',
        // ipv6_delegated: '',
        // login: signupForm.controls.email.value,
        // mac: ,
        // password: '',
        // port_id: ,
        // Make sure this is defined
        services_login: `${this.address.streetNumber}@${this.address.suburb}`,
        quantity: 1,
        start_date: moment().format('YYYY-MM-DD'),
        status: 'pending',
        taking_ipv4: 2,
        tariff_id: signupForm.value.packageSelectedId,
        // contract_term: signupForm.value.packageInstallSelectedIndex.trim(),
        // tariff_id: this.randomArr[signupForm.controls.providerSelectedIndex.value][signupForm.controls.packageSelectedIndex.value].id,
        unit_price: parseFloat(this.tariffs.byId[this.signupForm.controls.packageSelectedId.value].price),
      };

      // This is now a general signup method
      // Thus we need to add some keys if business sign up.
      if (!isHome) {
        customer['additional_attributes']['client_company_name'] = signupForm.value.companyName;
        customer['companyRegistrationNumber'] = signupForm.value.companyRegistrationNumber;
        customer['companyName'] = signupForm.value.companyName;
        customer['vatNumber'] = signupForm.value.vatNumber;
        customerInfo['company_id'] = signupForm.value.companyRegistrationNumber;
        customerInfo['vat_id'] = signupForm.value.vatNumber;
      } else {
        customerServices['discount'] = signupForm.controls.promoCode.value;
      }

      const allFormData = { ...customer, ...customerPaymentAccounts, ...customerInfo, ...customerServices };

      for (const key in allFormData) {
        if (key === 'additional_attributes') {
          // tslint:disable-next-line:forin
          for (const additionalkey in customer[key]) {
            // Still using customer, guess its fine.
            customerMultiPartFormData.set(additionalkey, customer[key][additionalkey]);
          }
        } else {
          customerMultiPartFormData.set(key, allFormData[key]);
          // customerMultiPartFormData.set(key, this.customer[key]);
        }
      }

      if (!environment.production) {
        localStorage.setItem('bankingForm', JSON.stringify(bankingForm.value));
        localStorage.setItem('signupForm', JSON.stringify(signupForm.value));
        // localStorage.setItem('documentsForm', JSON.stringify(documentsForm));

        localStorage.setItem('businessbankingForm', JSON.stringify(this.businessBankingForm.value));
        localStorage.setItem('businessSignupForm', JSON.stringify(this.businessSignupForm.value));
        // localStorage.setItem('businessDocumentsForm', JSON.stringify(this.businessDocumentsForm));
      }

      this.loading = true;
      this.snackBarService.showSnackBar('We are busy signing you up, this could take a while.', 'Please wait', null, null, 2500);

      // Potentially Generate lead
      this.customerService.postCustomer(customerMultiPartFormData).subscribe(
        (data: { id: number, message: string }) => {
          this.loading = false;
          this.success = true;
          // TODO: REDIRECT TO SIGNUP COMPLETE
          console.log('Succesful, redirect to', 'signup/residential/complete');
          this.showResidential = false;
          this.showBusiness = false;
          this.router.navigate(['signup/complete']);
          this.stepService
          .setLoading(false);
          this.snackBar.open(data.message ? data.message : 'Your request has been received. Please check ' + signupForm.value.email + ' for an email regarding further steps.', 'Close', {
            duration: 24000,
          });
        },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.stepService
          .setLoading(false);

          // signupForm.get('promoCode').reset(null);
          const err: AppError = {
            color: 'red',
            duration: 40000,
            message: 'Signup failed',
            status_code: error.status,
            type: 'signup',
            style: 'snackbar',
            title: 'Signup error',
          };
          if (error.status === 404) {
            err['message'] += ': Promotion does not exist';
          }
          if (error.status === 422) {
            if (error.error && error.error.message) {
              if (typeof error.error.message === 'object') {
              } else if (typeof error.error.message === 'string') {
                if (error.error.message.indexOf('unique') > -1) {
                  err['message'] += ': Email address is already in use';
                }
              }
            }
          }
          err['message'] += '. Please call 0129414000 for assistance.';
          this.store.dispatch(new SetError({ stack: 'default', error: err }));
        }
      );
    } catch (e) {
      this.stepService
      .setLoading(false);
      // console.log('e', e);
    }
  }

  async signup(isHome: boolean): Promise<void> {

    this.stepService
      .setLoading(true);

    let signupForm;
    let bankingForm;
    let documentsForm;
    // let agreementsForm;
    if (isHome) {
      signupForm = this.signupForm;
      bankingForm = this.bankingForm;
      documentsForm = this.documentsForm;
      // agreementsForm = this.agreementsForm;
      console.log(documentsForm);

      // this.documentsService.termsAndConditionsDocument
      // .subscribe((tos: File) => {
      //   this.agreementsForm.controls.termsAndConditionsDocument.setValue(tos);
      // });

      // this.documentsService.debitOrderMandateDocument
      // .subscribe((dom: File) => {
      //   this.agreementsForm.controls.debitOrderMandateDocument.setValue(dom);
      // });

      if (documentsForm.value.hasDocuments) {
        // tslint:disable-next-line:forin
        const hasAllRequiredDocuments = this.checkIfAllDocumentsAreAdded(documentsForm);

        if (!hasAllRequiredDocuments) {
          const err: AppError = {
            color: 'red',
            duration: 5000,
            message: 'Please add all the required documents. If you do not have all required documents please un-select the checkbox.',
            status_code: 0,
            type: 'signup',
            style: 'snackbar',
            title: 'Signup error',
          };
          this.store.dispatch(new SetError({ stack: 'default', error: err }));

          this.stepService
          .setLoading(false);

          return;
        }
      }

      // Open documents...
      this.viewTermsAndConditions()
        .then((res: boolean) => {
          if (res) {
            this.viewDebitOrderMandate()
              .then((resDebitOrder) => {
                if (!resDebitOrder) {
                  this.stepService
                  .setLoading(false);
                  return this.snackBarService.showSnackBar('Please press the sign up button again and accept the debit order mandate.');
                }
                // if (this.agreementsForm.valid) {
                this.signupForm.controls.acceptedTermsAndConditions.setValue(true);
                this.signupForm.controls.acceptedDebitOrderMandate.setValue(true);
                this.continueSignup(signupForm, bankingForm, documentsForm, isHome);
                // } else {
                //   this.stepService
                //   .setLoading(false);
                //   return this.snackBarService.showSnackBar('Please press the sign up button again and accept the agreements provided.');
                // }
              });
          } else {
            this.stepService
            .setLoading(false);
            return this.snackBarService.showSnackBar('Please press the sign up button again and accept the terms and conditions.');
          }
        });

    } else {
      signupForm = this.businessSignupForm;
      bankingForm = this.businessBankingForm;
      documentsForm = this.businessDocumentsForm;
      // agreementsForm = this.agreementsForm;

      if (documentsForm.value.hasDocuments) {
        // tslint:disable-next-line:forin
        const hasAllRequiredDocuments = this.checkIfAllDocumentsAreAdded(documentsForm);

        if (!hasAllRequiredDocuments) {
          const err: AppError = {
            color: 'red',
            duration: 5000,
            message: 'Please add all the required documents. If you do not have all required documents please un-select the checkbox.',
            status_code: 0,
            type: 'signup',
            style: 'snackbar',
            title: 'Signup error',
          };
          this.stepService
          .setLoading(false);
          this.store.dispatch(new SetError({ stack: 'default', error: err }));
          return;
        }
      }

      this.continueSignup(signupForm, bankingForm, documentsForm, isHome);
    }

  }

  async applyPromo(event: { type: string }): Promise<void> {
    console.log('######## APPLY PROMO START#######');
    if (event.type === 'home') {
      this.store.dispatch(new ClearErrorsByTypeAndStack({ type: 'promotion', stack: 'default' }));
      this.promoObject = null;
      try {
        const promoResult: TariffWithPromotions = await this.planService
          .checkPromoCode(this.signupForm.value.promoCode, this.signupForm.value.packageSelectedId)
          .toPromise();

        const currPromotion: Promotion = promoResult.promotions[0];
        console.log(currPromotion, '######## CURRENT PROMOTION #######');
// check if not null
        if (promoResult !== null) {
          const newTariff: Tariff = {
            connectivity_provider: promoResult.connectivity_provider,
            price: promoResult.price,
            service_details: promoResult.service_details,
            id: promoResult.id,
            install_options: promoResult.install_options,
            service_type: promoResult.service_type,
            title: promoResult.title,
            old_price: promoResult.price,
            shortcode: promoResult.shortcode,
            promo_code: currPromotion.promo_code,
            promo_offer: currPromotion.promo_offer,
            promo_type: currPromotion.promo_type,
            only_promo: false,
          };
          console.log(newTariff, '######## NEW TARIFF 1 #######');
          if (currPromotion.promo_type === 'price') {
            newTariff.price = currPromotion.price;
          }
          console.log(currPromotion.price, '######## PRICE 1 #######');
          console.log(currPromotion.promo_offer, '######## PROMO OFFER 1 #######');
          const promoObject: Promotion = {
            old_price: promoResult.price,
            price: currPromotion.price,
            promo_code: currPromotion.promo_code,
            promo_offer: currPromotion.promo_offer,
            promo_type: currPromotion.promo_type,
          };
          console.log(currPromotion.price, '######## PRICE 2 #######');
          console.log(promoObject, '######## NEW TARIFF 2 #######');
          if (promoResult) {
            this.promoObject = promoObject;
          }
          console.log('Set tariff 0 !!!');
          this.store.dispatch(new SetTariff(newTariff));
        }
      } catch (e) {
        // console.log('init check failed, checking for promo code plans');
        this.signupForm.get('promoCode').reset('');
        const err: AppError = {
          color: 'red',
          duration: 5000,
          message: 'Promotion failed',
          status_code: e.status,
          type: 'promotion',
          style: 'snackbar',
          title: 'Promotion code error:',
        };
        if (e.status === 404 || e.status === 500) {
          err['message'] += ': Promotion does not exist.';
        }
        this.store.dispatch(new SetError({ stack: 'default', error: err }));
        try {
          // const promotionOnlyPlans = await this.planService.addPromoCodesPlans(this.signupForm.value.promoCode).toPromise();
          // if (promotionOnlyPlans && promotionOnlyPlans.length && promotionOnlyPlans.length > 0) {
          //   const promoObject: Promotion = {
          //     old_price: null,
          //     price: null,
          //     promo_code: promotionOnlyPlans[0].promo_code,
          //     promo_offer: 'Promotion only plans added! Please check above for the new packages.',
          //     promo_type: 'other',
          //   };
          //   this.promoObject = promoObject;
          //   console.log('Set tariffs 1!!!');
          //   this.store.dispatch(new SetTariffs(promotionOnlyPlans));
          // }
        } catch (promotionOnlyPlansError) {
          // // console.log('add promo code plans e', e);
          // this.signupForm.get('promoCode').reset('');
          // const err: AppError = {
          //   color: 'red',
          //   duration: 5000,
          //   message: 'Promotion failed',
          //   status_code: e.status,
          //   type: 'promotion',
          //   style: 'snackbar',
          //   title: 'Promotion code error:',
          // };
          // if (e.status === 404 || e.status === 500) {
          //   err['message'] += ': Promotion does not exist.';
          // }
          // this.store.dispatch(new SetError({ stack: 'default', error: err }));
          // throw promotionOnlyPlansError;
        }
      }
    }
  }

  getBirthdayFromId(id): string {
    const rawDate = id.slice(0, 6);

    let year = '';
    let month = '';
    let day = '';
    let dob = '';

    // year

    const yearId = rawDate[0] + rawDate[1];
    const yearDate = new Date().getFullYear().toString();

    if (yearId > yearDate[2] + yearDate[3]) {
      year = `19${yearId}`;
    } else {
      year = `20${yearId}`;
    }

    // month

    month = rawDate[2] + rawDate[3];

    // day

    day = rawDate[4] + rawDate[5];

    // build string and return

    dob = `${year}/${month}/${day}`;
    return dob;
  }
}
