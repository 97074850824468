<section  fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout.gt-sm="row" fxLayout.lt-sm="column">

  <div class="container" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.lt-sm="column">

    <mat-card fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout="column">

      <div class="block-one" fxLayoutAlign="center center" fxLayoutAlign="space-between">
        <div class="blank-space"></div>
        <h2> Online Maintenance </h2>
        <span>
          <div class="close-icon"> <mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></div>
        </span>
      </div>

      <div class="scroll-block" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.lt-sm="column">
          <dl class="example-dl">
            <ng-container fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout.gt-sm="row" fxLayout.lt-sm="column" >
            <p>We are experiencing technical difficulties with our online sign-ups. Our technical team is working around the clock and our online sign-ups will be back up and running.</p>
            <p>This does not affect our network and you will still experience fast and reliable internet. In the meantime, if you would like to sign up for any of our fibre packages, we have our friendly staff standing by for your call.</p>
            <p> You can call us on  <span class="number"> 012 941 4000</span> <span> or email </span> <span class="email"> sales@clearaccess.co.za.</span> </p>
            <p>Apologies for any inconvenience.</p>
            </ng-container>
          </dl>
      </div>
    </mat-card>

  </div>
</section>




