import { Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-evotel-promo',
  templateUrl: './evotel-promo.component.html',
  styleUrls: ['./evotel-promo.component.scss']
})
export class EvotelPromoComponent implements OnInit {

  @Input() type: string;
  mobile = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRefThree: MatDialogRef<EvotelPromoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // // detect screen size changes
    // this.breakpointObserver.observe([
    //   '(width <= 1200px)'
    // ]).subscribe((result: BreakpointState) => {
    //   if (result.matches) {
    //     this.mobile = false;
    //   } else {
    //     this.mobile = true;
    //   }
    // });
  }

  ngOnInit(): void {

  }

  closeDialog(): void {
    this.dialogRefThree.close();
  }
}
