<div class="spacer"></div>
<h2 fxLayoutAlign="center center" >Choose a device option:</h2>
<section  fxLayoutAlign="center center" fxLayout.lt-md="column">
  <div *ngFor="let device of estateLevDevice;" class="grid-container" fxLayoutAlign="center center"  fxLayout.lt-md="column">
    <div fxLayoutGap="5px" fxLayoutAlign="center center" fxLayoutAlign="space-between"  >
      <div class="card-container" fxLayout.lt-md="column">
        <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">
          <img src="{{device.img}}" alt="tp-link router option one WR850N">
          <mat-card-subtitle>{{device.description}}</mat-card-subtitle>
          <h2 class="price">{{device.cost}}</h2>
          <button mat-button (click)="onClicked(device)">SELECT</button>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<div class="spacer"></div>

<!--<div class="spacer"></div>-->
<!--<h2 fxLayoutAlign="center center" >Choose a device option:</h2>-->
<!--<section fxLayoutAlign="center center" >-->
<!--  <div class="grid-container" fxLayoutAlign="center center" >-->
<!--    <div fxLayoutGap="50px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-sm="row" fxLayout.lt-sm="column">-->
<!--      <div class="card-container">-->
<!--        <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">-->
<!--          <img src="assets/tplinkwr850n.jpg" alt="tp-link router option one WR850N">-->
<!--          <mat-card-subtitle>TP-link WR850N</mat-card-subtitle>-->
<!--          <h2 class="price">R499 </h2>-->
<!--          <button mat-button (click)="goNext()">SELECT</button>-->
<!--        </mat-card>-->
<!--      </div>-->
<!--      <div>-->
<!--        <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">-->
<!--          <img src="assets/tplinkarcherc5.jpg" alt="tp-link router option two Archer C5">-->
<!--          <mat-card-subtitle>TP-link Archer C5</mat-card-subtitle>-->
<!--          <h2 class="price">R999</h2>-->
<!--          <button mat-button (click)="goNext()">SELECT</button>-->
<!--        </mat-card>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->
<!--<div class="spacer"></div>-->

