import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {EstCustomerInterface} from '../../../interfaces/estCustomerInterface';
import {EstCustomerService} from '../../../services/estCustomer.service';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {CustomerServices} from '../../../interfaces/CustomerServices';
@Component({
  selector: 'app-lev-est-debitorder-mandate',
  templateUrl: './lev-est-debitorder-mandate.component.html',
  styleUrls: ['./lev-est-debitorder-mandate.component.scss']
})
export class LevEstDebitorderMandateComponent implements OnInit {
  todayDate: Date = new Date();
  newSubscription: EstCustomerInterface;

  private id: number;
  private body: CustomerServices;

  constructor(
    private pick: EstatePickerComponent,
    private location: Location,
    private estCustomerService: EstCustomerService,
    public Shared: SharedService,
    ) { }

  ngOnInit(): any{

    this.newSubscription = this.Shared.getNewSubscription();
  }
  // tslint:disable-next-line:typedef
  onClicked(data: EstCustomerInterface) {
  }
  back(): void {
    this.location.back();
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy(): void {
  }

}
