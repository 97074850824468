import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';



import {catchError, retry} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class EstCustomerService {
  constructor(
    public http: HttpClient,
  ) {
  }

  baseUrl = environment.baseUrl + '/customers/estate';


// tslint:disable-next-line:typedef
postFormData(estCustomer: any) {
  return this.http.post(this.baseUrl, estCustomer)
      .pipe( catchError(this.handleError) );
  }

  // tslint:disable-next-line:typedef
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; Could not post Signup form, please try again later.'));
  }

}

