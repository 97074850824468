import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CentEstDebitorderMandateComponent} from '../cent-est-debitorder-mandate/cent-est-debitorder-mandate.component';
import {CentEstTermsCoComponent} from '../cent-est-terms-co/cent-est-terms-co.component';
import {EstCustomerInterface} from '../../../interfaces/estCustomerInterface';
import {EstCustomerService} from '../../../services/estCustomer.service';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {SMSPortalService} from '../../../services/SMSPortalService';
import moment from 'moment';
import {SmsOTPGenerateService} from '../../../services/smsOTPGenerate.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';




@Component({
  selector: 'app-cent-est-signup',
  templateUrl: './cent-est-signup.component.html',
  styleUrls: ['./cent-est-signup.component.scss']
})
export class CentEstSignupComponent implements OnInit {
  max: Date | null;
  today = new Date();
  formattedBirthdayDate: string;
  todayFormatDate: string;
  isPackagesCostFree = false;
  newSubscription: EstCustomerInterface;


  @Output() tcExit = new EventEmitter();
  @Output() domExit = new EventEmitter();
  @Input() bankAccountTypes: string[] = ['Cheque', 'Savings', 'Transactional'];
  tcDialogData: any;

  form: any;


  estatesForm: FormGroup;
  formPayload = {
    first_Name: '',
    lastname: '',
    id_number: '',
    email: '',
    cell_number: '',
    date_of_birth: '',
    address: '',
    acc_holder_name: '',
    bank_name: '',
    acc_no: '',
    acc_type: '',
    branch_code: '',
  };


  formIsValid = false;
  snackbarInvalidNotify: any;


  constructor(
    private pick: EstatePickerComponent,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private estCustomerService: EstCustomerService,
    private Shared: SharedService,
    private sMSPortalService: SMSPortalService,
    private smsOTPGenerateService: SmsOTPGenerateService,
    private fb: FormBuilder,
    private snackBarInvalidField: MatSnackBar,
  ) {
    this.estatesForm = this.fb.group({
      first_Name: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      lastname: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      id_number: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      cell_number: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      date_of_birth: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      address: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      acc_holder_name: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      bank_name: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      acc_no: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      acc_type: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$')
        ]
      ],
      branch_code: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z-,]+(s{0,1}[a-zA-Z-, ])*$'),
        ]
      ],
    });
  }

  // tslint:disable-next-line:typedef
  get formControls() {
    return this.estatesForm.controls;
  }

  ngOnInit(): void {
    // initialize selected data gathered from package and device selection to be displayed
    this.Shared.mandateInfo.subscribe(
      data =>
        this.Shared.setFormMandate(this.newSubscription)
    );
    this.newSubscription = this.Shared.getNewSubscription();

    this.today = new Date();
    this.today.setFullYear(this.today.getFullYear());
    this.todayFormatDate = moment(this.today).format('yyyy-MM-DD');


    // Hide Banking details if package selected is null
    if (this.newSubscription.selectedPackage.price === 0) {
      this.isPackagesCostFree = true;
    }
  }
  // tslint:disable-next-line:typedef
  submitForm(data: EstCustomerInterface, estateForm) {

    if (estateForm.invalid) {
      this.snackBarInvalidField.open('Please ensure all required fields have been filled', '', {
        duration: 3000
      });
    } else if (estateForm.valid) {
      // update , set data from previous selections to be displayed on form  and submit form with data added
      data.selectedDevice = this.newSubscription.selectedDevice;
      data.selectedPackage = this.newSubscription.selectedPackage;
      data.estateOptions = this.newSubscription.estateOptions;
      this.Shared.setFormMandate(data);
      this.openDialog();
    }
  }
  // OPENS T&C DIALOG
  // tslint:disable-next-line:typedef
  openDialog()
{
  const dialogRef = this.dialog.open(CentEstTermsCoComponent,
    {
      height: 'auto',
      width: '100%',
      data: {
        tcDialogData: this.tcDialogData
      }
    });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.tcExit.emit();
    } else if (this.newSubscription.selectedPackage.price === 0) {
      this.goToverify();
    } else {
      this.continueToDebit();
    }
  });
}

// OPENS DEBIT ORDER MANDATE DIALOG
  // tslint:disable-next-line:typedef
  private continueToDebit()
{
  const dialogRef = this.dialog.open(CentEstDebitorderMandateComponent,
    {
      height: 'auto',
      width: '100%',
      data: {
        tcDialogData: this.tcDialogData
      }
    });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.domExit.emit();
    } else {
      this.goToverify();
    }
  });
}

  // tslint:disable-next-line:typedef
  goToverify() {

// build up form payload data to be sent
    this.formattedBirthdayDate = moment(this.newSubscription.customerDetails.dateOfBirth).format('yyyy-MM-DD');

    const form = {
      // personal info
      firstName: this.newSubscription.customerDetails.firstName,
      lastName: this.newSubscription.customerDetails.lastName,
      idNumber: this.newSubscription.customerDetails.idNumber,
      email: this.newSubscription.customerDetails.email,
      cellNumber: this.newSubscription.customerDetails.cellNumber,
      otherNumber: this.newSubscription.customerDetails.otherNumber,
      dateOfBirth: this.formattedBirthdayDate,
      address: this.newSubscription.customerDetails.address,
      estateId: this.newSubscription.selectedEstate.id,

      // selection details
      selected_package_id: this.newSubscription.selectedPackage.id,
      selected_device_id: this.newSubscription.selectedDevice.id,
      install_option_id: this.newSubscription.InstallOption.id,

      // banking details input, if package selected is R0  no input or debit mandate is required , set banking details to empty string
      // tslint:disable-next-line:max-line-length
      acc_holder_name: this.newSubscription.bankingDetails.accHolderName ? this.newSubscription.bankingDetails.accHolderName : this.newSubscription.customerDetails.firstName + ' ' + this.newSubscription.customerDetails.lastName,
      bankName: this.newSubscription.bankingDetails.bankName ? this.newSubscription.bankingDetails.bankName : ' ',
      accNo: this.newSubscription.bankingDetails.accNo ? this.newSubscription.bankingDetails.accNo : ' ',
      accType: this.newSubscription.bankingDetails.accType ? this.newSubscription.bankingDetails.accType : ' ',
      branchCode: this.newSubscription.bankingDetails.branchCode ? this.newSubscription.bankingDetails.branchCode : ' ',

      // additional details
      price: this.Shared.newSubscription.selectedPackage.price,
      date: this.todayFormatDate,
      estate_name: this.newSubscription.selectedEstate.fullname,
      postal_code: this.newSubscription.selectedEstate.postal_code,
      selected_device: this.newSubscription.selectedDevice.description,
    };

    // Generate random OTP number five digits
    this.smsOTPGenerateService.generateOTP(this.newSubscription.customerDetails.cellNumber, this.newSubscription.customerDetails.firstName, this.newSubscription.customerDetails.lastName);
    // keep state to carry data of form to next page
    if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-signup') {
      this.router.navigate(['/estate-picker/cent-est-verification'], {
        relativeTo: this.route,
        state: {newSubscription: form}
      });
    }
  }
}


