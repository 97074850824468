<section class="package-Options"  fxLayout="column" fxLayoutAlign="center center"  fxLayoutGap="12px">
  <h1>Choose to stay on your selected package or choose an upgrade</h1>

  <mat-card *ngFor="let plan of estateCentDevice;" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-md="row" fxLayout.lt-md="column">

    <div class="title-container">
      <div  class="title-sec-container"  >
        <h2  fxLayoutAlign="start center"  fxLayoutAlign.lt-md="center center" class="title" fxLayout.gt-sm="row">{{plan.title}}</h2>
      </div>
    </div>

    <div class="description-container"  fxLayout="column">
      <div>
        <p fxLayout="column">{{plan.service_details}}</p>
      </div>
    </div>

    <div class="price-container">
      <div fxLayoutAlign="center center" class="price-sec-container">
        <h2 class="price">R{{plan.price}}</h2>
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <button class="select" mat-button  (click)="onClicked(plan)">SELECT</button>
    </div>

  </mat-card>

</section>
<div class="Vat" fxLayoutAlign="start">
  <p class="Vat">*All prices include VAT</p>
</div>


