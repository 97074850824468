import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProviderStateModel } from '../shared/map.state';
import { Tariff } from 'src/app/interfaces/Tariff';
import { SetSelectedProvider, SetSelectedProviderByUser } from '../../store/tariffs/tariffs.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit {
  @Input() signupForm: FormGroup;
  @Input() bankingForm: FormGroup;
  @Input() documentsForm: FormGroup;
  @Input() providers: ProviderStateModel;
  @Input() packageOptions: Tariff[];
  @Input() bankAccountTypes: string[];
  @Output() provider = new EventEmitter<string>();
  selectedPackageIdx = null;
  constructor(private store: Store) {}

  setSelectedProvider(): void {
    // const that = this;
    this.signupForm.controls.packageSelectedId.reset();

    this.store.dispatch(new SetSelectedProviderByUser(this.signupForm.value.providerSelected));
  }

  bankInfoValid(): boolean {
    return (
      this.bankingForm.controls.accountType.valid &&
      this.bankingForm.controls.bankName.valid &&
      this.bankingForm.controls.accountBranch.valid &&
      this.bankingForm.controls.accountHolderName.valid &&
      this.bankingForm.controls.accountNumber.valid
    );
  }

  allIsValid(): boolean {
    return (
      this.bankingForm.valid &&
      this.documentsForm.valid &&
      this.signupForm.controls.firstName.valid &&
      this.signupForm.controls.lastName.valid &&
      this.signupForm.controls.email.valid &&
      this.signupForm.controls.cellNumber.valid &&
      this.signupForm.controls.providerSelected.valid &&
      this.signupForm.controls.packageSelectedId.valid &&
      this.signupForm.controls.companyRegistrationNumber.valid &&
      this.signupForm.controls.companyName.valid &&
      this.signupForm.controls.vatNumber.valid
    );
  }

  onFileChange(event): void {
    const reader = new FileReader();

    this.documentsForm.patchValue({
      [event.target.name]: event.target.files[0],
    });
  }

  packageSelectionValid(): boolean {
    // return this.signupForm.controls.packageSelectedIndex.valid && this.signupForm.controls.packageInstallSelectedIndex.valid;
    return true;
  }

  ngOnInit(): void {}
}
