<div class="__clear-access-form-container">
  <mat-card
    class="bg-color flex ca__form-layer"
    id="clearaccess-angular-form"
    style="padding: 0 !important"
    [ngStyle]="showBusiness ? 'max-width: 1000px; margin: 0 auto; display: block; background: #12122e' : ''"
  >
  <!-- padding: 12px 30px 30px 30px;  -->
    <img
      *ngIf="showBusiness"
      id="logoImage"
      data-lazysrc="https://s3-us-west-2.amazonaws.com/formgetassets/form-images/header/20199_h_form_421059.png"
      style="width: 100%"
      src="https://s3-us-west-2.amazonaws.com/formgetassets/form-images/header/20199_h_form_421059.png"
    />

    <div style="display: flex; justify-content: flex-start; width: 100%; height: 100%; flex-direction: column;">
      <ng-container *ngIf="connectivityProviders$ | async as connectivityProviders">
        <app-sign-up
          *ngIf="showResidential"
          [promoObject]="promoObject"
          [providers]="connectivityProviders.home"
          [packageOptions]="tariffsFilteredBySelectedProvider"
          [bankingForm]="bankingForm"
          [signupForm]="signupForm"
          [documentsForm]="documentsForm"
          [agreementsForm]="agreementsForm"
          [promoLoading]="promoLoading"
          [promoPlansIsAdded]="promoPlansIsAdded"
          [bankAccountTypes]="bankAccountTypes"
          (applyPromoEvent)="applyPromo($event)"
        >
        </app-sign-up>
        <app-business
          *ngIf="showBusiness"
          [bankAccountTypes]="bankAccountTypes"
          [packageOptions]="tariffsFilteredBySelectedProvider"
          [providers]="connectivityProviders.business"
          [bankingForm]="businessBankingForm"
          [signupForm]="businessSignupForm"
          [documentsForm]="businessDocumentsForm"
        >
        </app-business>

        <!-- <span style="border: 2px solid lightblue !important; margin-top: 15px; margin-bottom: 15px !important"></span> -->

        <!-- <div *ngIf="!loading && !success" [style.width]="smallScreen ? '100%' : '87%'" style="display: flex; justify-content: flex-end;">
          <button
            mat-raised-button
            class="signup-button"
            (click)="handleSignup()"
            style="padding: 0px; width: 100px; font-weight: bold; font-size: 14px; color: rgb(255, 255, 255); background-color: #3380F5;"
          >
            SIGN UP
          </button>
        </div> -->
        <!-- <div *ngIf="loading" style="text-align: center" class="ca__loading">
          <mat-spinner class="ca__loading__spinner"></mat-spinner>
          <h4 class="ca__loading__text" style="color: white">Loading... Please wait</h4>
        </div>
        <div *ngIf="success" style="text-align: center">
          <span style="color: white"
            >Success ! Please check {{ showResidential ? signupForm.value.email : businessSignupForm.value.email }} for your details.</span
          >
        </div> -->
      </ng-container>
      <!-- <ng-template #loadingOrError>
        <div *ngIf="!apiError" class="ca__loading">
          <mat-spinner class="ca__loading__spinner"></mat-spinner>
          <h3 class="ca__loading__text">Loading... Please wait</h3>
        </div>
      </ng-template> -->
      <!-- <div *ngIf="apiError">
        <h4>Sorry, there was an unexpected error.</h4>
        <h5>Please try again later.</h5>
      </div> -->
    </div>
    <!-- <ng-template #noData style="color: white">
      <div>
        <h4>Sorry, there was an unexpected error.</h4>
        <h5>Please try again later.</h5>
      </div>
    </ng-template> -->
    <!-- <button mat-button class="clearaccess-button back-to-map" routerLink="/signup/coverage"><mat-icon>arrow_back</mat-icon> Back to coverage map</button> -->
  </mat-card>
</div>
