<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3vw" class="dialog-big">
  <div class="second-container-big">
    <div class="image-cont-big">
      <div class="svg-big">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 20 20" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  </div>
</div>


<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3vw" class="dialog-small">
  <div class="second-container-small">
    <div class="image-cont-small">
      <div class="svg-small">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 20 20" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
  </div>
</div>
