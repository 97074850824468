<h1 class="sign-up-header">Sign up form</h1>
<p class="fibre-provider-text">Please Choose your Fibre Provider</p>
<form [formGroup]="signupForm" class="clear_access_form" style="flex-direction: column">
  <mat-form-field appearance="fill">
    <mat-label>Provider</mat-label>
    <mat-select (selectionChange)="setSelectedProvider()" formControlName="providerSelected" name="provider">
      <mat-option *ngFor="let provider of providers" [value]="provider">
        {{ provider[0].toUpperCase() + provider.slice(1, provider.length) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span class="text-white" style="margin-top: 0px; margin-bottom: 8px; text-transform: capitalize"
    >Clear Access FTTH @ {{ signupForm.controls.providerSelected.value }}</span
  >

  <div style="width: 100%">
    <mat-radio-group
      (change)="signupForm.controls.packageInstallSelectedIndex.reset(); signupForm.controls.packageInstallSelectedIndex.markAsTouched()"
      formControlName="packageSelectedId"
      aria-labelledby="example-radio-group-label"
    >
      <mat-card [attr.radio-data-selected]="selectedPackageIdx" class="card__form package-container" *ngFor="let option of packageOptions; index as i">
        <mat-radio-button [attr.data-tariff-id]="option.id" class="example-radio-button" (change)="selectedPackageIdx = i" [value]="option.id">
          {{ option.service_details }} <span *ngIf="option.old_price" style="text-decoration: line-through">R{{ option.old_price }}</span> R{{ option.price }}
        </mat-radio-button>
      </mat-card>
      <mat-error *ngIf="!signupForm.controls.packageSelectedId.valid && signupForm.controls.packageSelectedId.touched">
        Please select a valid package</mat-error
      >
    </mat-radio-group>

    <div style="margin-top: 20px">
      <span class="text-white" style="margin-top: 25px; margin-bottom: 8px">Installation Options</span>

      <mat-radio-group
        *ngIf="packageOptions[selectedPackageIdx]"
        formControlName="packageInstallSelectedIndex"
        aria-labelledby="example-radio-group-label-package-install"
      >
        <mat-card
          *ngFor="let installOption of packageOptions[selectedPackageIdx].install_options.split(','); index as i"
          class="card__form"
          style="margin: 5px !important"
        >
          <mat-radio-button [value]="installOption" class="example-radio-button"> {{ installOption }} </mat-radio-button>
        </mat-card>
        <mat-error *ngIf="!signupForm.controls.packageInstallSelectedIndex.valid && signupForm.controls.packageInstallSelectedIndex.touched">
          Please select a valid install option</mat-error
        >
      </mat-radio-group>
    </div>
  </div>

  <span style="border: 2px solid lightblue !important; margin-top: 15px; margin-bottom: 15px !important"></span>

  <div class="clear_access_form form_personal_details" style="width: 100%">
    <div class="field-container" #cardNumberForm class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="companyName" required />
        <mat-error> Please enter a valid Company name</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" #cardNumberForm class="input__half__width no-margin-right">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Company Registration Number</mat-label>
        <input matInput formControlName="companyRegistrationNumber" required />
        <mat-error> Please enter a valid Company Registration Number</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" #cardNumberForm class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Company VAT Number</mat-label>
        <input matInput formControlName="vatNumber" required />
        <mat-error> Please enter a valid VAT Number</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" #cardNumberForm class="input__half__width no-margin-right">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" required />
        <mat-error> Please enter a valid first name</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput formControlName="lastName" required />
        <mat-error> Please enter a valid last name</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width no-margin-right">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Personal ID Number</mat-label>
        <input type="text" matInput formControlName="idNumber" required />
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Enter a date of birth</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint class="ca__form-hint">
          <span>Format: dd/mm/yyyy</span>
          <span>&nbsp;Example: 8/8/1990</span>
        </mat-hint>
        <mat-error>Please enter or choose a valid date of birth</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width no-margin-right">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Email Address</mat-label>
        <input type="text" matInput formControlName="email" required />
        <mat-error> Please enter a valid Email Address</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Cell Number</mat-label>
        <input type="text" matInput formControlName="cellNumber" required />
        <mat-error> Please enter a valid cell number</mat-error>
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width no-margin-right">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Other Number</mat-label>
        <input type="text" matInput formControlName="otherNumber" />
      </mat-form-field>
    </div>

    <div class="field-container" class="input__half__width">
      <mat-form-field class="w-10" appearance="fill">
        <mat-label>Address</mat-label>
        <textarea type="text" matInput formControlName="address" readonly required></textarea>
        <mat-hint class="ca__form-hint"
          >This address is automatically set based on your address input earlier. To change it please go back to the coverage map.
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>

<form [formGroup]="bankingForm" class="clear_access_form" *ngIf="packageSelectionValid()">
  <span style="border: 2px solid lightblue !important; margin-top: 15px; margin-bottom: 15px !important; display: block; width: 100%"></span>
  <div style="display: flex; flex-direction: column">
    <span class="text-white" style="margin-top: 10px; margin-bottom: 8px; text-transform: capitalize"> Type of Bank Account </span>
    <mat-form-field appearance="fill">
      <mat-label>Account Types</mat-label>
      <mat-select formControlName="accountType" name="accountTypes">
        <mat-option *ngFor="let bankAccountType of bankAccountTypes" [value]="bankAccountType">
          {{ bankAccountType }}
        </mat-option>
      </mat-select>
      <mat-error>Please select a valid bank account type</mat-error>
    </mat-form-field>
  </div>

  <div class="clear_access_form bank__form--after-select" *ngIf="bankingForm.controls.accountType.valid">
    <div class="bankinfo__form">
      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Bank Name</mat-label>
          <input type="text" matInput formControlName="bankName" required />
          <mat-error>Please enter a valid bank name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Account Holder Name</mat-label>
          <input type="text" matInput formControlName="accountHolderName" required />
          <mat-error>Please enter a valid Account holder name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Branch Code</mat-label>
          <input type="text" matInput formControlName="accountBranch" required />
          <mat-error>Please enter a valid bank branch number</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Account Number</mat-label>
          <input type="text" matInput formControlName="accountNumber" required />
          <mat-error>Please enter a valid account number</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
<form class="clear_access_form" style="flex-direction: column" [formGroup]="documentsForm" *ngIf="bankInfoValid()">
  <!-- Bank + attachments -->
  <span style="border: 2px solid lightblue !important; margin-top: 15px; margin-bottom: 15px !important; display: block; width: 100%"></span>
  <span class="text-white" style="margin-top: 25px; margin-bottom: 8px; text-transform: capitalize"> Additional Documents</span>
  <mat-card class="documents-checkbox-container">
    <mat-checkbox class="documents-checkbox" formControlName="hasDocuments" value="true">I have all my documents ready to attach</mat-checkbox>
  </mat-card>
  <mat-hint class="ca__form-hint" style="margin-bottom: 1.34375em; display: block; margin-top: 0.4em"
    >If you dont have your documents now we will contact you for them.</mat-hint
  >
  <div class="bankingform__documents;" style="width: 100%; margin-bottom: 24px" *ngIf="documentsForm.controls.hasDocuments.value">
    <!-- personal identification -->
    <div class="bankingform__fileinput-container">
      <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
        Personal Identification Document
      </label>
      <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
        <mat-icon *ngIf="!documentsForm.controls.personalIdentificationDocument.value" class="bankingform__upload-icon" aria-hidden="true">publish</mat-icon>
        <mat-icon *ngIf="documentsForm.controls.personalIdentificationDocument.value">check_circle</mat-icon>
      </label>
      <input (change)="onFileChange($event)" name="personalIdentificationDocument" id="file-upload-id" class="bankingform__fileinput-input" type="file" />
    </div>

    <!-- proof of bank account -->
    <div class="bankingform__fileinput-container">
      <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
        Proof of Bank Account
      </label>
      <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
        <mat-icon *ngIf="!documentsForm.controls.proofOfBankAccountDocument.value" class="bankingform__upload-icon" aria-hidden="true">publish</mat-icon>
        <mat-icon *ngIf="documentsForm.controls.proofOfBankAccountDocument.value">check_circle</mat-icon>
      </label>
      <input (change)="onFileChange($event)" name="proofOfBankAccountDocument" id="file-upload-account" class="bankingform__fileinput-input" type="file" />
    </div>

    <!-- proof of address -->
    <div class="bankingform__fileinput-container">
      <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom"> Proof of Address </label>
      <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
        <mat-icon *ngIf="!documentsForm.controls.proofOfAddressDocument.value" class="bankingform__upload-icon" aria-hidden="true">publish</mat-icon>
        <mat-icon *ngIf="documentsForm.controls.proofOfAddressDocument.value">check_circle</mat-icon>
      </label>
      <input (change)="onFileChange($event)" name="proofOfAddressDocument" id="file-upload-address" class="bankingform__fileinput-input" type="file" />
    </div>

    <!-- company registration document -->
    <div class="bankingform__fileinput-container">
      <label for="file-upload-company" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
        Company Registration Document
      </label>
      <label for="file-upload-company" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
        <mat-icon *ngIf="!documentsForm.controls.companyRegistrationDocument.value" class="bankingform__upload-icon" aria-hidden="true">publish</mat-icon>
        <mat-icon *ngIf="documentsForm.controls.companyRegistrationDocument.value">check_circle</mat-icon>
      </label>
      <input (change)="onFileChange($event)" name="companyRegistrationDocument" id="file-upload-company" class="bankingform__fileinput-input" type="file" />
    </div>
  </div>
</form>
<form [formGroup]="signupForm" class="clear_access_form" *ngIf="allIsValid()">
  <mat-card class="field-container" style="margin-bottom: 32px">
    <mat-checkbox formControlName="acceptedTermsAndConditions" class="input__full__width bankingform__checkbox">
      I agree to the standard Terms and Conditions
      <a target="_blank" href="https://www.clearaccess.co.za/legal/terms-and-conditions">(https://www.clearaccess.co.za/legal/terms-and-conditions)</a>
    </mat-checkbox>
  </mat-card>
  <mat-error *ngIf="!signupForm.controls.acceptedTermsAndConditions.valid && signupForm.controls.acceptedTermsAndConditions.touched">
    Please accept the terms and conditions.</mat-error
  >
  <mat-card class="field-container">
    <mat-checkbox formControlName="acceptedDebitOrderMandate">
      I agree to the Debit Order Mandate
      <a target="_blank" href="https://www.clearaccess.co.za/legal/debit-order-mandate">(https://www.clearaccess.co.za/legal/debit-order-mandate).</a>
    </mat-checkbox>
  </mat-card>
  <mat-error *ngIf="!signupForm.controls.acceptedDebitOrderMandate.valid && signupForm.controls.acceptedDebitOrderMandate.touched">
    Please accept the debit order mandate.</mat-error
  >
</form>
