import { Component, OnInit, OnDestroy } from '@angular/core';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {EstGetEstatePlanService} from '../../../services/estGetEstatePlanService';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-lev-est-select-package',
  templateUrl: './lev-est-select-package.component.html',
  styleUrls: ['./lev-est-select-package.component.scss']
})
export class LevEstSelectPackageComponent implements OnInit, OnDestroy {

  public estateLevPack = [];
  private PACKAGE: Subscription;

  constructor(private pick: EstatePickerComponent,
              private estGetEstatePlanService: EstGetEstatePlanService,
              private Shared: SharedService,
  ) {
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // get estate plan by Id to dispaly data and subrcibe to data to use later on signup form
    this.PACKAGE = this.estGetEstatePlanService.getEstCustomerPlanSelectionInfo(this.Shared.newSubscription.selectedEstate.id)
      .subscribe(data => {
        this.estateLevPack = data;
      });
  }

  // tslint:disable-next-line:typedef
  onClicked(plan) {
    this.returnPackages();
    //  set selected package value to be displayed on form
    this.Shared.setPackage(plan);
    this.pick.goToNextLev();

  }

  returnPackages(): string {
      return `${this.estateLevPack[1].title}-${this.estateLevPack[1].service_details}-${this.estateLevPack[1].price}`;
  }
  // tslint:disable-next-line:typedef
  ngOnDestroy() {
  }
}


