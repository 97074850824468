<!-- <div id="map"></div> -->
<div style="height: 100%">
  <iframe #iframe [src]="cleaned28EastUrl" frameborder="0" class="coverage-map-embed" id="__coverage-map-clear-access" allow="geolocation">
    <p>Your browser does not support iframes.</p>
  </iframe>

  <!-- <div class="__clear_access_content_container">
  <button class="ca-primary-button w-button small-margin-top" (click)="viewPackages()">View Packages</button>
</div> -->
</div>
