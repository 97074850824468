import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  // public activeStep: BehaviorSubject<string>;
  public step: Subject<string> = new Subject<string>();
  public signup: Subject<string> = new Subject<string>();
  public loading: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  setStep(step: string): void {
    this.step.next(step);
    // this.activeStep.next(step);
  }

  setSignup(): void {
    this.signup.next();
  }

  setLoading(isLoading: boolean): void {
    this.loading.next(isLoading);
  }

  // getStep(): string {
  //   return this.activeStep.getValue();
  // }

}
