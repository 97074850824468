import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetTariffs, SetTariff, SetSelectedProvider, SetSelectedProviderByUser, FilterTariffsByFunc } from './tariffs.actions';
import { Injectable } from '@angular/core';
import { Tariff } from '../../interfaces/Tariff';
import { ProviderStateModel } from 'src/app/components/shared/map.state';
import { environment } from 'src/environments/environment';

interface TariffsById {
  [id: string]: Tariff;
}

export class TariffsStateModel {
  byId: Record<number, Tariff>;
  byArray: Tariff[];
  allIds: number[];
  selectedProvider?: string;
  // Don't set selectedProvider if user already has modified it.
  selectedProviderUserModified?: boolean;
}

const preferenceArr = [
  'Clear Access',
  'Vumatel',
  'Vumatelcore',
  'Octotel',
  'MetroFibre Networx',
  'LinkAfrica',
  'Frogfoot',
  'SADV',
  'DNATel',
  'TTConnect',
  'Vodacom',
  'Mtn',
  'OpenServe',
];

// const localStorageTariffs = localStorage.getItem('tariffs');

// let localStorageDefault: TariffsStateModel = null;

// if (localStorageTariffs && environment.production) {
//   localStorageDefault = JSON.parse(localStorageTariffs);
// }

@State<TariffsStateModel>({
  name: 'tariffs',
  defaults: {
    byId: {},
    byArray: [],
    allIds: [],
    // byId: localStorageDefault ? localStorageDefault.byId : null,
    // byArray: localStorageDefault ? localStorageDefault.byArray : null,
    // allIds: localStorageDefault ? localStorageDefault.allIds : null,
    selectedProvider: 'Clear Access',
    selectedProviderUserModified: false,
  },
  // defaults: { byId: new Map(), byArray: [], allIds: [], selectedProvider: null },
})
@Injectable()
export class TariffsState {
  @Selector()
  static tariffsByArray(state: TariffsStateModel): Tariff[] {
    return state.byArray;
  }

  @Selector()
  static tariffsById(state: TariffsStateModel): Record<number, Tariff> {
    return state.byId;
  }

  // @Selector()
  // static tariffsById(state: TariffsStateModel): Map<number, Tariff> {
  //   return state.byId;
  // }

  @Selector()
  static selectedProvider(state: TariffsStateModel): string {
    return state.selectedProvider;
  }

  @Selector()
  static selectedProviderUserModified(state: TariffsStateModel): boolean {
    return state.selectedProviderUserModified;
  }

  // Maybe instead of array filter, use provider key to access all...
  @Selector()
  static tariffsByArrayAndFilteredBySelectedProvider(state: TariffsStateModel): Tariff[] {
    return state.byArray.filter((tariff) => tariff.connectivity_provider.toLowerCase() === state.selectedProvider.toLowerCase());
  }

  constructor() {}

  @Action(SetTariffs)
  setTariffs({ patchState, getState }: StateContext<TariffsStateModel>, { payload }: SetTariffs): void {
    const allIds = [];
    const currState = getState();
    //const tariffsPayload: TariffsStateModel = { byId: { ...currState.byId }, byArray: [...currState.byArray], allIds: [...currState.allIds], selectedProvider: currState.selectedProvider }; //, selectedProvider: null };
    const tariffsPayload: TariffsStateModel = { byId: [], byArray: [], allIds: [], selectedProvider: null }; //, selectedProvider: null };


    for (let i = 0; i < payload.length; i++) {
      tariffsPayload.byId[payload[i].id] = payload[i];
      allIds.push(payload[i].id);
    }

    tariffsPayload.byArray = Object.values(payload);

    // if (!environment.production) {
    //   localStorage.setItem('tariffs', JSON.stringify(tariffsPayload));
    // }

    patchState({
      ...tariffsPayload,
    });
  }
  @Action(SetTariff)
  setTariff({ patchState, getState }: StateContext<TariffsStateModel>, { payload }: SetTariff): void {
    const state = getState();

    let allIds = state.allIds;
    if (allIds.indexOf(payload.id) === -1) {
      allIds = [...allIds, payload.id];
    }

    const byArray = [...state.byArray];

    const index = byArray.map((tariff) => tariff.id).indexOf(payload.id);

    patchState({
      allIds,
      byArray: [...byArray.slice(0, index), { ...byArray[index], ...payload }, ...byArray.slice(index + 1)],
      byId: { ...getState().byId, [payload.id]: payload },
      selectedProvider: payload.connectivity_provider
    });

    // patchState({
    //   allIds,
    //   byArray: [...byArray.slice(0, index), { ...byArray[index], ...payload }, ...byArray.slice(index + 1)],
    //   byId: { ...getState().byId, [payload.id]: payload },
    // });
  }

  @Action(SetSelectedProvider)
  setSelectedProvider({ patchState, getState }: StateContext<TariffsStateModel>, { payload }: SetSelectedProvider): void {
    console.log('setSelectedProvider action. payload: ' + payload);
    console.table(getState());
    const userModified = getState().selectedProviderUserModified;
    if (!userModified) {
      console.log('modify selectedProvider??', payload);
      patchState({
        selectedProvider: payload,
      });
    }
  }

  @Action(SetSelectedProviderByUser)
  setSelectedProviderByUser({ patchState }: StateContext<TariffsStateModel>, { payload }: SetSelectedProviderByUser): void {
    console.log('set selected provider by user: ' + payload);
    patchState({
      selectedProvider: payload,
      selectedProviderUserModified: true,
    });
  }

  @Action(FilterTariffsByFunc)
  filterTariffs({ patchState, getState }: StateContext<TariffsStateModel>, { payload }: FilterTariffsByFunc): void {
    const currState = getState();

    const newState: TariffsStateModel = { byId: {}, byArray: [], allIds: [] };

    // console.log('tariffs before filter', currState.byArray);

    newState.byArray = currState.byArray.filter(payload);

    // console.log('tariffs after filter', newState.byArray);

    for (let i = 0; i < newState.byArray.length; i++) {
      newState.byId[newState.byArray[i].id] = newState.byArray[i];
    }

    newState.allIds = Object.values(newState.byId).map((tariff) => tariff.id);

    // console.log({ ...newState });

    patchState({
      ...newState,
    });
  }
}
