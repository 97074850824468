import { Location } from '@angular/common';
import { ConstantPool } from '@angular/compiler';
import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Providers } from 'src/app/interfaces/Providers';
import { Tariff } from 'src/app/interfaces/Tariff';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { StepService } from 'src/app/services/step.service';
import { Address } from 'src/app/store/address/Address';
import { SetAddress } from 'src/app/store/address/address.actions';
import { SetSelectedProvider } from 'src/app/store/tariffs/tariffs.actions';
import { environment } from 'src/environments/environment';
import { SelectPackageComponent } from '../residential/residential/select-package/select-package.component';
import { SetProviders } from '../shared/map.actions';
import {EvotelPromoComponent} from "../../evotel-promo/evotel-promo.component";
import { EstGetEstateService } from 'src/app/services/estGetEstateService';
import { EstateMapAddress } from 'src/app/interfaces/estate-map-address';
import { EstatePickerComponent } from '../estates/estate-picker/estate-picker.component';
import { EstGetEstatePlanService } from 'src/app/services/estGetEstatePlanService';
import { SharedService } from 'src/app/services/shared.service';
import {EstateOptionInterface} from "../../interfaces/estateOptionInterface";

@Component({
  selector: 'app-check-coverage',
  templateUrl: './check-coverage.component.html',
  styleUrls: ['./check-coverage.component.scss']
})
export class CheckCoverageComponent implements OnInit, AfterContentInit, OnDestroy {

  public smallScreen = false;
  public breakpointSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SelectPackageComponent>,
    private stepService: StepService,
    private location: Location,
    private breakpointService: BreakpointService,
    private estGetEstateService: EstGetEstateService,
    private pick: EstatePickerComponent,
    private estGetEstatePlanService: EstGetEstatePlanService,
    private Shared: SharedService,
  ) {

    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

    this.cleaned28EastUrl = sanitizer.bypassSecurityTrustResourceUrl(environment.eastUrl);
    window.removeEventListener('message', this.eventListener);
  }

  providers: Providers;
  cleaned28EastUrl: any;
  eventListener;

  // mapInputName: string;

  mapInputName: any;


  ngOnInit(): void {

    window.removeEventListener('message', this.eventListener);

    if (environment.production) {
      localStorage.clear();
    }
    // Set step
    this.stepService
    .setStep('Check Your Coverage');
    // const iframe = (document.getElementById('__coverage-map-clear-access') as HTMLIFrameElement).contentWindow;

    // console.log(this.location.getState());
    // @ts-ignore
    if (this.location.getState()?.navigationId === 1) {
      this.observe28east();
    }
  }

  observe28east(): void {
    this.eventListener = window.addEventListener(
      'message',
      (e) => {
        window.removeEventListener('message', this.eventListener);
        if (e.origin === 'https://clearaccess.28east.co.za') {
          this.providers = JSON.parse(e.data);
          // console.log('this is the providers ',this.providers);

          if (this.providers.action !== 'access') {
            return;
          }
          console.log('INPUT ADDRESS' + this.providers.input.formatted_address);

          if(this.providers.input.formatted_address !== null && this.providers.input.formatted_address !== undefined) {
            //////////////////////////////////////////////////////////////////////////////////////////////////////////
            // GET INPUT MATCH OF MAP NAME BY 28 EAST
            let body: EstateMapAddress =  {map_address:this.providers.input.formatted_address };

            let estateResult: EstateOptionInterface = null;
            let isEstateSelection: boolean = false;

            this.estGetEstateService.post28EastMapName(body).subscribe(result => {

              if(result !== null ) {
                estateResult = result;
                console.log('post28EastMapName IF NOT NULL  ' + this.providers.input.formatted_address);

                this.Shared.setSelectedEstate(result);

                ////////////////////////////////////////////////////////////////////////
                // estate REDIRECT to ESTATE picker on map search address match of estate
                // this.estateType(estateResult);

                this.estateType(this.providers.input.formatted_address);

              } else {
                this.store.dispatch(new SetProviders(JSON.parse(e.data))).subscribe(() => {

                  if (this.providers.selected_service === 'fibre') {
                    // this.router.navigate(['residential'], { relativeTo: this.route.parent });
                    this.dialogRef = this.dialog
                      .open(SelectPackageComponent, {
                        disableClose: true,
                        // width: '80%',
                        height: this.smallScreen ? '85%' : '',
                        maxHeight: this.smallScreen ? '577px' : ''
                      });

                    this.dialogRef
                      .afterClosed()
                      .subscribe((t: Tariff) => {
                        if (t) {
                          this.dialogRef = null;
                          this.store.dispatch(new SetSelectedProvider(t.connectivity_provider));
                          this.router.navigate(['signup/residential/customer-details'], { queryParams: { tariffId: t.id } });
                        }
                      });
                  } else {
                    this.router.navigate(['business'], { relativeTo: this.route.parent });
                  }
                });
              }
            });
          }



          const addressKeys: Address = { city: '', country: '', postalCode: '', province: '', streetName: '', streetNumber: '', suburb: '' };
          for (const addressComponent of this.providers.place.address_components) {
            if (addressComponent.types.indexOf('street_number') > -1) {
              addressKeys['streetNumber'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('locality') > -1) {
              addressKeys['city'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('administrative_area_level_1') > -1) {
              addressKeys['province'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('route') > -1) {
              addressKeys['streetName'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('sublocality') > -1 || addressComponent.types.indexOf('sublocality_level_1') > -1) {
              addressKeys['suburb'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('postal_code') > -1) {
              addressKeys['postalCode'] = addressComponent.long_name;
            } else if (addressComponent.types.indexOf('country') > -1) {
              addressKeys['country'] = addressComponent.long_name;
            }
          }
          if (this.providers && this.providers.place && this.providers.place.geometry) {
            addressKeys['geometry'] = this.providers.place.geometry;
          }

          const address = new Address(
            addressKeys['streetNumber'],
            addressKeys['streetName'],
            addressKeys['suburb'],
            addressKeys['city'],
            addressKeys['province'],
            addressKeys['postalCode'],
            addressKeys['country'],
            addressKeys['geometry'],
          );

          this.store.dispatch(new SetAddress(address)).subscribe(
            () => {},
            (er) => {
            }
          );


        } else if (e.origin === 'https://angular.clearaccess.co.za' || 'https://devring.clearaccess.co.za') {
          try {
            const data = JSON.parse(e.data);
            let environmentEastUrl = environment.eastUrl;
            if (data && data.from && typeof data.from === 'string') {
              if (data.from.indexOf('business') > -1) {
                environmentEastUrl += '?businessfibre=true';
              } else if (data.from.indexOf('residential') > -1) {
                environmentEastUrl += '?fibre=true';
              }
            }

            this.cleaned28EastUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environmentEastUrl);
          } catch (e) {}
        }
      },
      false
    );

    fromEvent(window, 'message')
      .pipe(debounceTime(500))
      .subscribe((e: MessageEvent) => {
        if (e.origin === 'https://angular.clearaccess.co.za' || 'https://devring.clearaccess.co.za') {
          try {
            const data = JSON.parse(e.data);
            let environmentEastUrl = environment.eastUrl;
            if (data && data.from && typeof data.from === 'string') {
              if (data.from.indexOf('business') > -1) {
                environmentEastUrl += '?businessfibre=true';
              } else if (data.from.indexOf('residential') > -1) {
                environmentEastUrl += '?fibre=true';
              }
            }

            this.cleaned28EastUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environmentEastUrl);
          } catch (e) {}
        }
      });
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {
    // console.log('destroy message ev listener', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    window.removeEventListener('message', this.eventListener);
    this.breakpointSubscription.unsubscribe();
    this.eventListener = null;
  }

  // tslint:disable-next-line:typedef
  goToNextCentury() {
    if (this.router?.url.toLowerCase() === '/signup/check-coverage') {
      this.router.navigate(['/estate-picker/cent-est-select-package'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-select-package') {
      this.router.navigate(['cent-est-install-option'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-install-option') {
      this.router.navigate(['cent-est-device-options'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-device-options') {
      this.router.navigate(['cent-est-signup'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-signup') {
      this.router.navigate(['cent-est-verification'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-verification') {
      this.router.navigate(['cent-est-signup-success'], {relativeTo: this.route});
    }
  }

  // tslint:disable-next-line:typedef
  goToNextLev() {
    if (this.router?.url.toLowerCase() === '/signup/check-coverage') {
      this.router.navigate(['/estate-picker/lev-est-select-package'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-select-package') {
      this.router.navigate(['lev-est-signup'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-signup') {
      this.router.navigate(['lev-est-verification'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-verification') {
      this.router.navigate(['lev-est-signup-success'], {relativeTo: this.route});
    }
  }

  // tslint:disable-next-line:typedef
  estateType(estate: any) {
    if (estate.type === 'levy') {
      this.goToNextLev();
    } else {
      this.goToNextCentury();
    }
  }
}
