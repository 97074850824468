import {Injectable} from '@angular/core';
import {SmsPortalInterface} from '../interfaces/SmsPortalInterface';
import {SharedService} from './shared.service';
import {SMSPortalService} from './SMSPortalService';
import {Subscription} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SmsOTPGenerateService {

  OPTCODE: Subscription;

  constructor(
    private Shared: SharedService,
    private sMSPortalService: SMSPortalService,
  ) {
  }
// GENERATE A RANDOM OTP OR RESEND GENERATED PIN
  // tslint:disable-next-line:typedef
  public generateOTP(cellNumber: string, firstName: string, lastName: string) {
    // Declare a digits variable
    // which stores all digits
    const digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 5; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    this.Shared.generatedOTP = OTP;

    const smsOtpData: SmsPortalInterface = {
      smsOTP: String(OTP),
      custCellNum: cellNumber,
      custFullName: firstName + ' ' + lastName,
    };
    // POST - SEND OTP TO SPRING to SMS Client
    this.OPTCODE = this.sMSPortalService.postOTP(smsOtpData).subscribe((data) => {
    });
    return OTP;
  }

  // RESEND OTP
  // tslint:disable-next-line:typedef
  public resendOTP(cellNumber: string, firstName: string, lastName: string) {

    const OTP = this.Shared.generatedOTP;
    const smsOtpData: SmsPortalInterface = {
      smsOTP: String(OTP),
      custCellNum: cellNumber,
      custFullName: firstName + ' ' + lastName,
    };
    // POST - SEND OTP TO SPRING to SMS Client
    this.OPTCODE = this.sMSPortalService.postOTP(smsOtpData).subscribe((data) => {
    });
    return OTP;
  }
}
