import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Address } from './Address';
import { SetAddress } from './address.actions';
import { environment } from 'src/environments/environment';

let localStorageAddress: Address = null;
if (localStorage.getItem('address') && environment.production) {
  localStorageAddress = JSON.parse(localStorage.getItem('address'));
}
// const localStorageAddress = localStorage.getItem('address');

export class AddressStateModel {
  address: Address;
}
@State<AddressStateModel>({
  name: 'address',
  defaults: {
    address: localStorageAddress ? localStorageAddress : new Address('', '', '', '', '', '', ''),
  },
})
@Injectable({ providedIn: 'root' })
export class AddressState {
  @Selector()
  static address(state: AddressStateModel): Address {
    return state.address;
  }

  constructor() {}

  @Action(SetAddress)
  setAddress({ patchState, getState }: StateContext<AddressStateModel>, { payload }: SetAddress): void {
    const state = getState();

    if (window && window.localStorage && !environment.production) {
      localStorage.setItem(
        'address',
        JSON.stringify({
          streetNumber: payload.streetNumber,
          streetName: payload.streetName,
          suburb: payload.suburb,
          city: payload.city,
          province: payload.province,
          postalCode: payload.postalCode,
          country: payload.country,
          geometry: {
            location: {
              lat: payload.geometry.location ? payload.geometry.location.lat : '',
              lng: payload.geometry.location ? payload.geometry.location.lng : '',
            },
            location_type: payload.geometry.location_type,
            viewport: {
              east: payload.geometry.viewport.east,
              north: payload.geometry.viewport.north,
              south: payload.geometry.viewport.south,
              west: payload.geometry.viewport.west,
            },
          },
        })
      );
    }

    patchState({
      address: payload,
    });
  }
}
