import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepService } from 'src/app/services/step.service';
import { SelectPackageComponent } from '../select-package/select-package.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { DocumentsService } from 'src/app/services/documents-service/documents.service';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-debit-order',
  templateUrl: './debit-order.component.html',
  styleUrls: ['./debit-order.component.scss']
})
export class DebitOrderComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  public smallScreen: boolean = false;
  public breakpointSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<SelectPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {accountHolder: string, address: string, bankName: string, branchName: string, postalCode: string, accountNumber: string, accountType: string, date: string, contactNumber: string, amount: string, beneficiaryName: string, contractReferenceNumber: string, debitOrderDate: string},
    private stepService: StepService,
    private snackBarService: SnackBarService,
    private documentsService: DocumentsService,
    private breakpointService: BreakpointService
  ) {

    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

    this.stepService
    .setStep('Debit Order Mandate');
  }

  ngOnInit(): void {
    // (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
}

  acceptTermsAndConditions(): void {
    // this.loading = true;
    // const body = document.getElementById('debit-order-mandate');
    // const name = `clear-access-debit-order-mandate-${new Date().toLocaleDateString()}`;
    // body.style.height = '100%';
    // html2canvas(body).then(canvas => {
    //   // Few necessary setting options
    //   // const imgWidth = 215;
    //   // const pageHeight = 800;
    //   // const imgHeight = canvas.height * imgWidth / canvas.width;
    //   // const heightLeft = imgHeight;

    //   const width = body.scrollWidth;
    //   const height = body.scrollHeight;

    //   const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
    //   const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    //   const position = 0;
    //   pdf.addImage(contentDataURL, 'JPEG', 2, 2, width, 100);
    //   const file = pdf.save(`${name}.pdf`); // Generated PDF
    //   const blob = file.output('blob');
    //   const formData = new FormData();
    //   formData.append('pdf', blob);
    //   const blobPart: BlobPart[] = [blob];
    //   const debitOrderMandateFile = new File(blobPart, 'debitOrderMandateDocument');
    //   this.documentsService.setDebitOrderMandate(debitOrderMandateFile);
    //   // <input (change)="onFileChange($event)" name="proofOfAddressDocume
    //   // this.snackBarService.showSnackBar(
    //   //   `${name}.pdf`,
    //   //   'File Successfully downloaded'
    //   // );
    // });

    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.documentsService.setDebitOrderMandate(null);
    this.dialogRef.close();
  }

}
