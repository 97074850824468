import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProviderStateModel } from '../../../shared/map.state';
import { Tariff } from 'src/app/interfaces/Tariff';
import { Promotion } from 'src/app/interfaces/Promotion';
import { SetSelectedProvider, SetSelectedProviderByUser } from '../../../../store/tariffs/tariffs.actions';
import { Store, Select } from '@ngxs/store';
import { AppState, AppError } from 'src/app/store/app/app.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TariffsStateModel } from 'src/app/store/tariffs/tariffs.state';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectPackageComponent } from '../select-package/select-package.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { DebitOrderComponent } from '../debit-order/debit-order.component';
import { StepService } from 'src/app/services/step.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  @Input() signupForm: FormGroup;
  @Input() bankingForm: FormGroup;
  @Input() documentsForm: FormGroup;
  @Input() agreementsForm: FormGroup;
  @Input() providers: string[];
  @Input() packageOptions: Tariff[];
  @Input() promoObject: Promotion;
  @Input() bankAccountTypes: string[];
  @Input() promoPlansIsAdded: boolean;
  @Output() providerSelected: EventEmitter<string>;
  @Output() applyPromoEvent: EventEmitter<{ type: string }> = new EventEmitter();
  @Input() promoLoading: boolean;
  // crazy 8 disclaimer
  @Input() isCrazy8 = false;
  @Input() disclaimerCrazy8: string;

  promoWaiting = false;
  selectedPackageIdx = null;
  @Select('tariffs') tariffs$: Observable<TariffsStateModel>;
  promoPanelState: boolean;
  private destroy: Subject<boolean> = new Subject<boolean>();
  private queryParamSub: Subscription;
  public loading: boolean = false;

  @Select(AppState.errorsByTypeAndStack('default', 'promotion'))
  promotionErrors$: Observable<AppError[]>;

  activeTariff: Tariff;
  testData: boolean = false;
  public smallScreen: boolean = false;
  public breakpointSubscription: Subscription;
  public stepLoadingSubscription: Subscription;

  @ViewChild('activePromoCodeDiv') activePromoCodeDiv: ElementRef;
  @ViewChild('promotionErrorsDiv') promotionErrorsDiv: ElementRef;
  @ViewChild('promoEffectDiv') promoEffectDiv: ElementRef;

  @Input()
  max: Date | null

  today = new Date();

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private stepService: StepService,
    private breakpointService: BreakpointService,
  ) {
    console.log(this.signupForm);

    this.stepService
      .setStep('Sign up');

    this.stepLoadingSubscription = this.stepService
      .loading
      .subscribe((isLoading: boolean) => {
        this.loading = isLoading;
      });

    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });
  }

  handleSignup(): void {
    this.stepService.setSignup();
  }

  // show disclaimer CRAZY 8

  showCrazy8Disclaimer(): boolean {
    if (this.activeTariff.title.toLowerCase() === ('Clear Access FTTH - Crazy 8').toLowerCase()) {
      return this.isCrazy8 = true;
    }
  }

  // this.tariffsFilteredBySelectedProvider = this.tariffs.tariffs.byArray.filter(
  //   (tariff) => tariff.connectivity_provider.toLowerCase() === provider.toLowerCase()
  // );

  applyPromo(): void {
    this.applyPromoEvent.emit({ type: 'home' });
  }

  removePromo(): void {
    this.signupForm.reset();
  }

  onFileChange(event): void {
    const reader = new FileReader();

    this.documentsForm.patchValue({
      [event.target.name]: event.target.files[0],
    });
  }

  setSelectedProvider(event): void {
    // const that = this;
    // console.log('set selectedprovider::', event);
    this.signupForm.controls.packageSelectedId.reset();
    // this.signupForm.controls.providerSelected.setValue()
    this.store.dispatch(new SetSelectedProviderByUser(this.signupForm.value.providerSelected));
  }

  // setDateOfBirth(): void {
  //   // const date = Date.parse(this.signupForm.controls.dateOfBirth.value);
  //   const date = new Date(this.signupForm.controls.dateOfBirth.value);
  //   console.log('DATE', date);
  //   const formattedDate = date.toISOString().substring(0, date.toISOString().indexOf('T')).split('-').join('/');
  //   console.log(formattedDate);
  //   this.signupForm.controls.dateOfBirth.setValue(formattedDate);
  //   // new Date();
  //   // this.signupForm.controls.dateOfBirth.setValue(this.signupForm.controls.dateOfBirth.value)
  // }

  allIsValid(): boolean {
    console.log(this.bankingForm.valid, this.documentsForm.valid, this.signupForm, this.agreementsForm);
    console.log(this.signupForm.controls.firstName.valid &&
      this.signupForm.controls.lastName.valid &&
      this.signupForm.controls.email.valid &&
      this.signupForm.controls.cellNumber.valid &&
      this.signupForm.controls.providerSelected.valid &&
      this.signupForm.controls.packageSelectedId.valid
    );
    return (
      this.bankingForm.valid &&
      this.documentsForm.valid &&
      this.signupForm.controls.firstName.valid &&
      this.signupForm.controls.lastName.valid &&
      this.signupForm.controls.email.valid &&
      this.signupForm.controls.cellNumber.valid &&
      this.signupForm.controls.providerSelected.valid &&
      this.signupForm.controls.packageSelectedId.valid
    );
  }

  bankInfoValid(): boolean {
    if (this.bankingForm) {
      return (
        this.bankingForm.controls.accountType.valid &&
        this.bankingForm.controls.bankName.valid &&
        this.bankingForm.controls.accountBranch.valid &&
        this.bankingForm.controls.accountHolderName.valid &&
        this.bankingForm.controls.accountNumber.valid
      );
    }
    return false;
  }

  packageSelectionValid(): boolean {
    // return this.signupForm.controls.packageSelectedIndex.valid && this.signupForm.controls.packageInstallSelectedIndex.valid;
    return true;
  }

  ngOnInit(): void {
    // get tariff from route param
    console.log(this.route);
    this.queryParamSub = this.route.queryParams
      .subscribe(params => {
        if (params) {
          this.tariffs$.pipe(takeUntil(this.destroy))
            .subscribe((tariff) => {
              if (tariff) {
                if (tariff.byId[params.tariffId] != null) {
                  this.activeTariff = tariff.byId[params.tariffId];
                }
                // Assign provider data
                this.signupForm.controls.providerSelected
                  .setValue(
                    this.providers[this.providers.indexOf(this.activeTariff.connectivity_provider)]
                  );
                this.selectedPackageIdx = this.packageOptions.findIndex(p => p.id === this.activeTariff.id);
                this.signupForm.controls.packageSelectedId.setValue(this.activeTariff.id);

                // set form data if dev env
                if (!environment.production && !this.testData) {
                  this.testData = true;
                  // Signup form
                  setTimeout(() => {
                    // this.signupForm.controls.packageInstallSelectedIndex.setValue(
                    //   this.packageOptions[this.selectedPackageIdx]?.install_options?.split(',')[0]
                    // );
                    this.signupForm.controls.firstName.setValue('Rhenier');
                    this.signupForm.controls.lastName.setValue('Kloppers');
                    this.signupForm.controls.dateOfBirth.setValue('1999-08-08');
                    this.signupForm.controls.idNumber.setValue('9908085088903');
                    this.signupForm.controls.email.setValue('rhenierkloppers124@gmail.com');
                    this.signupForm.controls.cellNumber.setValue('0640000000');
                    this.signupForm.controls.otherNumber.setValue('0640000000');
                    this.signupForm.controls.hasPromoCode.setValue(false);
                    this.signupForm.controls.promoCode.setValue(null);
                    this.signupForm.controls.acceptedTermsAndConditions.setValue(false);
                    this.signupForm.controls.acceptedDebitOrderMandate.setValue(false);

                    // Banking form
                    this.bankingForm.controls.accountType.setValue(
                      this.bankAccountTypes[this.bankAccountTypes.findIndex(b => b.toLowerCase() === 'cheque')]
                    );
                    this.bankingForm.controls.bankName.setValue('FNB');
                    this.bankingForm.controls.accountHolderName.setValue('MR ACCOUNT HOLDER');
                    this.bankingForm.controls.accountBranch.setValue('250655');
                    this.bankingForm.controls.accountNumber.setValue('6284567842');

                    // Documents form
                    // ...
                  }, 250)
                }
                const installOptionsArr = this.packageOptions[this.selectedPackageIdx]?.install_options?.split(',');
                console.log(installOptionsArr);
                // this.signupForm.controls.packageInstallSelectedIndex.setValue(
                //   installOptionsArr[installOptionsArr.findIndex((val) => {
                //     console.log(val);
                //     console.log(val.toLowerCase());
                //     console.log(val.toLowerCase().indexOf('month to month'));
                //     return val.toLowerCase().indexOf('month to month') > -1;
                //   })]
                // );
                console.log('Setting install option default to', this.signupForm.controls.packageInstallSelectedIndex);
              }
            });
        }
      });
  }

  setHasPromoCode(isOpen: boolean): boolean {
    this.promoPanelState = isOpen;
    this.signupForm.controls.hasPromoCode.setValue(false);
    return this.promoPanelState;
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.breakpointSubscription.unsubscribe();
    this.stepLoadingSubscription.unsubscribe();
    this.queryParamSub.unsubscribe();
  }

}
