import { Action, Selector, State, StateContext, Select, Store } from '@ngxs/store';
import { SetProviders /* , SetSelectedProvider */ } from './map.actions';
import { Injectable } from '@angular/core';
import { Providers, AddressComponents, Addresses, Geometry, Location, Place, PlusCode, ServiceProviders, Services, Viewport } from '../../interfaces/Providers';
import { SetTariffs, SetSelectedProvider } from 'src/app/store/tariffs/tariffs.actions';
import { FibreHome } from 'src/app/interfaces/fibre-home';
import { PlanService } from 'src/app/services/plans.service';
import { environment } from 'src/environments/environment';

export class ProviderStateModel {
  providers: Providers;
  connectivityProviders: { home: string[]; business: string[] };
  shortCodes: { home: string[]; business: string[] };
}

const preferenceArr = [
  'Clear Access',
  'Vumatel',
  'Vumatelcore',
  'Octotel',
  'MetroFibre Networx',
  'LinkAfrica',
  'Frogfoot',
  'SADV',
  'DNATel',
  'TTConnect',
  'Vodacom',
  'Mtn',
  'OpenServe',
];

const localStorageProviders = localStorage.getItem('providers');

let localStorageDefault: ProviderStateModel = null;

if (localStorageProviders && environment.production) {
  localStorageDefault = JSON.parse(localStorageProviders);
}

const localStorageConnectivityProviders = localStorage.getItem('connectivityProviders');

let localStorageDefaultConnectivityProviders: { home: string[]; business: string[] } = null;

if (localStorageConnectivityProviders && environment.production) {
  localStorageDefaultConnectivityProviders = JSON.parse(localStorageConnectivityProviders);
}

@State<ProviderStateModel>({
  name: 'providers',
  defaults: {
    providers: localStorageDefault ? localStorageDefault.providers : null,
    connectivityProviders: localStorageDefaultConnectivityProviders ? { ...localStorageDefaultConnectivityProviders } : null, // ,
    shortCodes: { home: [], business: [] },
  },
})
@Injectable({ providedIn: 'root' })
export class ProviderState {
  @Selector()
  static providers(state: ProviderStateModel): Providers {
    return state.providers;
  }

  @Selector()
  static connectivityProviders(state: ProviderStateModel): { home: string[]; business: string[] } {
    const lowest = preferenceArr.indexOf(state.connectivityProviders.home[0]);
    const lowestArr = [];
    lowestArr.push(state.connectivityProviders.home[0]);
    for (let i = 0; i < state.connectivityProviders.home.length; i++) {
      if (preferenceArr.indexOf(state.connectivityProviders.home[i]) < lowest) {
        const temp = lowestArr[i];
        lowestArr[i] = state.connectivityProviders.home[i];
        lowestArr[i + 1] = temp;
      }
    }

    return state.connectivityProviders;
  }

  constructor(private store: Store, private planService: PlanService) {}

  @Action(SetProviders)
  setProviders({ patchState, getState }: StateContext<ProviderStateModel>, { payload }: SetProviders): void {
    console.log('Payload set providers', payload);
    const connectivityProviders: { home: string[]; business: string[] } = { business: [], home: [] };
    const servicesByServiceType: { home: Services; business: Services } = { business: { providers: [], type: null }, home: { providers: [], type: null } };

    for (let i = 0; i < payload.services.length; i++) {
      if (payload.services[i].type === 'fibre') {
        servicesByServiceType['home'] = payload.services[i];
      } else if (payload.services[i].type === 'businessfibre') {
        servicesByServiceType['business'] = payload.services[i];
      }
    }

    for (let j = 0; j < servicesByServiceType.home.providers.length; j++) {
      connectivityProviders.home.push(servicesByServiceType.home.providers[j].provider);
    }

    for (let j = 0; j < servicesByServiceType.business.providers.length; j++) {
      connectivityProviders.business.push(servicesByServiceType.business.providers[j].provider);
    }

    const serviceType = payload.selected_service;

    const connectivityProvidersFromApi: { home: string[]; business: string[] } = { business: [], home: [] };

    patchState({ shortCodes: { home: connectivityProviders.home, business: connectivityProviders.business } });
    console.log('get service providers map');
    this.planService
      .getProvidersByNames(
        serviceType === 'fibre' ? connectivityProviders.home : connectivityProviders.business,
        serviceType === 'businessfibre' ? 'business' : 'home'
      )
      .subscribe(
        (dataProviders: FibreHome[]) => {
          console.log('get service providers', dataProviders);
          const connectivityProvidersHome = [];
          const connectivityProvidersBusiness = [];
          if (serviceType === 'fibre') {
            for (let i = 0; i < dataProviders.length; i++) {
              if (connectivityProvidersHome.indexOf(dataProviders[i].connectivity_provider) === -1) {
                connectivityProvidersHome.push(dataProviders[i].connectivity_provider);
              }
            }
          } else if (serviceType === 'businessfibre') {
            for (let i = 0; i < dataProviders.length; i++) {
              if (connectivityProvidersBusiness.indexOf(dataProviders[i].connectivity_provider) === -1) {
                connectivityProvidersBusiness.push(dataProviders[i].connectivity_provider);
              }
            }
          }
          connectivityProvidersFromApi.business = connectivityProvidersBusiness;
          connectivityProvidersFromApi.home = connectivityProvidersHome;

          this.store.dispatch(new SetTariffs(dataProviders));

          if (!environment.production) {
            localStorage.setItem('providers', JSON.stringify(payload));
            localStorage.setItem('connectivityProviders', JSON.stringify(connectivityProvidersFromApi));
          }

          if (connectivityProvidersFromApi.business.indexOf('Clear Access') > -1) {
            connectivityProvidersFromApi.business = ['Clear Access'];
          }
          if (connectivityProvidersFromApi.home.indexOf('Clear Access') > -1) {
            connectivityProvidersFromApi.home = ['Clear Access'];
          }

          if (serviceType === 'fibre') {
            this.store.dispatch(new SetSelectedProvider(connectivityProvidersFromApi.home[0]));
          } else if (serviceType === 'businessfibre') {
            this.store.dispatch(new SetSelectedProvider(connectivityProvidersFromApi.business[0]));
          }

          patchState({
            providers: payload,
            connectivityProviders: { ...connectivityProvidersFromApi },
          });
        },
        (e) => {
        }
      );
  }
}
