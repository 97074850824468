import { Tariff } from '../../interfaces/Tariff';

export class SetTariffs {
  static readonly type = '[Tariffs] Set Tariffs';
  constructor(public payload: Tariff[]) {}
}

export class SetTariff {
  static readonly type = '[Tariffs] Set Tariff';
  constructor(public payload: Tariff) {}
}

export class SetSelectedProvider {
  static readonly type = '[Tariffs] Set SelectedProvider';
  constructor(public payload: string) {}
}

export class SetSelectedProviderByUser {
  static readonly type = '[Tariffs] Set SelectedProviderByUser';
  constructor(public payload: string) {}
}

export class FilterTariffsByFunc {
  static readonly type = '[Tariffs] Filter tariffs by a function';
  constructor(public payload: (tariff: Tariff) => boolean) {}
}
