// import { BreakpointObserver } from '@angular/cdk/layout';
// import { Route } from '@angular/compiler/src/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { StepService } from 'src/app/services/step.service';
import { Location } from '@angular/common';
import {SharedService} from '../../services/shared.service';
import {NavigationService} from '../../services/navigationService';
import {LevEstSignupComponent} from '../estates/lev-est-signup/lev-est-signup.component';
import {EstCustomerInterface} from '../../interfaces/estCustomerInterface';
import {CentEstSignupComponent} from '../estates/cent-est-signup/cent-est-signup.component';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {


  newSubscription: EstCustomerInterface;

  public step: string;
  public smallScreen = false;
  public breakpointSubscription: Subscription;
  public stepSubscription: Subscription;
  public selectedEstate: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private stepService: StepService,
    private snackBarService: SnackBarService,
    private breakpointService: BreakpointService,
    private location: Location,
    private Shared: SharedService,
    private navigation: NavigationService,

  ) {
    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });
  }

  ngOnInit(): void {
    // this.step = this.router
    //   .getCurrentNavigation()
    //   .extras
    //   .state
    //   .step;


    this.stepSubscription = this.stepService
      .step
      .subscribe(step => {
        this.step = step;
      });
  }

  determineButtonFunctionality(): string {
    if (this.step?.toLowerCase() === 'check your coverage' || this.step?.toLowerCase() === 'select your package') {
      return 'SWITCH TO BUSINESS FIBRE';
    }
    return 'GO BACK';
  }

  navbarButtonClick(): void {
    console.log(this.step.toLowerCase());
    if (this.step?.toLowerCase() === 'check your coverage' || this.step?.toLowerCase() === 'select your package') {
      window.location.href = 'https://www.clearaccess.co.za/business/fibre';
    } else if (this.step?.toLowerCase() === 'sign up' || this.step?.toLowerCase() === 'terms & conditions' || this.step?.toLowerCase() === 'debit order mandate') {
      this.router.navigate(['signup/check-coverage']);
    } else if (this.step?.toLowerCase() === 'sign up complete') {
      this.router.navigate(['signup/check-coverage']);
    }
    // signup/complete
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  // navbarButtonClickNext(): void {
  //   console.log(this.step.toLowerCase());
  //   if (this.step?.toLowerCase() === 'check your coverage' || this.step?.toLowerCase() === 'select your package') {
  //     console.log('goto signup');
  //     this.router.navigate(['signup/residential/customer-details'], { queryParams: { tariffId: 68 } });
  //     this.step = 'Sign up';
  //   } else if (this.step?.toLowerCase() === 'sign up') {
  //     console.log('goto terms');
  //     this.router.navigate(['signup/residential/terms']);
  //   } else if (this.step?.toLowerCase() === 'terms & conditions') {
  //     console.log('goto debit order');
  //     this.router.navigate(['signup/residential/debit-order']);
  //   } else if (this.step?.toLowerCase() === 'debit order mandate') {
  //     console.log('goto complete');
  //     this.router.navigate(['signup/residential/complete']);
  //   } else if (this.step?.toLowerCase() === 'sign up complete') {
  //     console.log('goto nowhere');
  //     this.snackBarService.showSnackBar('Already at the last step');
  //   }
  //   // signup/complete
  // }
  // tslint:disable-next-line:typedef
  // demoEstates() {
  //   if (this.router?.url.toLowerCase() !== '/estate-picker') {
  //     // return this.hide = true,
  //     this.router.navigate(['estate-picker'], {relativeTo: this.route});
  //   }
  // }

  // tslint:disable-next-line:typedef
  backToEstates() {
    if (this.router?.url.toLowerCase() !== '/estate-picker') {
      this.router.navigate(['estate-picker'], {relativeTo: this.route});
    }
  }

  // Display the text of the step name via current url
  // tslint:disable-next-line:typedef
  displayStepName(): string {
    this.selectedEstate = this.Shared.newSubscription.selectedEstate.fullname;

    if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-select-package' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-select-package') {
      return 'Select your package';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-install-option') {
      return 'Install options';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-device-options' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-device-options') {
      return 'Device options';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-signup' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-signup') {
      return 'Sign up';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-terms-co' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-terms-co') {
      return 'Terms & Conditions';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-debitorder-mandate' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-debitorder-mandate') {
      return 'Debit Order Mandate';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-verification' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-verification') {
      return 'Verification';
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-signup-success' || this.router?.url.toLowerCase() === '/estate-picker/cent-est-signup-success') {
      return 'Sign up complete';
    }
  }

  // tslint:disable-next-line:typedef
  back() {
    this.navigation.back();
  }
}
