import { Component, OnInit } from '@angular/core';
import { StepService } from 'src/app/services/step.service';

@Component({
  selector: 'app-sign-up-complete',
  templateUrl: './sign-up-complete.component.html',
  styleUrls: ['./sign-up-complete.component.scss']
})
export class SignUpCompleteComponent implements OnInit {

  constructor(
    private stepService: StepService
  ) {

  }

  ngOnInit(): void {
    this.stepService
    .setStep('Sign up complete');

    console.log('Set step');
  }

}
