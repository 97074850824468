<div class="clear_access_form" style="position: relative;">
  <mat-card class="field-container">
    <div style="position: absolute; right: 0; margin-right: 15px;">
      <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <ng-container *ngIf="!loading else loadingTemplate">
      <div [style.maxWidth]="smallScreen ? '100%' : '80%'" id="debit-order-mandate" style="height: 65%; max-height: 65%; padding: 18px; overflow-y: auto;">
        <strong><p style="color: #3380F5; font-size: 24px;">Debit Order Authority/Mandate</p></strong>
        <strong style="font-size: 16px;">A. Authority/Mandate: Paper/Electronic</strong>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">Given by (name of Account Holder): <strong>{{ data.accountHolder }}</strong></p>
        <p style="margin: 0; padding: 0;">Address: <strong> {{data.address}}</strong></p>
        <div style="height: 12px;"></div>
        <p style="color: #3380F5; margin: 0; padding: 0;">Bank Account Details:</p>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">Bank Name:<strong> {{data.bankName}}</strong></p>
        <!-- <p style="margin: 0; padding: 0;">Branch Name and Town:<strong> {{data.branchName}}</strong></p> -->
        <p style="margin: 0; padding: 0;">Branch Number:<strong> {{data.branchName}}</strong></p>
        <p style="margin: 0; padding: 0;">Account Number:<strong> {{data.accountNumber}}</strong></p>
        <p style="margin: 0; padding: 0;">Type of Account:<strong> {{data.accountType}}</strong></p>
        <p style="margin: 0; padding: 0;">Date:<strong> {{data.date}}</strong></p>
        <p style="margin: 0; padding: 0;">Contact Number:<strong> {{data.contactNumber}}</strong></p>
        <p style="margin: 0; padding: 0;">Amount (Variable, based on service and any outstanding amounts due):<strong> R{{data.amount | number:'1.2-2'}}</strong></p>
        <p style="margin: 0; padding: 0;">To (Name of Beneficiary):<strong> Clear Access (Pty) Ltd </strong></p>
        <p style="margin: 0; padding: 0;">Address:<strong> {{ data.address }}</strong></p>
        <p style="margin: 0; padding: 0;">Abbreviated Shortname to be used:<strong> CLEAR ACCE</strong></p>
        <div style="height: 12px;"></div>
        <!-- <p style="margin: 0; padding: 0;">Refer to contract reference number: {{ data.contractReferenceNumber }} (“the Contract Reference Number”)</p> -->
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">I/We hereby authorise Netcash (Pty) Ltd to issue and deliver payment instructions to your banker for collection against my/our abovementioned account at my/our abovementioned bank on condition that the sum of such payment instructions will not differ from my/our obligations as agreed to in the Contract Reference Number.</p>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">The individual payment instructions so authorised must be issued and delivered on the date when the obligation in terms of the Agreement is due and the amount of each individual payment instruction may not differ as agreed to in terms of the Agreement.</p>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">The payment instructions so authorised to be issued must carry the Contract Reference Number, included in the said payment instructions, and must be provided to identify the specific contract. The said Contract Reference Number should be added to this form in section E before the issuing of any payment instruction and communicated directly after having been completed.</p>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">I/We agree that the first payment instruction will be issued and delivered {{data.debitOrderDate}} and thereafter regularly {{data.debitOrderDate}} of each month. If however, the date of the payment instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the following business day; or</p>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">Subsequent payment instructions will continue to be delivered in terms of this authority until the obligations in terms of the Agreement have been paid or until this authority is cancelled by me/us by giving you notice in writing of not less than the interval (as indicated in the previous clause) and sent by prepaid registered post or delivered to your address indicated above.</p>
        <div style="height: 12px;"></div>
        <strong style="font-size: 16px;">B. MANDATE</strong>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">I/We acknowledge that all payment instructions issued by you will be treated by my/our abovementioned bank as if the instructions had been issued by me/us personally.</p>
        <div style="height: 12px;"></div>
        <strong style="font-size: 16px;">C. CANCELLATION</strong>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">I/We agree that although this authority and mandate may be cancelled by me/us, such cancellation will not cancel the Agreement. I/we also understand that I/we cannot reclaim amounts, which have been withdrawn from my/our account (paid) in terms of this authority and mandate if such amounts were legally owing to you.</p>
        <div style="height: 12px;"></div>
        <strong style="font-size: 16px;">D. ASSIGNMENT:</strong>
        <div style="height: 12px;"></div>
        <p style="margin: 0; padding: 0;">I/We acknowledge that this authority may be ceded or assigned to a third party if the Agreement is also ceded or assigned to that third party.
        Signed and agreed to electronically on this date: <strong><underline>{{data.date}}</underline></strong></p>
        <div style="height: 12px;"></div>
        <div style="height: 12px;"></div>
      </div>
      <div
        style="display: flex; flex-direction: row; width: 100%; justify-content: space-between; align-items: center;"
      >
      <strong [style.fontSize]="smallScreen ? '10px' : '16px'" [style.width]="smallScreen ? '50%' : '75%'">By clicking “I ACCEPT”, you sign and agree to the debit order mandate.</strong>
      <button
        mat-raised-button
        class="signup-button"
        (click)="acceptTermsAndConditions()"
        style="padding: 0px; width: 100px; font-weight: bold; font-size: 14px; color: rgb(255, 255, 255); background-color: #3380F5 !important;"
      >
        <strong>I ACCEPT</strong>
      </button>
      </div>
    </ng-container>
    <ng-template #loadingTemplate>
      <mat-spinner
        mode="indeterminate"
        color="primary"
        diameter="24"
      >
      </mat-spinner>
    </ng-template>
  </mat-card>
</div>
