<section  fxLayout="column" fxLayoutAlign="center center"  fxLayoutGap="12px">
  <div class="container" >
    <div fxLayout="column" fxLayoutAlign="center center"  fxLayoutGap="12px">
      <div fxLayoutGap="12px" fxLayoutAlign="center center">
        <h1 class="Success">SIGN UP SUCCESSFUL</h1>
      </div>
      <div fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between"  fxLayout.gt-md="column">
        <p>Thank you for signing up with Clear Access. Your order has been successfully submitted. Please check your email for further information.</p>
      </div>
    </div>
  </div>
</section>
