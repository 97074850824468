import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-octotel-promo',
  templateUrl: './octotel-promo.component.html',
  styleUrls: ['./octotel-promo.component.scss']
})
export class OctotelPromoComponent implements OnInit {

  @Input() type: string;

  // mobile = false;

  constructor(
    // private breakpointObserver: BreakpointObserver,
    private dialogRefTwo: MatDialogRef<OctotelPromoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {

    this.dialogRefTwo.close();
  }
}
