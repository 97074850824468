import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Providers } from '../interfaces/Providers';
import { FibreHome } from '../interfaces/fibre-home';
import { Tariff } from '../interfaces/Tariff';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  baseUrl = environment.baseUrl + '/plans';

  constructor(private http: HttpClient) {}

  getProvidersByNames(nameArray, serviceType): Observable<FibreHome[]> {
    const nameString = nameArray.join();
    return this.http.get<FibreHome[]>(this.baseUrl + '/providers/' + nameString + '?service_type=' + serviceType);
  }

  checkPromoCode(promoCode, tariffId): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/checkPromoCode/' + tariffId + '/' + promoCode);
  }

  addPromoCodesPlans(promoCode): Observable<Tariff[]> {
    return this.http.get<Tariff[]>(this.baseUrl + '/getPromoCodePlans' + '/' + promoCode);
  }
}
