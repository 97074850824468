<section  fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout.gt-sm="row" fxLayout.lt-sm="column">

  <div class="container" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.lt-sm="column">

    <mat-card fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout="column">

      <div class="block-one" fxLayoutAlign="center center" fxLayoutAlign="space-between">
        <div class="blank-space"></div>
        <h2> Debit Order Authority/Mandate </h2>
        <span>
          <mat-dialog-actions>
          <div class="close-icon"> <mat-icon aria-hidden="false" aria-label="Example home icon" [mat-dialog-close]="true" cdkFocusInitial>close</mat-icon></div>
          </mat-dialog-actions>
        </span>
      </div>



      <div class="scroll-block">
        <cdk-virtual-scroll-viewport class="example-viewport" itemSize="20">
          <dl class="example-dl">


            <ng-container>
              <dt><strong style="font-size: 16px;">A. Authority/Mandate: Paper/Electronic</strong></dt>
             <p style="margin: 0; padding: 0;">Given by (name of Account Holder): <strong>{{newSubscription.bankingDetails.accHolderName}}</strong></p>



              <p style="margin: 0; padding: 0;">Address: <strong> {{newSubscription.customerDetails.address}} {{newSubscription.selectedEstate.fullname}}, {{newSubscription.selectedEstate.streetNumber}} {{newSubscription.selectedEstate.streetaddr}}, {{newSubscription.selectedEstate.suburb}}, {{newSubscription.selectedEstate.city}}, {{newSubscription.selectedEstate.postal_code}}</strong></p>

              <p style="color: #3380F5; margin: 0; padding: 0;">Bank Account Details:</p>

              <p style="margin: 0; padding: 0;">Bank Name:<strong> {{newSubscription.bankingDetails.bankName}}</strong></p>

              <p style="margin: 0; padding: 0;">Branch Number:<strong> {{newSubscription.bankingDetails.branchCode}}</strong></p>
              <p style="margin: 0; padding: 0;">Account Number:<strong> {{newSubscription.bankingDetails.accNo}}</strong></p>
              <p style="margin: 0; padding: 0;">Type of Account:<strong> {{newSubscription.bankingDetails.accType}}</strong></p>
              <p style="margin: 0; padding: 0;">Date:<strong> {{todayDate | date }}</strong></p>
              <p style="margin: 0; padding: 0;">Contact Number:<strong> {{newSubscription.customerDetails.cellNumber}}</strong></p>
              <p style="margin: 0; padding: 0;">Amount (Variable, based on service and any outstanding amounts due):<strong>R{{newSubscription.selectedPackage.price}}</strong></p>
              <p style="margin: 0; padding: 0;">To (Name of Beneficiary):<strong> Clear Access (Pty) Ltd</strong></p>
              <p style="margin: 0; padding: 0;">Unit number:<strong>{{newSubscription.customerDetails.address}}</strong></p>
              <p style="margin: 0; padding: 0;">Abbreviated Shortname to be used:<strong> CLEAR ACCE</strong></p>

              <!-- <p style="margin: 0; padding: 0;">Refer to contract reference number: {{ data.contractReferenceNumber }} (“the Contract Reference Number”)</p> -->

              <p style="margin: 0; padding: 0;">I/We hereby authorise Netcash (Pty) Ltd to issue and deliver payment instructions to your banker for collection against my/our abovementioned account at my/our abovementioned bank on condition that the sum of such payment instructions will not differ from my/our obligations as agreed to in the Contract Reference Number.</p>

              <p style="margin: 0; padding: 0;">The individual payment instructions so authorised must be issued and delivered on the date when the obligation in terms of the Agreement is due and the amount of each individual payment instruction may not differ as agreed to in terms of the Agreement.</p>

              <p style="margin: 0; padding: 0;">The payment instructions so authorised to be issued must carry the Contract Reference Number, included in the said payment instructions, and must be provided to identify the specific contract. The said Contract Reference Number should be added to this form in section E before the issuing of any payment instruction and communicated directly after having been completed.</p>

              <p style="margin: 0; padding: 0;">I/We agree that the first payment instruction will be issued and delivered (debitOrderDate) and thereafter regularly (debitOrderDate of each month. If however, the date of the payment instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the following business day; or</p>

              <p style="margin: 0; padding: 0;">Subsequent payment instructions will continue to be delivered in terms of this authority until the obligations in terms of the Agreement have been paid or until this authority is cancelled by me/us by giving you notice in writing of not less than the interval (as indicated in the previous clause) and sent by prepaid registered post or delivered to your address indicated above.</p>

              <strong style="font-size: 16px;">B. MANDATE</strong>

              <p style="margin: 0; padding: 0;">I/We acknowledge that all payment instructions issued by you will be treated by my/our abovementioned bank as if the instructions had been issued by me/us personally.</p>

              <strong style="font-size: 16px;">C. CANCELLATION</strong>

              <p style="margin: 0; padding: 0;">I/We agree that although this authority and mandate may be cancelled by me/us, such cancellation will not cancel the Agreement. I/we also understand that I/we cannot reclaim amounts, which have been withdrawn from my/our account (paid) in terms of this authority and mandate if such amounts were legally owing to you.</p>

              <strong style="font-size: 16px;">D. ASSIGNMENT:</strong>

              <p style="margin: 0; padding: 0;">I/We acknowledge that this authority may be ceded or assigned to a third party if the Agreement is also ceded or assigned to that third party.
                Signed and agreed to electronically on this date: <strong>{{todayDate | date }}</strong></p>

            </ng-container>

          </dl>
        </cdk-virtual-scroll-viewport>
      </div>

      <div class="block-two" fxLayoutGap="12px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
        <p class="accept" fxFlex="start"> By clicking " I Accept", you agree to the terms and conditions of this contract</p>
        <button class="i-accept-btn" [mat-dialog-close]="false">I ACCEPT</button>
      </div>

    </mat-card>

  </div>
</section>

