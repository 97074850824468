import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {catchError} from 'rxjs/operators';
import {EstCentInstallOptionInterface} from '../interfaces/estCentInstallOptionInterface';

@Injectable({
  providedIn: 'root',
})
export class EstInstallOptionService {
  constructor(
    public http: HttpClient,
  ) {
  }
  baseUrl = environment.baseUrl + '/estInstallOption';

  getCustomerCentInstallOptionsInfo(): Observable<EstCentInstallOptionInterface[]> {
    return this.http.get<EstCentInstallOptionInterface[]>(this.baseUrl)
      .pipe( catchError(this.handleError) );
  }

  // tslint:disable-next-line:typedef
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Could not retrieve Install options; please try again later.'));
  }
}


