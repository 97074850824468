import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {EstGetEstatePlanService} from '../../../services/estGetEstatePlanService';

@Component({
  selector: 'app-cent-est-select-package',
  templateUrl: './cent-est-select-package.component.html',
  styleUrls: ['./cent-est-select-package.component.scss']
})
export class CentEstSelectPackageComponent implements OnInit {

  public estateCentDevice = [];

  constructor(
    private pick: EstatePickerComponent,
    private estGetEstatePlanService: EstGetEstatePlanService,
    private Shared: SharedService,
  ) {
  }

  ngOnInit(): void {
    // get estate plan by Id to dispaly data and subrcibe to data to use later on signup form
    this.estGetEstatePlanService.getEstCustomerPlanSelectionInfo(this.Shared.newSubscription.selectedEstate.id)
      .subscribe(data => {
        this.estateCentDevice = data;
        // set subrcibed data to add to selected device
        this.Shared.setDevice(this.estateCentDevice);
      });
  }

  // tslint:disable-next-line:typedef
  onClicked(plan) {
    this.returnPackages();
    //  set selected package value to be displayed on form
    this.Shared.setPackage(plan);
    this.pick.goToNextCentury();
  }

  returnPackages(): string {
    try {
      return `${this.estateCentDevice[1].title}-${this.estateCentDevice[1].service_detail}-${this.estateCentDevice[1].price}`;
    } catch (e) {
      return 'return error =' + e;
    }
  }
}
