import { AppError, AppErrors } from './app.state';

export class SetError {
  static readonly type = '[App] Set Error';
  constructor(public payload: { error: AppError; stack: string }) {}
}

export class ClearErrorsByTypeAndStack {
  static readonly type = '[App] Clear Errors By Type and Stack';
  constructor(public payload: { type: string; stack: string }) {}
}
