import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {SharedService} from './shared.service';


@Injectable({ providedIn: 'root' })
export class NavigationService {

  private history: string[] = [];
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private Shared: SharedService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
