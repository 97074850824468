<!-- Mobile friendly -->
<div *ngIf="smallScreen" style="padding: 8px; display: flex; align-items: center; flex-direction: column;">
  <form [formGroup]="signupForm" *ngIf="signupForm && activeTariff" class="clear_access_form" style="flex-direction: row;">

    <p style="color: #3380F5; width: 100%; font-weight: 700; margin-bottom: 0.75rem; font-size: 16px;">Personal Details:</p>

    <div
      *ngIf="signupForm.controls.providerSelected.valid"
      class="clear_access_form form_personal_details"
      style="width: 100%"
    >
      <div class="field-container" #cardNumberForm class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" required />
          <mat-error> Please enter a valid first name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input type="text" matInput formControlName="lastName" required />
          <mat-error> Please enter a valid last name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Personal ID Number</mat-label>
          <input type="text" matInput formControlName="idNumber" required />
          <mat-error> Please enter an ID number</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Email Address</mat-label>
          <input type="text" matInput formControlName="email" required />
          <mat-error>Please enter a valid email address</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Cell Number</mat-label>
          <input type="text" matInput formControlName="cellNumber" required />
          <mat-error>Please enter a cell number</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Other Number</mat-label>
          <input type="text" matInput formControlName="otherNumber" />
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Enter a date of birth</mat-label>
          <input matInput [max]="today" [matDatepicker]="picker" formControlName="dateOfBirth" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>Please enter or choose a valid date of birth</mat-error>
        </mat-form-field>
      </div>

      <ng-container
        *ngIf="activeTariff  && packageOptions && packageOptions[selectedPackageIdx]"
      >
        <mat-form-field appearance="fill" class="input__half__width">
          <mat-label>Provider</mat-label>
          <input matInput disabled name="provider" [value]="activeTariff.connectivity_provider">
        </mat-form-field>

        <mat-form-field appearance="fill" class="input__half__width">
          <mat-label>Package selection</mat-label>
          <input matInput disabled [value]="activeTariff.title">
          <div >



            <!--   CRAZY *8  disclaimer goes here Small to Mobile -->
            <mat-card-subtitle class="disclaimer disclaimer-mobile" *ngIf="showCrazy8Disclaimer()" [style.fontSize]="smallScreen ? '9px' : '11px'">*Router and installation cost subject to invoicing.</mat-card-subtitle>
          </div>
        </mat-form-field>

          <mat-form-field class="mat-form-field-disabled input__half__width" appearance="fill">
            <mat-label>Address</mat-label>
            <input matInput disabled type="text" matInput [formControl]="signupForm.controls.address" readonly required>
        <!-- <input matInput disabled type="text" matInput [formControl]="$any(signupForm).controls.address" readonly required>-->
          </mat-form-field>

        <!-- <mat-form-field appearance="fill" class="input__half__width">
          <mat-label>Please select an installation option</mat-label>
          <mat-select
            formControlName="packageInstallSelectedIndex"
          >
            <mat-option
              *ngFor="let installOption of packageOptions[selectedPackageIdx].install_options.split(','); index as i"
              [value]="installOption"
            >
              {{ installOption }}
            </mat-option>
            <mat-error *ngIf="!signupForm.controls.packageInstallSelectedIndex.valid && signupForm.controls.packageInstallSelectedIndex.touched">
              Please select a valid install option
            </mat-error>
          </mat-select>
        </mat-form-field> -->

      </ng-container>
    </div>

    <ng-container *ngIf="activeTariff">
      <div class="field-container mb-2 ignore-margin">
        <mat-form-field style="width: 100%;" class="promo-code-form-field" appearance="fill">
          <mat-label>Promo Code</mat-label>
          <input matInput formControlName="promoCode" type="text" />
          <mat-spinner *ngIf="promoLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
          <mat-hint *ngIf="promotionErrors$ | async as promotionErrors"><p *ngIf="promotionErrors && promotionErrors.length > 0">{{ promotionErrors[0].message }}</p></mat-hint>
          <mat-hint *ngIf="promoObject && promoObject.promo_type === 'price'">
            <div style="display: flex;"><p style="margin-right: 3px; color: #0567FC; font-weight: 600;">Promotion code applied! </p><p style="color: #3380F5;">Promotion code effect: <span style="text-decoration: line-through">R{{ promoObject.old_price }}</span> R{{ promoObject.promo_offer }}</p></div>
          </mat-hint>
          <mat-hint *ngIf="promoObject && promoObject.promo_type !== 'price'">
            <div style="display: flex;"><p style="margin-right: 3px; color: #0567FC; font-weight: 600;">Promotion code applied! </p><p style="color: #3380F5;">Promotion code effect: {{ promoObject.promo_offer }}</p></div>
          </mat-hint>
        </mat-form-field>
      </div>

      <ng-container *ngIf="promotionErrors$ | async as promotionErrors">
        <div hidden style="display: none !important;" #promotionErrorsDiv *ngIf="promotionErrors && promotionErrors.length > 0">
          <div class="promotion-error__item" style="width: 100%;" *ngIf="promotionErrors && promotionErrors.length > 0">
            <p style="color: #3380F5;">{{ promotionErrors[0].message }}</p>
          </div>
        </div>
      </ng-container>

      <div hidden style="display: none !important;" #promoEffectDiv *ngIf="promoObject">
        <h4 style="margin-bottom: 0;">Promotion code applied!</h4>
        <p *ngIf="promoObject.promo_type === 'price'; else promoNotPriceTemplate">
          Promotion code effect: <span style="text-decoration: line-through">R{{ promoObject.old_price }}</span> R{{ promoObject.promo_offer }}
        </p>
        <ng-template #promoNotPriceTemplate>
          <p>Promotion code effect: {{ promoObject.promo_offer }}</p>
        </ng-template>
      </div>
    </ng-container>


  </form>
  <form [formGroup]="bankingForm" class="clear_access_form-form" *ngIf="packageSelectionValid()">

    <div class="clear_access_form bank-inner-form">

      <p style="color: #3380F5; width: 100%; font-weight: 700; margin-bottom: 0.75  rem; font-size: 16px;">Bank Details:</p>

      <div class="clear_access_form bank__form--after-select">
        <div class="bankinfo__form">

          <div class="field-container" class="input__half__width bank-input bank-input--right no-margin-right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Account Holder Name</mat-label>
              <input type="text" matInput formControlName="accountHolderName" required />
              <mat-error>Please enter a valid Account holder name</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Bank Name</mat-label>
              <input type="text" matInput formControlName="bankName" required />
              <mat-error>Please enter a valid bank name</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right no-margin-right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Account Number</mat-label>
              <input type="text" matInput formControlName="accountNumber" required />
              <mat-error>Please enter a valid account number</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">
            <mat-form-field appearance="fill">
              <mat-label>Account Type</mat-label>
              <mat-select [formControl]="bankingForm.controls.accountType" name="accountTypes">
                <mat-option *ngFor="let bankAccountType of bankAccountTypes" [value]="bankAccountType">
                  {{ bankAccountType }}
                </mat-option>
              </mat-select>
              <mat-error>Please select a valid bank account type</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" style="width: 100% !important;" class="input__half__width bank-input bank-input--right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Branch Code</mat-label>
              <input type="text" matInput formControlName="accountBranch" required />
              <mat-error>Please enter a valid bank branch number</mat-error>
            </mat-form-field>
          </div>

        </div>
      </div>
    </div>
  </form>
  <form class="clear_access_form" style="flex-direction: column; width: 100% !important;" [formGroup]="documentsForm" *ngIf="bankInfoValid()">
    <mat-card style="border-radius: 24px; margin-bottom: 20px; padding: 10px;" class="documents-checkbox-container">
      <mat-checkbox class="documents-checkbox" formControlName="hasDocuments" value="true">I have all my documents ready to attach</mat-checkbox>
    </mat-card>

    <div class="bankingform__documents" style="width: 100%; margin-bottom: 12px" *ngIf="documentsForm.controls.hasDocuments.value">
      <!-- personal identification -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
          Personal Identification Document
        </label>
        <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.personalIdentificationDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.personalIdentificationDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="personalIdentificationDocument" id="file-upload-id" class="bankingform__fileinput-input" type="file" />
      </div>

      <!-- proof of bank account -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
          Proof of Bank Account
        </label>
        <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.proofOfBankAccountDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.proofOfBankAccountDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="proofOfBankAccountDocument" id="file-upload-account" class="bankingform__fileinput-input" type="file" />
      </div>

      <!-- proof of address -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom"> Proof of Address </label>
        <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.proofOfAddressDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.proofOfAddressDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="proofOfAddressDocument" id="file-upload-address" class="bankingform__fileinput-input" type="file" />
      </div>
    </div>

    <p style="font-size: 12px; font-weight: 600; color: darkgrey !important;">*Your provider, package and address is automatically set based on your earlier
      inputs. To change it please go back.</p>
  </form>
  <div [style.width]="smallScreen ? '100%' : '87%'" style="display: flex; justify-content: flex-end; margin-bottom: 20px;">
    <button
      mat-raised-button
      class="signup-button"
      (click)="handleSignup()"
      [disabled]="loading"
      style="padding: 0px; height: 38px; width: 100px; font-weight: bold; font-size: 14px; color: rgb(255, 255, 255); background-color: #3380F5;"
    >
      <span *ngIf="!loading">
        SIGN UP
      </span>
      <span *ngIf="loading" class="spinner">
      </span>
    </button>
  </div>
</div>
<!-- Non-Mobile friendly -->
<div *ngIf="!smallScreen" style="padding: 20px 100px 0 100px; display: flex; align-items: center; flex-direction: column;">
  <form [formGroup]="signupForm" *ngIf="signupForm && activeTariff" class="clear_access_form" style="flex-direction: row;">

    <p style="color: #3380F5; width: 87%; font-weight: 700; font-size: 16px; margin-bottom: 1rem;">Personal Details:</p>

    <div
      *ngIf="signupForm.controls.providerSelected.valid"
      class="clear_access_form form_personal_details"
      style="width: 100%;"
    >


      <div class="field-container" #cardNumberForm class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" required />
          <mat-error> Please enter a valid first name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input type="text" matInput formControlName="lastName" required />
          <mat-error> Please enter a valid last name</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Personal ID Number</mat-label>
          <input type="text" matInput formControlName="idNumber" required />
          <mat-error> Please enter an ID number</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Email Address</mat-label>
          <input type="text" matInput formControlName="email" required />
          <mat-error>Please enter a valid email address</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Cell Number</mat-label>
          <input type="text" matInput formControlName="cellNumber" required />
          <mat-error>Please enter a cell number</mat-error>
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Other Number</mat-label>
          <input type="text" matInput formControlName="otherNumber" />
        </mat-form-field>
      </div>

      <div class="field-container" class="input__half__width no-margin-right">
        <mat-form-field class="w-10" appearance="fill">
          <mat-label>Enter a date of birth</mat-label>
          <input matInput [max]="today" [matDatepicker]="picker" formControlName="dateOfBirth" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <!-- <mat-hint class="ca__form-hint">
            <span>Format: dd/mm/yyyy</span>
            <span>&nbsp;Example: 8/8/1990</span>
          </mat-hint> -->
          <mat-error>Please enter or choose a valid date of birth</mat-error>
        </mat-form-field>
      </div>

      <ng-container
        *ngIf="activeTariff && packageOptions && packageOptions[selectedPackageIdx]"
      >
        <mat-form-field appearance="fill">
          <mat-label>Provider</mat-label>
          <input matInput disabled name="provider" [value]="activeTariff.connectivity_provider">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Package selection</mat-label>
          <input matInput disabled [value]="activeTariff.title">
          <div >
            <!--   CRAZY *8  disclaimer goes here desktop-->
            <mat-card-subtitle  class="disclaimer disclaimer-desktop" *ngIf="showCrazy8Disclaimer()" [style.fontSize]="smallScreen ? '9px' : '10px'">*Router and installation cost subject to invoicing.</mat-card-subtitle>
          </div>
        </mat-form-field>


        <mat-form-field class="mat-form-field-disabled" appearance="fill">
          <mat-label>Address</mat-label>
          <input matInput disabled type="text" matInput [formControl]="signupForm.controls.address" readonly required>
          <!-- <input matInput disabled type="text" [formControl]="$any(signupForm).controls.address" readonly required>-->
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Please select an installation option</mat-label>
          <mat-select
            formControlName="packageInstallSelectedIndex"
          >
            <mat-option
              *ngFor="let installOption of packageOptions[selectedPackageIdx].install_options.split(','); index as i"
              [value]="installOption"
            >
              {{ installOption }}
            </mat-option>
            <mat-error *ngIf="!signupForm.controls.packageInstallSelectedIndex.valid && signupForm.controls.packageInstallSelectedIndex.touched">
              Please select a valid install option
            </mat-error>
          </mat-select>
        </mat-form-field> -->

      </ng-container>

      <ng-container *ngIf="activeTariff">
        <div #activePromoCodeDiv class="field-container mb-2 input__half__width ignore-margin">
          <mat-form-field class="w-10 promo-code-form-field" appearance="fill">
            <mat-label>Promo Code</mat-label>
            <input matInput formControlName="promoCode" type="text" />
            <mat-spinner *ngIf="promoLoading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <mat-hint *ngIf="promotionErrors$ | async as promotionErrors"><p *ngIf="promotionErrors && promotionErrors.length > 0">{{ promotionErrors[0].message }}</p></mat-hint>
            <mat-hint *ngIf="promoObject && promoObject.promo_type === 'price'">
              <div style="display: flex;"><p style="margin-right: 3px; color: #0567FC; font-weight: 600;">Promotion code applied! </p><p style="color: #3380F5;">Promotion code effect: <span style="text-decoration: line-through">R{{ promoObject.old_price }}</span> R{{ promoObject.promo_offer }}</p></div>
            </mat-hint>
            <mat-hint *ngIf="promoObject && promoObject.promo_type !== 'price'">
              <div style="display: flex;"><p style="margin-right: 3px; color: #0567FC; font-weight: 600;">Promotion code applied! </p><p style="color: #3380F5;">Promotion code effect: {{ promoObject.promo_offer }}</p></div>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="field-container" class="input__half__width bank-input bank-input--right">

        </div>


        <ng-container *ngIf="promotionErrors$ | async as promotionErrors">
          <div hidden style="display: none !important;" #promotionErrorsDiv *ngIf="promotionErrors && promotionErrors.length > 0">
            <div class="promotion-error__item" style="width: 100%;" *ngIf="promotionErrors && promotionErrors.length > 0">
              <p style="color: #3380F5;">{{ promotionErrors[0].message }}</p>
            </div>
          </div>
        </ng-container>

        <div hidden style="display: none !important;" #promoEffectDiv *ngIf="promoObject">
          <h4 style="margin-bottom: 0;">Promotion code applied!</h4>
          <p *ngIf="promoObject.promo_type === 'price'; else promoNotPriceTemplate">
            Promotion code effect: <span style="text-decoration: line-through">R{{ promoObject.old_price }}</span> R{{ promoObject.promo_offer }}
          </p>
          <ng-template #promoNotPriceTemplate>
            <p>Promotion code effect: {{ promoObject.promo_offer }}</p>
          </ng-template>
        </div>
      </ng-container>

    </div>
  </form>
  <form [formGroup]="bankingForm" *ngIf="packageSelectionValid()">

    <div class="clear_access_form bank-inner-form">

      <p style="color: #3380F5; width: 87%; font-weight: 700; font-size: 16px; margin-bottom: 1rem;">Bank Details:</p>

      <div class="clear_access_form bank__form--after-select">
        <div class="bankinfo__form">

          <div class="field-container" class="input__half__width bank-input bank-input--right no-margin-right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Account Holder Name</mat-label>
              <input type="text" matInput formControlName="accountHolderName" required />
              <mat-error>Please enter a valid Account holder name</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Bank Name</mat-label>
              <input type="text" matInput formControlName="bankName" required />
              <mat-error>Please enter a valid bank name</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right no-margin-right">
            <mat-form-field class="w-10" appearance="fill">
              <mat-label>Account Number</mat-label>
              <input type="text" matInput formControlName="accountNumber" required />
              <mat-error>Please enter a valid account number</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">
            <mat-form-field appearance="fill">
              <mat-label>Account Type</mat-label>
              <mat-select [formControl]="bankingForm.controls.accountType" name="accountTypes">
                <mat-option *ngFor="let bankAccountType of bankAccountTypes" [value]="bankAccountType">
                  {{ bankAccountType }}
                </mat-option>
              </mat-select>
              <mat-error>Please select a valid bank account type</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">
            <mat-form-field appearance="fill">
              <mat-label>Branch Code</mat-label>
              <input type="text" matInput formControlName="accountBranch" required />
              <mat-error>Please enter a valid bank branch number</mat-error>
            </mat-form-field>
          </div>

          <div class="field-container" class="input__half__width bank-input bank-input--right">

          </div>

        </div>
      </div>
    </div>
  </form>
  <form class="clear_access_form" style="flex-direction: column; width: 87% !important;" [formGroup]="documentsForm" *ngIf="bankInfoValid()">
    <mat-card style="border-radius: 24px; margin-bottom: 20px; padding: 10px;" class="documents-checkbox-container">
      <mat-checkbox class="documents-checkbox" formControlName="hasDocuments" value="true">I have all my documents ready to attach</mat-checkbox>
    </mat-card>

    <div class="bankingform__documents" style="width: 100%; margin-bottom: 12px" *ngIf="documentsForm.controls.hasDocuments.value">
      <!-- personal identification -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
          Personal Identification Document
        </label>
        <label for="file-upload-id" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.personalIdentificationDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.personalIdentificationDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="personalIdentificationDocument" id="file-upload-id" class="bankingform__fileinput-input" type="file" />
      </div>

      <!-- proof of bank account -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom">
          Proof of Bank Account
        </label>
        <label for="file-upload-account" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.proofOfBankAccountDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.proofOfBankAccountDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="proofOfBankAccountDocument" id="file-upload-account" class="bankingform__fileinput-input" type="file" />
      </div>

      <!-- proof of address -->
      <div class="bankingform__fileinput-container">
        <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--left bankingform__fileinput-custom"> Proof of Address </label>
        <label for="file-upload-address" class="custom-file-upload bankingform__fileinput-custom--right bankingform__fileinput-custom">
          <svg *ngIf="!documentsForm.controls.proofOfAddressDocument.value" class="bankingform__upload-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
          </svg>
          <svg *ngIf="documentsForm.controls.proofOfAddressDocument.value" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </label>
        <input (change)="onFileChange($event)" name="proofOfAddressDocument" id="file-upload-address" class="bankingform__fileinput-input" type="file" />
      </div>
    </div>

    <p style="font-size: 12px; font-weight: 600; color: darkgrey !important;">*Your provider, package and address is automatically set based on your earlier
      inputs. To change it please go back.</p>
  </form>

  <div [style.width]="smallScreen ? '100%' : '87%'" style="display: flex; justify-content: flex-end; margin-bottom: 20px;">
    <button
      mat-raised-button
      class="signup-button"
      (click)="handleSignup()"
      [disabled]="loading"
      style="padding: 0px; height: 38px; width: 100px; font-weight: bold; font-size: 14px; color: rgb(255, 255, 255); background-color: #3380F5;"
    >
      <span *ngIf="!loading">
        SIGN UP
      </span>
      <span *ngIf="loading" class="spinner">
      </span>
    </button>
  </div>

</div>
