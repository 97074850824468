import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../../../services/shared.service';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {EstInstallOptionService} from '../../../services/estInstallOptionService';
import {EstCustomerInterface} from '../../../interfaces/estCustomerInterface';


@Component({
  selector: 'app-cent-est-install-option',
  templateUrl: './cent-est-install-option.component.html',
  styleUrls: ['./cent-est-install-option.component.scss']
})
export class CentEstInstallOptionComponent implements OnInit {

  public estateInstallOption = [];
  newSubscription: EstCustomerInterface;
  constructor(
    private pick: EstatePickerComponent,
    public router: Router,
    private route: ActivatedRoute,
    private estInstallOptionService: EstInstallOptionService,
    private Shared: SharedService,
  ) {
  }

  ngOnInit(): void {
    this.estInstallOptionService.getCustomerCentInstallOptionsInfo()
      .subscribe(data => {
        this.estateInstallOption = data;
        // set subrcibed data to add to selected install option
        this.Shared.setInstallOption(this.estateInstallOption);
      });
    this.Shared.newSubscription.selectedDevice.description = null;
    this.Shared.newSubscription.selectedDevice.cost = null;
    this.Shared.newSubscription.selectedDevice.id = null;
  }

  // tslint:disable-next-line:typedef
  onClicked(install) {
    this.returnInstallOption();
    // setDevice set selected device value to be displayed on form
    this.Shared.setInstallOption(install);

    if (install.device_required === false) {
      this.router.navigate(['/estate-picker/cent-est-signup'], {relativeTo: this.route});
    } else {
      this.pick.goToNextCentury();
    }

  }

  //  return  string format data from server
  returnInstallOption(): string {
    return `${this.estateInstallOption[1].description}-${this.estateInstallOption[1].cost}-${this.estateInstallOption[1].term}`;
  }
}
