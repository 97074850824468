<!-- Not mobile friendly -->
<mat-card
  *ngIf="!smallScreen"
  style="border-radius: 24px !important; box-shadow: 0 !important; min-height: 501px !important; padding: 12px !important; text-align: center; display: flex; flex-direction: column; justify-content: flex-start; align-items: center;"
>
  <div style="width: 100%; height: 100%;">
    <div style="display: flex; justify-content: flex-end; margin-right: 12px;">
      <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <p
      style="margin-bottom: 2px; font-size: 24px; color: green;"
    >GREAT NEWS</p>
    <p>We've got you covered! Choose your package available on your Fibre Network Operator:</p>
    <ng-container *ngIf="tariffs else loadingTemplate">
      <div style="flex-wrap: wrap; display: flex; max-height: 100%; justify-content: center; align-items: center; flex-direction: row;">
        <div
          style="display: flex; padding: 6px; justify-content: center; align-items: flex-end; flex-wrap: wrap; text-align: center;"
          [ngClass]="tariffsSplit.length > 1 ? 'non-single-tariff' : 'single-tariff'"
          *ngFor="let tariffSplit of tariffsSplit; let i = index;"
        >
          <ng-container
            *ngIf="tariffSplit.length > 0"
          >
            <mat-card
              class="mat-elevation-z"
              style="overflow-y: auto; width: 100%; padding: 8px; padding-bottom: 28px;"
            >
            <!-- height: 325px; max-height: 325px; -->
            <img
              style="margin-bottom: 12px;"
              [src]="'../../../../../assets/provider-logos/fno/' + tariffSplit[0].connectivity_provider?.toUpperCase() + '.png'"
              width="160"
            >
            <div
              style="width: 100%; height: 325px; max-height: 325px;"
            >
              <mat-card
                *ngFor="let tariff of tariffSplit"
                class="tariff"
                style="box-shadow: 0 !important; width: 100%; padding-top: 12px; padding-bottom: 12px; padding-left: 0 !important; padding-right: 0 !important; font-size: 14px; font-weight: 600; margin-bottom: 8px;"
                (click)="chooseTariff(tariff);"
                >
                <div
                  style="width: 100%; justify-content: space-around; align-items: center; display: flex; flex-direction: row;"
                >
                  <p style="margin-bottom: 0px; font-size: 12px; max-width: 160px;">{{ tariff.title }}</p>
                  <p style="display: flex; justify-content: flex-start; min-width: 275px; color: #3380F5; margin-bottom: 0px; font-size: 24px; font-weight: 700; min-width: 150px;"><strong>R{{ tariff.price | number: '1.2' }}*</strong></p>
                  <div
                  style="background-color: #3380F5; border-radius: 24px; font-size: 13px; color: white; padding: 4px 8px 4px 8px;"
                  >
                    SELECT
                  </div>
                </div>
              </mat-card>
            </div>
          </mat-card>
          <!-- <div *ngIf="checkElementMod(i)" style="height: 100%; border: 1px solid black; width: 1px;"></div> -->
          </ng-container>
          <p
            *ngIf="tariffSplit.length > 0"
            style="width: 100%; margin-top: 6px; text-align: left; align-self: flex-start; font-weight: 400; color: grey;">* All prices include vat *
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template
    #loadingTemplate
  >
    <div
      style="display: flex; justify-content: center; align-items: center; width: 100%;"
    >
      <mat-spinner
        color="primary"
        diameter="48"
      >
      </mat-spinner>
    </div>
  </ng-template>
</mat-card>
<!-- Mobile friendly -->
<mat-card
  *ngIf="smallScreen"
  style="border-radius: 24px !important; box-shadow: 0 !important; min-height: 501px !important; padding: 12px !important; text-align: center; display: flex; flex-direction: column; justify-content: flex-start; align-items: center;"
>
  <div style="width: 100%; height: 100%;">
    <div style="display: flex; position: absolute; right: 0; justify-content: flex-end; margin-right: 12px;">
      <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
    <p
      style="margin-bottom: 2px; font-size: 16px; color: green;"
    >GREAT NEWS</p>
    <p style="font-size: 11px;">We've got you covered! Choose your package available on your Fibre Network Operator:</p>
    <ng-container *ngIf="tariffs else loadingTemplate">
      <div style="max-height: 100%; justify-content: center; align-items: center; flex-direction: column;">
        <div
          style="display: flex; padding: 6px; justify-content: center; align-items: flex-end; flex-wrap: wrap; text-align: center;"
          *ngFor="let tariffSplit of tariffsSplit; let i = index;"
        >
          <ng-container
            *ngIf="tariffSplit.length > 0"
          >
            <mat-card
              class="mat-elevation-z"
              style="width: 100%; padding: 8px; padding-bottom: 28px;"
            >
            <!-- height: 325px; max-height: 325px; -->
            <img
              style="margin-bottom: 12px;"
              [src]="'../../../../../assets/provider-logos/fno/' + tariffSplit[0].connectivity_provider?.toUpperCase() + '.png'"
              width="160"
            >
            <div
              style="width: 100%;"
            >
              <mat-card
                *ngFor="let tariff of tariffSplit"
                class="tariff"
                style="box-shadow: 0 !important; width: 100%; padding-top: 12px; padding-bottom: 12px; padding-left: 0 !important; padding-right: 0 !important; font-size: 14px; font-weight: 600; margin-bottom: 8px;"
                (click)="chooseTariff(tariff);"
                >
                <div
                  style="width: 100%; justify-content: space-around; align-items: center; display: flex; flex-direction: column;"
                >
                  <p style="margin-bottom: 0px; font-size: 12px;">{{ tariff.title }}</p>
                  <div style="display: flex; flex-direction: row; align-items: center; width: 75%; justify-content: space-between;">
                    <p style="display: flex; justify-content: flex-start; min-width: 120px; color: #3380F5; margin-bottom: 0px; font-size: 20px; font-weight: 700; width: 50%;">R{{ tariff.price }}*</p>
                    <div
                    style="font-family: Montserrat, sans-serif !important; width: 120px; background-color: #3380F5; border-radius: 24px; font-size: 13px; color: white; padding: 4px 8px 4px 8px;"
                    >
                      SELECT
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </mat-card>
          <!-- <div *ngIf="checkElementMod(i)" style="height: 100%; border: 1px solid black; width: 1px;"></div> -->
          </ng-container>
          <p
            *ngIf="tariffSplit.length > 0"
            style="width: 100%; margin-top: 6px; text-align: left; align-self: flex-start; font-weight: 400; color: grey;">* All prices include vat *
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template
    #loadingTemplate
  >
    <div
      style="display: flex; justify-content: center; align-items: center; width: 100%;"
    >
      <mat-spinner
        color="primary"
        diameter="48"
      >
      </mat-spinner>
    </div>
  </ng-template>
</mat-card>

