<section [fxFlex.lt-lg]="breakpointSubscription" >
  <div class="container">
    <mat-card [fxFlex.lt-lg]="breakpointSubscription" >
      <!--    close button X-->
      <div style="position: absolute; right: 0; margin-right: 2px;">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="closeDialog()" style="height: 24px; width: 24px; cursor: pointer;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <!--   TC's Heading-->
      <div class="block-one">
        <h2> Terms & Conditions </h2>
      </div>
      <!--scrollable block containing TC's Text-->
      <div class="scroll-block">
        <cdk-virtual-scroll-viewport class="example-viewport" itemSize="20" >
          <dl class="example-dl">
            <ng-container *cdkVirtualFor="let term of terms">
              <dt class="heading-text">{{term.heading}}</dt>
              <dd class="paragraph-text">{{term.paragraph}}</dd>
            </ng-container>
          </dl>
        </cdk-virtual-scroll-viewport>
      </div>
      <div class="block-two" fxLayoutGap="5px" fxLayoutAlign="center center" fxLayoutAlign="space-between"  fxLayout.lt-sm="column">

        <!--      Disclaimer text -->
          <strong   style="margin-top: 5px; font-weight: bold; line-height:100%; " [style.fontSize]="smallScreen ? '10px' : '13px'" [style.width]="smallScreen ? '50%' : '75%'" fxLayoutAlign.lt-sm="column">By clicking "I Accept", you agree to the terms and conditions of this contract.</strong>

        <!--      ACCEPT Button-->
        <button fxLayoutAlign.lt-sm="column"
                mat-raised-button
                class="signup-button"
                (click)="acceptTermsAndConditions()"
                style=" position: absolute; right: 0; margin-right: 25px; margin-top: 5px; width: 100px; font-weight: bold; font-size: 14px; color: rgb(255, 255, 255); background-color: #3380F5 !important;">
          <strong>I ACCEPT</strong>
        </button>
      </div>
    </mat-card>
  </div>
</section>

