import { Injectable } from '@angular/core';
import { CustomerInfo } from '../interfaces/CustomerInfo';
import { CustomerServices } from '../interfaces/CustomerServices';
import { CustomerPaymentAccounts } from '../interfaces/CustomerPaymentAccounts';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  baseUrl = environment.baseUrl + '/customers';

  constructor(private http: HttpClient) {}

  postCustomer(customer): Observable<any> {
    return this.http.post(this.baseUrl, customer);
  }

  putCustomerInfo(id, body: CustomerInfo): Observable<any> {
    return this.http.put(this.baseUrl + `/customer-info/${id}`, body);
  }

  putCustomerPaymentAccount(id, body: CustomerPaymentAccounts): Observable<any> {
    return this.http.put(this.baseUrl + `/customer-payment-accounts/${id}`, body);
  }

  postCustomerService(id, body: CustomerServices): Observable<any> {
    return this.http.post(this.baseUrl + `/customer-services/${id}`, body);
  }
}
