
<section fxLayoutAlign="center center"  fxLayoutGap="12px" *ngIf="![
'/estate-picker/lev-est-select-package',
'/estate-picker/lev-est-device-options',
'/estate-picker/lev-est-signup',
'/estate-picker/lev-est-terms-co',
'/estate-picker/lev-est-debitorder-mandate',
'/estate-picker/lev-est-verification',
'/estate-picker/lev-est-verification',
'/estate-picker/lev-est-signup-success',

'/estate-picker/cent-est-select-package',
'/estate-picker/cent-est-install-option',
'/estate-picker/cent-est-device-options',
'/estate-picker/cent-est-signup',
'/estate-picker/cent-est-terms-co',
'/estate-picker/cent-est-debitorder-mandate',
'/estate-picker/cent-est-verification',
'/estate-picker/cent-est-signup-success'
].includes(router.url)">

  <div class="container" fxLayoutGap="25px">
    <mat-card class="card" *ngFor="let estate of estateOptions;">
      <img src="{{estate.img}}" alt="image of estate">
      <h3>{{estate.fullname}}</h3>
        <div class="description-container">
            <p>{{estate.description}}</p>
        </div>
      <div class="button-container" fxLayoutAlign="center center">
        <button mat-button (click)="onClicked(estate)">Sign me up</button>
      </div>
    </mat-card>

  </div>
</section>
<router-outlet></router-outlet>
