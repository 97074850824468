import { Injectable } from '@angular/core';
import {EstCustomerInterface} from '../interfaces/estCustomerInterface';
import {BehaviorSubject, observable, Observable} from 'rxjs';
import {SmsPortalInterface} from '../interfaces/SmsPortalInterface';
import {EstateMapAddress} from '../interfaces/estate-map-address';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  generatedOTP: any;

  constructor() {}
  smsPortalInterface: SmsPortalInterface;

  mapAddress: EstateMapAddress = {
    map_address: null,
  };

  newSubscription: EstCustomerInterface = {
    selectedPackage: null,
    selectedDevice: null,
    bankingDetails: null,
    customerDetails: null,
    InstallOption: null,
    estateOptions: null,
    selectedEstate: null,
  };
  mandateInfo = new Observable((observer) => {
    observer.next(this.newSubscription);
  });

  smsOTPData = new Observable((observer) => {
    observer.next(this.newSubscription);
  });

  // tslint:disable-next-line:typedef
  setEstateAddress(data){
    this.newSubscription.estateOptions = data;
  }

  // tslint:disable-next-line:typedef
  setSelectedEstate(data){
    this.newSubscription.selectedEstate = data;
  }

  // tslint:disable-next-line:typedef
  setDevice(data){
    this.newSubscription.selectedDevice = data;
  }

  // tslint:disable-next-line:typedef
  setPackage(data){
    this.newSubscription.selectedPackage = data;

  }
  // tslint:disable-next-line:typedef
  setInstallOption(data){
    this.newSubscription.InstallOption = data;

  }
  // tslint:disable-next-line:typedef
  setFormMandate(data){
    this.newSubscription.customerDetails = data;
    this.newSubscription.bankingDetails = data;

  }
  // tslint:disable-next-line:typedef
  setMapAddress(data){
    this.mapAddress.map_address = data;
  }
  setOTPData(data){
    this.generatedOTP = data;
    this.smsPortalInterface.smsOTP = data;
    this.smsPortalInterface.custFullName = data;
    this.smsPortalInterface.custCellNum = data;
  }

  // tslint:disable-next-line:typedef
  getNewSubscription() {
    return this.newSubscription;
  }
}
