import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { FormComponent } from './components/form/form.component';
import { BusinessComponent } from './components/business/business.component';
import { GenericFormComponent } from './generic-form/generic-form.component';
import { CheckCoverageComponent } from './components/check-coverage/check-coverage.component';
import { SelectPackageComponent } from './components/residential/residential/select-package/select-package.component';
import { SignUpComponent } from './components/residential/residential/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/residential/residential/terms-conditions/terms-conditions.component';
import { DebitOrderComponent } from './components/residential/residential/debit-order/debit-order.component';
import { SignUpCompleteComponent } from './components/residential/residential/sign-up-complete/sign-up-complete.component';
import { ResidentialComponent } from './components/residential/residential/residential.component';

// ESTATES
import { EstatePickerComponent } from './components/estates/estate-picker/estate-picker.component';

// levy
import { LevEstSelectPackageComponent } from './components/estates/lev-est-select-package/lev-est-select-package.component';
import { LevEstSignupComponent} from './components/estates/lev-est-signup/lev-est-signup.component';
import { LevEstVerificationComponent} from './components/estates/lev-est-verification/lev-est-verification.component';
import { LevEstSignupSuccessComponent} from './components/estates/lev-est-signup-success/lev-est-signup-success.component';

// century
import { CentEstSelectPackageComponent } from './components/estates/cent-est-select-package/cent-est-select-package.component';
import { CentEstInstallOptionComponent } from './components/estates/cent-est-install-option/cent-est-install-option.component';
import { CentEstDeviceOptionsComponent } from './components/estates/cent-est-device-options/cent-est-device-options.component';
import { CentEstSignupComponent } from './components/estates/cent-est-signup/cent-est-signup.component';
import { CentEstVerificationComponent} from './components/estates/cent-est-verification/cent-est-verification.component';
import { CentEstSignupSuccessComponent } from './components/estates/cent-est-signup-success/cent-est-signup-success.component';

// BUSINESS

// import {BusinessSignupComponent} from './components/business/business-signup/business-signup.component';
// import {BusCheckCoverageComponent} from './components/business/bus-check-coverage/bus-check-coverage.component';
// import {BusSelectPackageComponent} from "./components/business/bus-select-package/bus-select-package.component";
// import {BusAddServicesComponent} from "./components/business/bus-add-services/bus-add-services.component";
// import {BusServicesOptionsComponent} from "./components/business/bus-services-options/bus-services-options.component";
// import {BusTermsCoComponent} from "./components/business/bus-terms-co/bus-terms-co.component";
// import {
//   BusDebitOrderMandateComponent
// } from "./components/business/bus-debit-order-mandate/bus-debit-order-mandate.component";
// import {BusVerificationComponent} from "./components/business/bus-verification/bus-verification.component";
// import {BusSignupSuccessComponent} from "./components/business/bus-signup-success/bus-signup-success.component";

const routes: Routes = [
  {
    path: 'signup',
    children: [
      {
        path: 'check-coverage',
        component: CheckCoverageComponent,
      },
      {
        path: 'lev-est-select-package',
        component: LevEstSelectPackageComponent,
      },
      {
        path: 'cent-est-select-package',
        component: CentEstSelectPackageComponent,
      },
      {
        path: 'complete',
        component: SignUpCompleteComponent
      },
      {
        path: 'residential',
        component: GenericFormComponent,
        data: {
          formType: 'residential'
        },
        children: [
          {
            path: 'select-package',
            component: SelectPackageComponent
          },
          {
            path: 'customer-details',
            component: SignUpComponent
          },
          {
            path: 'terms',
            component: TermsConditionsComponent
          },
          {
            path: 'debit-order',
            component: DebitOrderComponent
          },
        ]
      },
      {
        path: 'business',
        component: GenericFormComponent,
        data: {
          formType: 'business'
        }
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'signup/check-coverage',
  },
  // Levy Estate routes
  {
    path: 'estate-picker',
    component: EstatePickerComponent ,
    children: [
      {
        path: 'lev-est-select-package',
        component: LevEstSelectPackageComponent,
      },
      {
        path: 'lev-est-signup',
        component: LevEstSignupComponent
      },
      {
        path: 'lev-est-verification',
        component: LevEstVerificationComponent
      },
      {
        path: 'lev-est-signup-success',
        component: LevEstSignupSuccessComponent
      }
    ],
  },

  // Century Estate routes
  {
    path: 'estate-picker',
    component: EstatePickerComponent,
    children: [
      {
        path: 'cent-est-select-package',
        component: CentEstSelectPackageComponent,
      },
      {
        path: 'cent-est-install-option',
        component: CentEstInstallOptionComponent,
      },
      {
        path: 'cent-est-device-options',
        component: CentEstDeviceOptionsComponent,
      },
      {
        path: 'cent-est-signup',
        component: CentEstSignupComponent,
      },
      {
        path: 'cent-est-verification',
        component: CentEstVerificationComponent,
      },
      {
        path: 'cent-est-signup-success',
        component: CentEstSignupSuccessComponent,
      }
    ],
  // },
// Business Estate routes
//   {
//     path: 'business',
//     component: BusinessComponent,
//     children: [
//       {
//         path: 'bus-check-coverage',
//         component: BusCheckCoverageComponent,
//       },
//       {
//         path: 'bus-select-package',
//         component: BusSelectPackageComponent,
//       },
//       {
//         path: 'bus-add-services',
//         component: BusAddServicesComponent,
//       },
//       {
//         path: 'bus-services-options',
//         component: BusServicesOptionsComponent,
//       },
//       {
//         path: 'business-signup',
//         component: BusinessSignupComponent,
//       },
//       {
//         path: 'bus-terms-co',
//         component: BusTermsCoComponent,
//       },
//       {
//         path: 'bus-debit-order-mandate',
//         component: BusDebitOrderMandateComponent,
//       },
//       {
//         path: 'bus-verification',
//         component: BusVerificationComponent,
//       },
//       {
//         path: 'bus-signup-success',
//         component: BusSignupSuccessComponent,
//       },
//     ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
