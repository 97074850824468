import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { StepService } from 'src/app/services/step.service';
import { SelectPackageComponent } from '../select-package/select-package.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { DocumentsService } from 'src/app/services/documents-service/documents.service';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit, OnDestroy {
  terms = [
    {
      heading: '',
      paragraph: 'Below are the Terms and Conditions of Use for all agreements between Clear Access, in which they provide goods and/or services to a customer, business or corporate entity. These terms and conditions also incorporate any use of or by an outside party of the http://www.clearaccess.co.za/ website or any localised derivative of that URL’s owned by Clear Access.'
    },
    {heading: 'INTRODUCTION', paragraph: ''},
    {
      heading: '',
      paragraph: 'These terms regulate the (i) agreement between Clear Access and customer when Clear Access supplies goods or services to customer and (ii) use of the website by any user, whether a customer or not.'
    },
    {
      heading: '',
      paragraph: 'By using the goods or services or visiting the website, you agree to be bound by these terms.'
    },

    {heading: 'TERMS & CONDITIONS DEFINITIONS', paragraph: ''},
    {
      heading: '',
      paragraph: 'Unless the context otherwise indicated, the following expressions shall have the meanings given to them hereunder:'
    },
    {heading: '', paragraph: '“Act” means the Electronic Communications Act No. 36 of 2005;'},
    {
      heading: '',
      paragraph: '“agreement” means these general terms and conditions, including all schedules and annexures hereto, together with the terms of any orders and the acceptable use policy which are incorporated herein by reference;'
    },
    {
      heading: '',
      paragraph: '“business day” means Monday to Friday, excluding public holidays published as such in the government gazette;'
    },
    {heading: '', paragraph: '“business hours” means from 08h00 to 17h00 South African time, on business days;'},
    {
      heading: '',
      paragraph: '“charges” means MRC, NRC, usage fees and any other charges payable under this agreement;'
    },
    {
      heading: '',
      paragraph: '“contract term” means the term of the applicable services as set out in the applicable service order or on the website;'
    },
    {
      heading: '',
      paragraph: '“customer” means the person that has entered into an agreement with Clear Access for the provision of goods or services or the user of the website, whichever applies in the circumstances;'
    },
    {heading: '', paragraph: '“effective date” means the date of signature of the agreement;'},
    {
      heading: '',
      paragraph: '“goods” means the any IT goods, including but not limited to hardware and software that Clear Access provides to customer in terms of an order;'
    },
    {
      heading: '',
      paragraph: '“intellectual property” means all intellectual property rights, including but not limited to any domain names; know-how (not in the public domain); invention (whether or not patented); design, trademark, copyright material (whether or not registered), material eligible for copyright in terms of applicable legislation trade or business names, trade secrets or any similar rights to any of the above rights that may subsist in South Africa or elsewhere, whether or not such rights are registered or capable of being registered;'
    },
    {
      heading: '',
      paragraph: '“order” means a goods or services order, which ever applies, that sets out the commercial terms for the provision of goods or services by Clear Access to the customer;'
    },
    {
      heading: '',
      paragraph: '“party” means either Clear Access or the customer and “parties” means both of them collectively and shall be deemed to mean and include their respective successors and permitted assigns’;'
    },
    {
      heading: '',
      paragraph: '“personnel” means any director, employee, agent, consultant, contractor or other representative of a party;'
    },
    {heading: '', paragraph: '“premises” means the premises where Clear Access provides the services;'},
    {
      heading: '',
      paragraph: '“services” means any services that Clear Access provides to the customer in terms of the agreement;'
    },
    {
      heading: '',
      paragraph: '“services commencement date” means the commencement date specified in the order and if no commencement date is specified, the first day on which the service is activated or provided;'
    },
    {
      heading: '',
      paragraph: '“service levels” means the levels according to which Clear Access will provide the service as agreed by the parties in the order or as specified on the website;'
    },
    {
      heading: '',
      paragraph: '“site” means the customer’s site, the Clear Access system or any other system being used by Clear Access for purposes of fulfilling its obligations in terms of the agreement;'
    },
    {
      heading: '',
      paragraph: '“system” means the data centre and equipment including without limitation servers, peripherals, routers, switches, software, databases, cables, generators, and uninterruptible power supplies which are operated together as a system by Clear Access in providing a service;'
    },
    {
      heading: '',
      paragraph: '“third party contractor” means the contractor, supplier, or licensor (which ever applies) of any goods or services, but which is not a party to this agreement;'
    },
    {
      heading: '',
      paragraph: '“website” means www.clearaccess.co.za or any localised URL derivative owned by Clear Access.'
    },

    {heading: 'THE INTERPRETATION', paragraph: ''},
    {
      heading: '',
      paragraph: 'Any reference to days shall be interpreted to be a reference to a calendar day unless qualified as a business day.'
    },
    {
      heading: '',
      paragraph: 'When any number of days is prescribed, the number of days shall be calculated on the basis that the first day is excluded and the last day is included, provided that if the last day is not a business day, the last day shall be the ensuing business day.'
    },
    {
      heading: '',
      paragraph: 'Inclusion of the words “including” or “excluding” followed by examples or items following the term be illustrative only and will not limit its ambit or application'
    },
    {
      heading: '',
      paragraph: 'Terms other than those defined will be given their plain English meaning, and those terms, acronyms, and phrases known in the information and communication technology industry will be interpreted in accordance with their generally accepted meanings.'
    },
    {
      heading: '',
      paragraph: 'Each order shall constitute the subject of a separate agreement between the parties. In the event of any conflict between the terms of these terms, the acceptable use policy and the service order, the agreement will be interpreted in the following order:'
    },
    {heading: '', paragraph: 'The general terms and conditions;'},
    {heading: '', paragraph: 'The acceptable use policy;'},
    {heading: '', paragraph: ' The order.'},

    {heading: 'GOODS AND SERVICES APPOINTMENT', paragraph: ''},
    {
      heading: '',
      paragraph: 'The customer shall use the services of Clear Access, or any goods or services supplied by Clear Access is in accordance with the acceptable use policy of Clear Access and:'
    },
    {heading: '', paragraph: '• Undertakes to not knowingly create, store or disseminate any illegal content.'},
    {
      heading: '',
      paragraph: '• Commits to lawful conduct in the use of the services, including copyright and intellectual property rights.'
    },
    {heading: '', paragraph: '• Undertakes not to send or promote the sending of spam.'},
    {
      heading: '',
      paragraph: 'Clear Access shall monitor its systems for performance and accounting purposes and to ensure compliance with the terms of any agreement between Clear Access and the customer, including the acceptable use policy.'
    },

    {heading: 'COMMENCEMENT AND DURATION', paragraph: ''},
    {
      heading: '',
      paragraph: 'This agreement shall commence on the effective date and shall thereafter remain in force indefinitely, subject to the remaining provisions of this agreement.'
    },
    {
      heading: '',
      paragraph: 'If applicable, the commencement of the services to be provided shall be in terms of each individual order that is completed by the customer and will be effective from the service commencement date for the contract term in the order.'
    },
    {
      heading: '',
      paragraph: 'Notice of termination of the initial agreement must be provided at least one calendar month prior to the expiration of the initial period.'
    },
    {
      heading: '',
      paragraph: 'If such notice is not given, the contract shall carry on indefinitely, subject to each party’s right to terminate the agreement by giving one calendar month’s written notice to the other party.'
    },

    {heading: 'GOODS AND SERVICES APPOINTMENT', paragraph: ''},
    {
      heading: '',
      paragraph: 'The customer appoints Clear Access to provide the services and/or equipment, subject to the terms and conditions contained in this agreement, which appointment Clear Access accepts'
    },

    {heading: 'PERMISSION TO ACCESS', paragraph: ''},
    {
      heading: '',
      paragraph: 'The customer expressly gives its permission and authorises Clear Access to remotely access its data, network, system, software, hardware or do whatever is required to fulfil its obligations and render the services in terms of the agreement.'
    },

    {heading: 'PERMISSIONS IN TERMS OF THE NCA', paragraph: ''},
    {
      heading: '',
      paragraph: 'The Customer expressly permits Clear Access to conduct a credit referencing or risk assessment process. This may entail the use of information provided by the customer to obtain information about the customer and the customer’s credit information from registered credit bureaus.'
    },
    {
      heading: '',
      paragraph: 'Consequently, Clear Access shall be entitled to request information related to the customer’s creditworthiness and the parties agree that Clear Access is authorised to conduct all reasonable credit checks and searches in accordance with the NCA.'
    },
    {
      heading: '',
      paragraph: 'The customer consents to Clear Access sharing their information and report on their payment behaviour with registered credit bureaus. This includes, listing a customer’s failure to make payments in terms of any agreement between the customer and Clear Access.'
    },
    {
      heading: '',
      paragraph: 'The customer shall be liable for any costs incurred by Clear Access in listing the customer with a credit bureau or legal action taken by Clear Access to recover costs from a customer.'
    },

    {heading: 'PROVISION OF SERVICES', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access agrees that it will from the effective date, and thereafter continue throughout the duration of this agreement, to supply the services as requested by the customer in accordance with the order.'
    },
    {
      heading: '',
      paragraph: 'For the duration of this agreement, the customer shall obtain the services as it requires from Clear Access in terms of this agreement, the services schedule and the order concluded from time to time.'
    },
    {
      heading: '',
      paragraph: 'Where applicable, Clear Access shall not be under any obligation to supply any services to the customer until an order in respect of the required services has been agreed by means of signing the order - clicking an acceptance button, sending of a confirmatory document, including an email or by signing or indicating acceptance by means recognised by the ECTA.'
    },
    {
      heading: '',
      paragraph: 'Each order shall constitute an agreement between the parties subject to and regulated by this agreement.'
    },
    {
      heading: '',
      paragraph: 'In order to provide the services, Clear Access reserves the right to utilize any technology available and which it considers at its discretion as the most suitable to provide services.'
    },
    {
      heading: '',
      paragraph: 'The services shall be used by the customer in accordance with the order, agreement and acceptable use policy.'
    },

    {heading: 'SERVICE ORDER', paragraph: ''},
    {
      heading: '',
      paragraph: 'In the event that these terms or conditions are in conflict with the terms of the order, the service order shall take preference.'
    },
    {
      heading: '',
      paragraph: 'Service orders may, depending on the service in question, be placed online or via a request for a quote from Clear Access.'
    },
    {
      heading: '',
      paragraph: 'If the particular service requires Clear Access to provide a quote to the customer first, the customer must confirm acceptance of the quote either in writing to sales@clearaccess.co.za, or by ticking the confirmation button online, whichever one applies. The customer must place such an order in the prescribed manner stipulated on the quote.'
    },
    {
      heading: '',
      paragraph: 'Any order placed by a customer, whether online or otherwise, is an offer to Clear Access to enter into the agreement with the customer.'
    },

    {heading: 'OWNERSHIP AND RISK', paragraph: ''},
    {
      heading: '',
      paragraph: 'Risk in any goods provided by Clear Access will pass to the customer on delivery to the customer.'
    },
    {
      heading: '',
      paragraph: 'Unless expressly specified and agreed otherwise, ownership of any goods, modem, hardware or equipment supplied by Clear Access to the Customer shall remain with Clear Access.'
    },
    {
      heading: '',
      paragraph: 'The customer will bear the risk in all goods supplied by Clear Access and shall be responsible to ensure that any such goods, equipment or hardware are adequately insured at all times.'
    },
    {
      heading: '',
      paragraph: 'The customer shall use any goods, equipment and hardware are used only for the purpose it is provided for and that the goods, equipment and hardware remain in the same condition as when it was first supplied to the Customer by Clear Access.'
    },
    {
      heading: '',
      paragraph: 'Where goods are purchased by the customer, ownership in the goods will only pass once the full payment for the goods have been received.'
    },
    {
      heading: '',
      paragraph: 'If a modem or other goods or equipment is supplied to the customer by Clear Access, such goods or equipment will at all times remain the property of Clear Access.'
    },
    {
      heading: '',
      paragraph: 'At the expiry or termination of the agreement between Clear Access and the customer, all goods, equipment or hardware must be returned to Clear Access within 7 (seven) calendar days from date of termination and in the same - All such items must be returned in its original packaging and in the same condition as when it was provided to the Customer. Should the Customer fail to return the goods, equipment or hardware in its original packaging and in the same condition as when provided to the Customer, the Customer shall be liable for payment of an amount of R1000.00 (one thousand rand) to Clear Access. Such payment must be made within 7 days from date of cancellation or expiration of the contract/order.'
    },

    {heading: 'DELIVERY AND INSTALLATION', paragraph: ''},
    {
      heading: '',
      paragraph: 'Any delivery date provided to a customer is an estimate date only and Clear Access shall make all reasonable efforts to deliver on the estimated date.'
    },
    {
      heading: '',
      paragraph: 'If Clear Access is not able to deliver on the estimated date, Clear Access will take all reasonable measures to inform the customer timeously of the delay in delivery.'
    },
    {
      heading: '',
      paragraph: 'Should the customer assist with the installation of any goods or services, including software, Clear Access will not be liable for any damage caused or losses suffered as a result of the customer’s assistance with the installation.'
    },
    {
      heading: '',
      paragraph: 'Should Clear Access charge the customer any delivery charges, Clear Access will quote for this upfront and all deliveries will be payable in full as specified on the quote, before delivery of the goods.'
    },
    {
      heading: '',
      paragraph: 'Clear Access provides free installation to the value of R2000.00 (two thousand rand) and free activation to the value of R900.00 (nine hundred rand). Should the Customer cancel the service order/agreement prior to 12 (twelve) months from activation of the service, the Customer shall be liable for an early cancellation charge of R2000.00 (two thousand rand).'
    },
    {
      heading: '',
      paragraph: 'The products offered for residential use are not suitable for business use and Clear Access shall not be liable for any loss or damage arising from the use of residential use products for any other purpose.'
    },
    {
      heading: '',
      paragraph: 'Modems provided for residential use are for use by a single household and equipment or services may not be shared with parties outside of the household.'
    },
    {
      heading: '',
      paragraph: 'Services are for residential use only and use by the household of the party who entered into the agreement/placed the service order with Clear Access.'
    },
    {
      heading: '',
      paragraph: 'The Crazy 8 service package does not include the installation and router costs free of charge. The client shall purchase a router from Clear Access and /or shall be charged an installation fee for Clear Access to install the router at the client’s premises.'
    },
    {
      heading: '',
      paragraph: 'In the event that a client owns a router and would like to utilise the router that router is subject to evaluation and confirmation of compatibility with the Clear Access network. In the event that a client owns a router that is compatible to the Clear Access network, the client will be charged an installation fee. If a router is not compatible, the client may purchase a router from Clear Access.'
    },
    {
      heading: '',
      paragraph: 'Clear Access does not accept responsibility for the third party router and will not be held liable for the functioning of the third party router.'
    },

    {heading: 'FEES AND PAYMENT', paragraph: ''},
    {
      heading: '',
      paragraph: 'The customer shall be liable for and shall pay the fees in respect of the goods or services as set out in this clause or in the applicable service order(s).'
    },
    {
      heading: '',
      paragraph: 'Fees are payable in South African Rand without deduction or set-off for any reason and each order will constitute a separate agreement between the parties.'
    },
    {
      heading: '',
      paragraph: 'Unless otherwise agreed in the service order, all monthly fees payable for services shall be invoiced by Clear Access monthly in advance and are due on or before the 1st day (first-day) of each month. The customer agrees to Clear Access rendering electronic invoices.'
    },
    {
      heading: '',
      paragraph: 'Clear Access may invoice pro-rata portions of monthly fees for services where an agreement commences on a day other than the first of the month.'
    },
    {
      heading: '',
      paragraph: 'The customer shall be required to authorise a debit order deduction from their designated bank account and undertakes to take any steps required to facilitate the establishment of such debit order. Clear Access may in their own discretion decide not to provide any goods or services in the absence of a valid debit order authorisation being in place.'
    },
    {
      heading: '',
      paragraph: 'By special arrangement and at the discretion of Clear Access, the customer may arrange to pay via electronic funds transfer. Debit orders shall be processed on the first day of each month. Should the first day of the month fall on a Saturday, Sunday or Public Holiday, the debit order shall be processed on the last business day prior thereto or as otherwise communicated by Clear Access from time to time.'
    },
    {
      heading: '',
      paragraph: 'In the event that an agreement exists between Clear Access and the customer, that the customer may make payment via electronic funds transfer, and the customer on two or more occasions during the contract period, fails to make payment within 3-days (three-days) of the payment due date, Clear Access shall be entitled to require the customer to sign a debit order authorisation. Should the customer refuse to sign a debit order authorisation, Clear Access will be entitled to cancel the agreement without any prejudice to any other rights in law or contractually.'
    },
    {
      heading: '',
      paragraph: 'Time and material-based services rendered, will be invoiced for and is payable before commencement/delivery.'
    },
    {
      heading: '',
      paragraph: 'The customer may not withhold payment of any amount due to Clear Access for any reason, including an alleged breach of contract by Clear Access.'
    },
    {
      heading: '',
      paragraph: 'Should any amount invoiced be disputed, the customer shall not be entitled to withhold any such amount pending resolution of the dispute and any dispute will be dealt with in terms of the provisions of this agreement.'
    },
    {
      heading: '',
      paragraph: 'Fees and charges not settled in terms of the agreement will bear interest at 15% (fifteen percent), such interest to be calculated from the due date of payment to the date of actual payment, both days inclusive, calculated daily and compounded monthly in arrears.'
    },
    {
      heading: '',
      paragraph: 'The customer shall reimburse all reasonable expenses authorized by customer and as are properly incurred by Clear Access and Clear Access’s personnel in fulfilling Clear Access’s obligations in terms of the agreement, including but not limited to goods and services purchased on the customer’s behalf, communications, stationery, report and presentation material, travelling and subsistence expenses as invoiced. Customer will not withhold authorization unreasonably.'
    },
    {
      heading: '',
      paragraph: 'Should the Customer fail to make payment in accordance with any order/these terms, Clear Access shall have the right to immediately suspend all services to the Customer.'
    },
    {
      heading: '',
      paragraph: 'The Customers shall be liable for payment of a reconnection fee of R250.00 (two hundred and fifty rand), to reconnect services that have been suspended due to non-payment.'
    },
    {
      heading: '',
      paragraph: 'The Customer will be held liable for any surcharges charged by the FNO on selected services for an upgrade or downgrade if applicable.'
    },

    {heading: 'CHARGES', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access shall be entitled to adjust the monthly fees and charges in the event of any regulatory, or government imposed factors which impact on such fees and charges.'
    },
    {
      heading: '',
      paragraph: 'Clear Access shall be entitled to review the monthly fees and charges for voice services, and other Telco voice carrier class services from time to time and will provide the customer with thirty-day (30) days written notice to effect any fee adjustments as may be applicable.'
    },
    {
      heading: '',
      paragraph: 'Clear Access may, from time to time, adjust package offerings and prices to conform to market trends. Customers shall be given 30-days (thirty-days) written notice of any such amendments.'
    },

    {heading: 'PROMOTIONAL CODES', paragraph: ''},
    {heading: '', paragraph: 'Promotional codes used from time to time – each will have own terms and conditions.'},

    {heading: 'SECURITY', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access will provide adequate protection for all personal information held and take reasonable steps to stop unauthorised access and use of personal information. Clear Access will, on an on-going basis, continue to review their security controls and related processes to ensure that our clients’ personal information remains secure. The customer shall give reasonable cooperation in any investigation that may be carried out by Clear Access relating to a security violation.'
    },
    {
      heading: '',
      paragraph: 'The customer must follow all Clear Access’s security instructions, including but not limited to advise Clear Access in writing of any security violation or imminent violation and indemnifies Clear Access against any loss, harm or damage suffered by Clear Access, including third party claims, arising out of any breach of security caused by the acts or omissions of the customer or its personnel.'
    },
    {
      heading: '',
      paragraph: 'In the event of a security violation, or if Clear Access, in its sole discretion, determines that a security violation is imminent, Clear Access may take whatever steps it deems necessary to protect its system and/or the premises, including without limitation:'
    },
    {heading: '', paragraph: '• changing the customers’ access codes and passwords;'},
    {heading: '', paragraph: '• temporarily preventing access to the customer’s account or system;'},
    {heading: '', paragraph: '• preventing access to the system and/or premises;'},
    {heading: '', paragraph: '• relocating the customer’s website;'},
    {
      heading: '',
      paragraph: 'The customer will cooperate in any investigation that may be carried out by Clear Access relating to a security violation or where a court order requires information to be provided.'
    },
    {
      heading: '',
      paragraph: 'It is the customer’s responsibility to ensure that it keeps all information relating to its account secure. Clear Access will respond to all customer activity until such time as a customer has informed Clear Access of a security violation and Clear Access will not be liable for any damages caused to the customer in this regard.'
    },

    {heading: 'SUSPENSION OF SERVICES', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access will be entitled to suspend the service with immediate effect and without notice if:'
    },
    {
      heading: '',
      paragraph: '• the customer fails to make payment/a debit order is unsuccessful on the agreed date as per the order or these terms;'
    },
    {
      heading: '',
      paragraph: '• there is a security violation or imminent security violation of the service, system or website;'
    },
    {
      heading: '',
      paragraph: '• emergency maintenance, as determined by Clear Access in its sole discretion is required;'
    },
    {heading: '', paragraph: '• it is necessary to comply with the law;'},
    {
      heading: '',
      paragraph: '• it receives a take-down notification from ISPA, and the alleged conduct is in contravention. The customer contravenes the terms of the Clear Access Acceptable Use policy.'
    },
    {
      heading: '',
      paragraph: 'Clear Access will be entitled to suspend the service and will endeavour to provide reasonable notice if routinemaintenance, repair or the like is required.'
    },

    {heading: 'WARRANTIES', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access does not give any warranty or guarantee of any nature whatsoever, whether express or implied, in respect of any goods or services provided in terms of this or any related agreements entered into with the customer.'
    },
    {heading: '', paragraph: 'Cheyenne'},

    {heading: 'INDEMNITY', paragraph: ''},
    {
      heading: '',
      paragraph: 'Subject to “Exclusion and limitation of liability” clause Clear Access will not be liable for any loss or damage whatsoever and the customer indemnifies Clear Access against such loss or damage that the customer may suffer as a result of the customer’s use of the services, fault, down-time, or outages of the goods or services or viruses, or security violations, howsoever arising and whether arising out of any problems with the services attributable to Clear Access or any electronic communications network services or another third party contractor of electronic communications services, loss of income, or for any other reason, the customer further indemnifies Clear Access against any claim resulting from:'
    },
    {heading: '', paragraph: '• the use of the customer’s data by a third party;'},
    {heading: '', paragraph: '• the loss of the customer’s data;'},
    {heading: '', paragraph: '• any non-compliance or breach with the agreement.'},
    {
      heading: '',
      paragraph: 'Notwithstanding the above, nothing in this agreement and/or any order, service order or acceptable use policy shall exclude or in any way limit Clear Access’s liability for gross negligence or wilful misconduct.'
    },

    {heading: 'EXCLUSION AND LIMITATION OF LIABILITY', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access is indemnified from all liability howsoever caused as a result of any use of third-party contractor’s goods or services.'
    },
    {
      heading: '',
      paragraph: 'Clear Access shall not be liable for any loss or damage to the property or equipment of the customer arising out of the provision, installation or maintenance of any services or goods to the customer.'
    },
    {
      heading: '',
      paragraph: 'Clear Access shall under no circumstances be liable for any special, indirect, consequential, economic or like damages which may arise pursuant to this agreement (or any act or omission arising during the course and scope of fulfilling its obligations in terms of this agreement), including, without limitation, any damages arising due to any loss of profits or loss of business.'
    },
    {
      heading: '',
      paragraph: 'Clear Access will only be liable for direct damages. The maximum total liability for direct damages as a result of breach of this agreement by Clear Access, will in all circumstances be:'
    },
    {
      heading: '',
      paragraph: '• the monthly service fee (if any) paid by the customer for the last 2 months prior to cancellation of the agreement;'
    },
    {
      heading: '',
      paragraph: '• the monthly service fee shall be determined with reference to the relevant service order.'
    },

    {heading: 'CONFIDENTIALITY', paragraph: ''},
    {
      heading: '',
      paragraph: 'The parties agree to treat all confidential information of the other party that is normally regarded as confidential information or not ordinarily available to the public as confidential for the period of the agreement as well as after termination of the agreement.'
    },

    {heading: 'TERMINATION AND BREACH', paragraph: ''},
    {
      heading: '',
      paragraph: 'After expiry of the initial term of the order, either party may terminate this agreement by giving the other party one calendar months’ notice in writing.'
    },
    {
      heading: '',
      paragraph: 'If the customer commits a material breach of the agreement, Clear Access may without any prejudice to any other rights afforded in terms of the law:'
    },
    {heading: '', paragraph: '• terminate the agreement and/or;'
    },

    {heading: '', paragraph: '• suspend the customer’s access or use of the service with immediate effect;'
    },
    {
      heading: '',
      paragraph: '• claim immediate payment of all outstanding fees and charges and future fees and charges due in terms of the agreement which fees and charged will become due and payable on demand.'
    },
    {
      heading: '',
      paragraph: 'Subject to the “Termination and breach” clause, should either party fail to comply with any of its obligations or commit a breach of the agreement and fail to remedy such default or breach within 14-days (fourteen-days) after having received a written notice to do so, or be placed in provisional or final sequestration, placed under debt review or judicial management, or enter into any compromise or scheme of arrangement with its creditors, or fail to satisfy a judgment taken against it within 10-days (ten-days), the other party shall be entitled to terminate this agreement on written notice to the defaulting party.'
    },
    {
      heading: '',
      paragraph: 'The Customer shall be liable for any administration or legal costs arising from the termination of the agreement due to breach.'
    },

    {heading: 'TERMINATION ASSISTANCE', paragraph: ''},
    {
      heading: '',
      paragraph: 'Subject to no outstanding payments being due to Clear Access, Clear Access will upon termination of the agreement render such reasonable assistance to the customer so as to enable the customer to migrate any services affected by such termination to a service provider of the customer’s choice.'
    },

    {heading: 'FOURCE MAJEURE', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access shall not be liable for any delay in performing or any failure to perform any obligations under this agreement due to any cause beyond their reasonable control, including but without being limited to any of the following: strikes, lock outs or other industrial action, sabotage, terrorism, civil commotion, riot ,invasion, war, threat of or preparation for war, fire, explosion, storm, flood, subsidence, epidemic or other natural physical disaster, impossibility of the use of railways, shipping aircraft, motor transport or other means of public or private transport; any act or policy of any state or government or other authority having jurisdiction over either party, sanctions, boycott or embargo, failure of any supplier of electricity, including ESKOM, telecommunication infrastructure or services, including TELKOM or any similar circumstances beyond the reasonable control of Clear Access.'
    },
    {
      heading: '',
      paragraph: 'Upon the occurrence of any delay or failure referred to in this clause the provisions of this agreement affected shall be suspended for as long as the cause in question continues to operate, provided that if that cause has not ceased to operate within 2-months (two-months) from when it first arose, this agreement may be terminated by either party on written notice to the other'
    },

    {heading: 'EXCLUSION AND LIMITATION OF LIABILITY', paragraph: ''},
    {heading: '', paragraph: 'By entering into an agreement with Clear Access, the customer acknowledges that:'},
    {
      heading: '',
      paragraph: '• The customer has read and understands Clear Access’ Privacy Policy which can be found on Clear Access’ website;'
    },
    {
      heading: '',
      paragraph: '• Clear Access may collect, use and process the customer’s personal information and/or data in accordance with POPIA for purposes listed in Clear Access’ Privacy Policy;'
    },
    {
      heading: '',
      paragraph: '• Clear Access may disclose personal information and/or data in the instances listed in Clear Access’ Privacy Policy;'
    },
    {
      heading: '',
      paragraph: '• Clear Access my retain personal information in accordance with the provisions of POPIA.'
    },

    {heading: 'DISPUTE RESOLUTION', paragraph: ''},
    {
      heading: '',
      paragraph: 'In the event that a dispute arises in respect of this agreement, including a dispute related to any invoice, the Customer may direct a complaint to accounts@clearaccess.co.za.'
    },
    {
      heading: '',
      paragraph: 'Clear Access shall attend to and respond to such complaint within 14 (fourteen) business days'
    },

    {heading: 'GENERAL', paragraph: ''},
    {
      heading: '',
      paragraph: 'Nothing in this agreement will constitute any relationship of employment, subcontract or partnership and neither party will be able to bind the other party contractually.'
    },
    {
      heading: '',
      paragraph: 'The parties choose as domicilium citandi et executandi the address stipulated in the order.'
    },
    {
      heading: '',
      paragraph: 'This agreement will be regulated and interpreted in accordance with the laws of South Africa.'
    },
    {
      heading: '',
      paragraph: 'The Customer may only assign its rights and obligations under this agreement to a third party with the written consent of Clear Access.'
    },
    {
      heading: '',
      paragraph: 'Clear Access reserves the right to sell its customer database to a third party at any time and reserves the right to cede its rights and obligations to any third party without the written consent of the customer.'
    },
    {
      heading: '',
      paragraph: 'This agreement constitutes the entire agreement between the parties and no variation will be of any effect unless agreed to in writing and signed by both parties.'
    },
    {
      heading: '',
      paragraph: 'The parties’ consent to the jurisdiction of the North Gauteng High Court of South Africa for purposes of any ligation flowing from this agreement.'
    },

    {heading: 'WEBSITE USE', paragraph: ''},
    {heading: 'PRIVACY AND MONITORING', paragraph: ''},
    {heading: '', paragraph: 'Clear Access recognises the right to privacy.'},
    {
      heading: '',
      paragraph: 'The customer agrees that Clear Access will have no duty to monitor the data transmitted through the system, but may monitor the system and site for operational purposes and to comply with applicable legislation, including but not limited to:'
    },
    {
      heading: '',
      paragraph: 'the Regulation of Interception of Communications and Provision of Communication-related Information Act (Act 70 of 2003);'
    },
    {heading: '', paragraph: 'the Film and Publications Act (Act 65 of 1996);'},
    {heading: '', paragraph: 'The Electronic Communications and Transactions Act (Act 25 of 2002).'},
    {
      heading: '',
      paragraph: 'Clear Access is committed to conduct business in compliance with the applicable legislation.'
    },
    {
      heading: '',
      paragraph: 'If Clear Access in its sole discretion determines any customer data or content available on the system to be in violation of the Acceptable use policy, or if Clear Access receives a take-down notice from ISPA, Clear Access may terminate or suspend the service in accordance with the "Suspension of services" clause'
    },

    {heading: 'PROFILE INFORMATION COLLECTED FROM YOU', paragraph: ''},
    {
      heading: '',
      paragraph: 'When you register for the Clear Access service we collect your company name, your name and both sets of contact details.'
    },
    {
      heading: '',
      paragraph: 'Further information may be collected from you throughout the course of the relationship with us.'
    },
    {
      heading: '',
      paragraph: 'This information is used by us to support your interaction with our site and to ensure that only the authorised user is instructing us via the web portal.'
    },
    {
      heading: '',
      paragraph: 'Your e-mail address or mobile phone number may be used to send you communication regarding the progress of your matter, to notify you of any changes or updates to the Clear Access service, and to send you relevant news and data from time to time. We will not share your e-mail address with any third parties.'
    },

    {heading: 'TERMS OF USE OF WEBSITE', paragraph: ''},
    {
      heading: '',
      paragraph: 'By using, accessing or viewing this website you agree to these terms and conditions, including any document that may be incorporated by reference thereto.'
    },
    {
      heading: '',
      paragraph: 'You may only use this website and its content in order to view, refer to, utilise or print information for lawful purposes.'
    },
    {
      heading: '',
      paragraph: 'The use of this website does not extend to its’ source code or to the source code of any software or computer program that forms part of the website.'
    },
    {
      heading: '',
      paragraph: 'You are not allowed to republish any information obtained on this website or distribute it in any manner.'
    },
    {heading: '', paragraph: 'Use of this website is strictly at your sole risk.'},
    {
      heading: '',
      paragraph: 'We may, in our sole discretion, at any time and for any reason and without prior written notice, suspend or terminate the operation of this website or withdraw any of the content; or your right to use this website.'
    },
    {
      heading: '',
      paragraph: 'We reserve the right to make amendments to the content and information on this website, including, but not limited to, amendments to pricing and rates. You acknowledge that each time you use the website it is your duty to familiarise yourself with any amendments that may have been made since you last used the website.'
    },
    {heading: '', paragraph: 'Automated transactions and searches are subject to these terms and conditions.'},
    {heading: '', paragraph: 'The use of malicious search technology is prohibited.'},
    {
      heading: '',
      paragraph: 'The use of search technology in an unlawful manner or for the collecting or harvesting of data for commercial gain is prohibited.'
    },

    {heading: 'LIMITATION OF LIABILITY', paragraph: ''},
    {
      heading: '',
      paragraph: 'Clear Access will not be liable for any unavailability, interruption, downtime, malfunction, or failure of this website or the disappearance of content from the website for any reason whatsoever.'
    },
    {
      heading: '',
      paragraph: 'Clear Access will not be liable for any viruses, destructive materials or any other data or code (‘harmful material’) which is able to harm or otherwise impede in any manner the operation of a computer system, network, handset or mobile device and the user accepts the risk associated with the existence of such harmful material.'
    },
    {
      heading: '',
      paragraph: 'Clear Access strictly agrees to adhere to the guidelines relating to consumer protection, as specified in Electronic Communications and Transactions Act 25 of 2002, the Consumer Protection Act 68 of 2008, and other relevant pieces of legislation.'
    },

    {heading: 'COPYRIGHT AND INTELLECTUAL PROPERTY RIGHTS', paragraph: ''},
    {
      heading: '',
      paragraph: 'Copyright in all information, images, icons, source codes and other original material contained in this website which is not attributed to a third party, is held by or licensed to Clear Access.'
    },
    {
      heading: '',
      paragraph: 'All intellectual property rights (whether existing before or after the agreement commences) that each party owns remains the sole and exclusive property of that party.'
    },

    {heading: 'EXCLUSION OF REPRESENTATIONS', paragraph: ''},
    {heading: '', paragraph: 'We reserve the right to change the website and content without notice.'},
    {heading: '', paragraph: 'This website is provided without any representation or warranty whatsoever.'},
    {heading: '', paragraph: 'We do not accept any responsibility for any errors or omissions on this website.'},

    {heading: 'WARRANTIES', paragraph: ''},
    {heading: '', paragraph: 'You warrant that all information that you provide to us is:'},
    {heading: '', paragraph: 'correct, accurate and as recent as possible; and'},
    {heading: '', paragraph: 'obtained via means, not in contravention of any law or legislation.'},
    {
      heading: '',
      paragraph: 'You warrant to us that the authorised representative, as identified in the application form, has the necessary authority to instruct us and bind you, to proceed with all and any instructions made by said authorised representative to us.'
    },

    {heading: 'RESTRICTIONS ON USE', paragraph: ''},
    {heading: '', paragraph: 'You may not use this website to obtain or distribute:'},
    {
      heading: '',
      paragraph: 'copyrighted material or material protected by laws relating to intellectual property rights without our permission;'
    },
    {
      heading: '',
      paragraph: 'harmful materials which are able to corrupt, interfere with, jeopardise, disrupt, disable, harm or otherwise impede in any manner the operation of a computer system or hardware or software;'
    },
    {heading: '', paragraph: 'material which is defamatory, unlawful or contains hate speech in our discretion; or'},
    {heading: '', paragraph: 'bulk e-mail, whether solicited or unsolicited.'},
    {
      heading: '',
      paragraph: 'You must not interfere or attempt to interfere with or jeopardise the functionality or the operation of any part of this website or any part thereof.'
    },
    {
      heading: '',
      paragraph: 'You shall not use this website for ‘spoofing’, ‘hacking’, ‘flaming’, ‘cracking’, ‘phishing’ or ‘spamming’ or any other activity designed or aimed at achieving similar purposes.'
    },
    {heading: '', paragraph: 'You may not intercept any information transmitted to or from us.'},

    {heading: 'COOKIES', paragraph: ''},
    {
      heading: '',
      paragraph: 'From time to time we may place cookies on your hard drive; a cookie is a small piece of information stored by your browser, typically used to identify returning visitors, and to collect aggregate information on the number of visitors to a site and the number of pages viewed.'
    },
    {
      heading: '',
      paragraph: 'When you register and log in we will place a unique identifier cookie on your hard drive; this cookie will be used to maintain logged-in status for the duration of your visit, and to log you in automatically on subsequent visits.'
    },
    {
      heading: '',
      paragraph: 'You can choose to prevent the site from storing cookies on your hard drive by disabling cookies in your browser options, but this will mean that you will have to log in manually each time you visit the site.'
    },
    {
      heading: '',
      paragraph: 'We may also use cookies to track the pages you visit so that we can deliver content based on your profile and past activity.'
    },
    {
      heading: '',
      paragraph: 'Cookie information is used only by us and our agents, and is not provided to any other organisation.'
    },

    {heading: 'OTHER DATA', paragraph: ''},
    {
      heading: '',
      paragraph: 'From time to time we may collect information such as click data and page-view statistics, the name of the Internet service provider and the IP address through which you access our site, the date and time you access the site, the pages that you access while at the site, and the Internet address of the website from which you navigated to our site.'
    },
    {
      heading: '',
      paragraph: 'This information is not personally identifiable and is used in aggregate for website and system administration, traffic and trend analysis and business decision-making.'
    },

    {heading: 'GENERAL TERMS', paragraph: ''},
    {
      heading: '',
      paragraph: 'The terms and conditions contained herein shall be deemed to be exclusive and applicable to each and every contract entered into between us. Any variation hereof shall not be binding unless reduced to writing and signed by Clear Access.'
    },
    {
      heading: '',
      paragraph: 'The address stated on the invoice will be that supplied to us by you and shall be deemed to be your chosen domicilium citandi et executandi, and any letter, notice or process shall be validly served if sent to such domicilium.'
    },

  ];


  loading: boolean = false;
  public smallScreen: boolean = false;
  public breakpointSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<SelectPackageComponent>,
    private stepService: StepService,
    private snackBarService: SnackBarService,
    private documentsService: DocumentsService,
    private breakpointService: BreakpointService
  ) {

    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

    this.stepService
      .setStep('Terms & Conditions');
  }
  ngOnInit(): void {
    // (document.getElementsByTagName('mat-dialog-container')[0] as HTMLElement).scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }

  acceptTermsAndConditions(): void {
    // this.loading = true;
    // const body = document.getElementById('terms-and-conditions');
    // const name = `clear-access-terms-and-conditions-${new Date().toLocaleDateString()}`;
    // body.style.height = '100%';
    // html2canvas(body).then(canvas => {
    //   // Few necessary setting options
    //   // const imgWidth = 215;
    //   // const pageHeight = 800;
    //   // const imgHeight = canvas.height * imgWidth / canvas.width;
    //   // const heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/jpeg');
    //   const pdf = new jsPDF(); // A4 size page of PDF
    //   // 'p', 'mm', 'a4'
    //   // let pageHeight = pdf.internal.pageSize.height;
    //   // console.log(contentDataURL);
    //   const cutoffHeight = 9000;
    //   const loops = Math.floor(54000/cutoffHeight);
    //   pdf.addImage(contentDataURL, 'JPEG', 2, 2, 210, 9000);
    //   console.log('amount of loops', loops);
    //   // for (let i = 0; i < loops; ++i) {
    //   //   pdf.addPage();
    //   //   console.log('current height', 9000*(i+1));
    //   //   pdf.addImage(contentDataURL, 'JPEG', 2, 2, 210, 9000);
    //   // }

    //   // pdf.html(body, {
    //   //   width: 210,
    //   //   x: 5,
    //   //   y: 5,
    //   //   autoPaging: true,
    //   //   filename: name,

    //   // });

    //   const file = pdf.save(`${name}.pdf`); // Generated PDF
    //   // pdf.addImage(contentDataURL, 'JPEG', 1, 1, 300, 500);
    //   const blob = file.output('blob');
    //   const formData = new FormData();
    //   formData.append('pdf', blob);
    //   const blobPart: BlobPart[] = [blob];
    //   const termsAndConditionsFile = new File(blobPart, 'termsAndConditionsDocument');
    //   this.documentsService.setTermsDocument(termsAndConditionsFile);
    //   // <input (change)="onFileChange($event)" name="proofOfAddressDocument" id="file-upload-address" class="bankingform__fileinput-input" type="file" />
    //   // const fileInputElement = new HTMLInputElement();
    //   // fileInputElement.name = 'termsAndConditionsDocument';
    //   // fileInputElement.id = 'file-upload-terms-and-conditions';
    //   // fileInputElement.type = 'file';
    //   // fileInputElement.onchange = () => {

    //   // }
    //   // send file to splynx
    //   // this.snackBarService.showSnackBar(
    //   //   `${name}.pdf`,
    //   //   'File Successfully downloaded'
    //   // );
    // });

    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.documentsService.setTermsDocument(null);
    this.dialogRef.close();
  }
}