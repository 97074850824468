import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  public smallScreen: Subject<boolean> = new Subject<boolean>();
  public isSmallScreen: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.smallScreen.next(true);
        this.isSmallScreen = true;
      } else {
        this.smallScreen.next(false);
        this.isSmallScreen = false;
      }
    });
  }
}
