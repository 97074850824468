
<div class="container">
  <div class="spacer"></div>
  <div  fxLayoutAlign="center center" fxLayout.gt-md="row" fxLayout.lt-md="column">
    <h2 fxLayout.lt-md="column">Choose an installation option to best suit your needs:</h2>
  </div>
  <section fxLayoutAlign="center center" fxLayout.lt-md="column" fxLayoutGap="20px">
    <div *ngFor="let install of estateInstallOption;" class="grid-container" fxLayoutAlign="center center"  >
      <div fxLayoutGap="50px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-sm="row" fxLayout.lt-md="column">

        <div class="card-container">
          <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">
            <p>{{install.description}}</p>
            <h2 class="price">{{install.cost}}</h2>
            <mat-card-subtitle>{{install.term}}</mat-card-subtitle>
            <button mat-button (click)="onClicked(install)" >SELECT</button>
          </mat-card>
        </div>
      </div>
    </div>
  </section>
</div>
<div class="spacer"></div>



<!--<div class="container">-->
<!--  <div class="spacer"></div>-->
<!--  <div  fxLayoutAlign="center center" fxLayout.gt-md="row" fxLayout.lt-md="column">-->
<!--    <h2 fxLayout.lt-md="column">Choose an installation option to best suit your needs:</h2>-->
<!--  </div>-->
<!--  <section fxLayoutAlign="center center" >-->
<!--    <div class="grid-container" fxLayoutAlign="center center" >-->
<!--      <div fxLayoutGap="50px" fxLayoutAlign="center center" fxLayoutAlign="space-between" fxLayout.gt-sm="row" fxLayout.lt-md="column">-->

<!--        <div class="card-container">-->
<!--          <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">-->
<!--            <p>Router installation and set up, once off - if you purchase a router from Clear Access</p>-->
<!--            <h2 class="price">R250</h2>-->
<!--            <mat-card-subtitle>Month to month</mat-card-subtitle>-->
<!--            <button mat-button (click)="goNext()">SELECT</button>-->
<!--          </mat-card>-->
<!--        </div>-->

<!--        <div>-->
<!--          <mat-card fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">-->
<!--            <p>Router installation and set up, once off - if you have your own router.</p>-->
<!--            <h2 class="price">R450</h2>-->
<!--            <mat-card-subtitle>Month to month</mat-card-subtitle>-->
<!--            <button mat-button (click)="skipDeviceOption()">SELECT</button>-->
<!--          </mat-card>-->
<!--        </div>-->


<!--        <div>-->
<!--          <mat-card  fxLayout="column"  fxLayoutAlign="space-between" fxLayoutAlign="center center">-->
<!--            <p class="last-description">I have my own router. Router installation and set up not required. Please send me my PPOE details.</p>-->
<!--            <h2 class="price last-price">R0</h2>-->
<!--            <mat-card-subtitle class="last-sub">Month to month</mat-card-subtitle>-->
<!--            <button  class="last-btn" mat-button  (click)="skipDeviceOption()">SELECT</button>-->
<!--          </mat-card>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--</div>-->
<!--<div class="spacer"></div>-->


