<section class="container" fxLayoutAlign="center center">
  <div class="second-container">
    <div fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout="column">
      <h1 class="verify-header">Verification</h1>
    </div>
    <div fxLayoutGap="12px" fxLayoutAlign="center center"   fxLayout.gt-sm="column">
      <p>We are almost done. For security reasons and to ensure that your details are correct you will need to verify your contact information. An SMS  has been sent to you. Please enter the verification code below.</p>
    </div>

    <div fxLayoutAlign="center center">
      <div class="sms-resend-container" fxLayoutAlign="center center" >
        <div fxLayout.gt-sm="row"  >
          <div>
            <h2>SMS Verification Code</h2>
          </div>
        </div>
        <div>
          <div>
            <a class="resend"  (click)="resendCode()" style="color:#3380F5;">Resend code</a>
          </div>
        </div>
      </div>
    </div>

    <form #smsOTPForm="ngForm" (ngSubmit)="getOTP(smsOTPForm.value)">
      <div class="pin-code-container"  fxLayoutGap="10px" fxLayoutAlign="center center"  fxLayout.gt-sm="row" >
        <ng-container>
          <mat-card class="card-code-holder" >
            <input matInput ngModel name="codeOne" type="number"  placeholder=""    #input1 maxlength="1"   [appAutoTab]="input2">
          </mat-card>

          <mat-card class="card-code-holder" >
            <input matInput  ngModel name="codeTwo" type="number"  placeholder=""  #input2  maxlength="1" [appAutoTab]="input3">
          </mat-card>

          <mat-card class="card-code-holder" >
            <input  matInput ngModel name="codeThree" type="number"  placeholder=""   #input3  maxlength="1" [appAutoTab]="input4">
          </mat-card>

          <mat-card class="card-code-holder" >
            <input matInput ngModel name="codeFour" type="number"  placeholder=""    #input4  maxlength="1" [appAutoTab]="input5" >
          </mat-card>

          <mat-card class="card-code-holder" >
            <input matInput ngModel name="codeFive" type="number"  placeholder=""    #input5  maxlength="1"  [appAutoTab]="input5">
          </mat-card>

        </ng-container>

      </div>

      <div *ngIf="verifySpinnerShow" fxLayoutGap="10px" fxLayoutAlign="center center" style="margin-top: 20px;">
        <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
      </div>

      <div fxLayoutGap="12px" fxLayoutAlign="center center"  fxLayout.gt-sm="row">
        <button class="select"  type="submit"  mat-button>VERIFY</button>
      </div>



  </form >
  </div>
</section>


