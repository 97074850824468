import { Component, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import ResizeObserver from 'resize-observer-polyfill';
import { Router } from '@angular/router';
import { BreakpointService } from './services/breakpoint/breakpoint.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'clearaccess-angular';
  public smallScreen: boolean = false;

  constructor(
    private router: Router,
    private breakpointService: BreakpointService
  ) {
    // indexedDB.databases()
    //   .then((db) => {
    //     console.log('Db\'s', db);
    //     db.forEach(db => {
    //       console.log(db);
    //       const deleteDb = indexedDB.deleteDatabase(db.name);
    //       deleteDb.onsuccess = (success) => {
    //         console.log('success?', success);
    //       };
    //       deleteDb.onerror = (error) => {
    //         console.log('error?', error);
    //       };
    //       deleteDb.onblocked = (blocked) => {
    //         console.log('blocked?', blocked);
    //       };
    //     });
    //   });

    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

  }

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  ngOnInit(): void {
    // this.router
    //   .navigate(['/signup/check-coverage']);
    // const observer = new ResizeObserver((mutations) => {
    //   console.log('mutations', mutations);
    //   window.parent.postMessage(
    //     JSON.stringify({ type: 'iframeheight', height: mutations[0].contentRect.height }),
    //     window.location !== window.parent.location ? document.referrer : document.location.href
    //   );
    // });
    // // start observing
    // console.log(document.getElementById('clearaccess-angular'));
    // observer.observe(document.getElementById('clearaccess-angular'));
  }
}
