import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { SetError, ClearErrorsByTypeAndStack } from './app.actions';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

export interface AppErrors {
  [stack: string]: AppError[];
}

export interface AppError {
  message: string;
  title?: string;
  status_code: number;
  type: string;
  duration: number;
  style: 'snackbar' | 'dialog';
  color: string;
}

export class AppStateModel {
  errors: AppErrors;
}

// export class AppStateModel {
//   errors: {
//     default: [
//       {
//         message: string;
//         status_code: number;
//         type: string;
//         duration: 5000;
//         color: 'red';
//       }
//     ];
//   };
// }

@State<AppStateModel>({
  name: 'app',
  defaults: {
    errors: {},
  },
})
@Injectable()
export class AppState {
  @Selector()
  static errors(state: AppStateModel): AppErrors {
    return state.errors;
  }

  static errorsByStack(stack: string): (state: AppStateModel) => AppError[] {
    return createSelector([AppState], (state: AppStateModel) => {
      return state.errors[stack];
    });
  }

  static errorsByTypeAndStack(stack: string, type: string): (state: AppStateModel) => AppError[] {
    return createSelector([AppState], (state: AppStateModel) => {
      return state.errors[stack].filter((error) => error.type === type);
    });
  }

  constructor() {}

  @Action(SetError)
  setError({ patchState, getState }: StateContext<AppStateModel>, { payload }: SetError): void {
    const stateErrors = getState().errors;
    let combined: AppErrors;
    if (stateErrors[payload.stack]) {
      combined = { ...stateErrors, [payload.stack]: [...stateErrors[payload.stack], payload.error] };
    } else {
      combined = { ...stateErrors, [payload.stack]: [payload.error] };
    }

    // const combined: AppErrors = { ...stateErrors, [payload.stack]: [...stateErrors[payload.stack], payload.error] };

    patchState({ errors: combined });
  }

  @Action(ClearErrorsByTypeAndStack)
  clearErrorsByType({ patchState, getState }: StateContext<AppStateModel>, { payload }: ClearErrorsByTypeAndStack): void {
    const errors = getState().errors;

    if (errors && errors[payload.stack]) {
      const validErrors = errors[payload.stack].filter((error) => error.type !== payload.type);
      const validErrorsAll = { ...errors, [payload.stack]: validErrors };
      patchState({ errors: validErrorsAll });
    }
  }
}
