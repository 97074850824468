// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   baseUrl: 'https://angular.clearaccess.co.za/api/clear-access/api/v1', // REMEMBER TO REMOVE & CHANGE BACK TO LOCALHOST
   
  //baseUrl: 'http://localhost:4200/clear-access/api/v1',
  // eastUrl: 'http://clearaccess.28east.co.za/dev',
   eastUrl: 'http://clearaccess.28east.co.za',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
