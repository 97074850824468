import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EstCustomerInterface } from '../interfaces/estCustomerInterface';


import {catchError, retry} from 'rxjs/operators';
import {SmsPortalInterface} from '../interfaces/SmsPortalInterface';


@Injectable({
  providedIn: 'root',
})
export class SMSPortalService {
  constructor(
    public http: HttpClient,
  ) {
  }

  baseUrl = environment.baseUrl + '/verifyOtp';


// tslint:disable-next-line:typedef
  postOTP(smsOtp: SmsPortalInterface) {

    return this.http.post(this.baseUrl, smsOtp)
      .pipe( catchError(this.handleError) );
  }

  // tslint:disable-next-line:typedef
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('OTP Verification Failed, please try again later.'));
  }
}

