import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-maintenance',
  templateUrl: './online-maintenance.component.html',
  styleUrls: ['./online-maintenance.component.scss']
})
export class OnlineMaintenanceComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
