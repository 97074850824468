import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  showSnackBar(message: string, action?: string, verticalPosition?: MatSnackBarVerticalPosition, horizontalPosition?: MatSnackBarHorizontalPosition, timer?: number) {
    this.snackBar.open(message, action ? action : '', {
      duration: timer ? timer : 4000,
      horizontalPosition: horizontalPosition ? horizontalPosition : 'center',
      verticalPosition: verticalPosition ? verticalPosition : 'bottom'
    });
  }
}
