<div  *ngIf="!smallScreen" style="position: fixed; z-index: +1; width: 100%; height: 65px; padding: 8px; color: white; background: transparent linear-gradient(180deg, #3380F5 0%, #69E5F5 100%) 0% 0% no-repeat padding-box; display: flex; justify-content: space-around; align-items: center;">

  <div  style="display: flex; font-size: 16px; margin-bottom: 0;">
    <!--    <p style="margin-bottom: 0;">Residential Fibre |</p>-->

    <!--    hide/show relevant descriptive text based on url estates or residential-->
    <p style="margin-bottom: 0;" *ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)">Residential Fibre |</p>

    <!--estate fibre text please select-->
    <p style="margin-bottom: 0;" *ngIf="[
  '/estate-picker'
    ].includes(router.url)"> Estate Fibre  <span style="font-weight: 600">| Please select an estate </span> </p>
    <!--    Estates Fibre  |-->



    <!--estate fibre text display selected estate name and flow step-->
    <p style="margin-bottom: 0;" *ngIf="[
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)">  {{this.selectedEstate}} <span style="font-weight: 600"> | {{displayStepName()}} </span> </p>
    <!--    Estates Fibre  |-->

    <!--    hide/show Check your coverage text based on url-->
    <p style="margin-left: 4px; margin-bottom: 0;" *ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)"><strong>{{ step ? step : 'Loading...' }}</strong>

      <!--    <p style="margin-left: 4px; margin-bottom: 0;"><strong>{{ step ? step : 'Loading...' }}</strong>-->
  </div>

  <!--   switch to business button and also hide/show button while navigating estate options-->
  <button  *ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
  ].includes(router.url)"
           style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 8px 24px 8px 24px; color: #3380F5; border-radius: 24px; font-size: 16px; cursor: pointer;"
           (click)="navbarButtonClick()">

    <!--    style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 8px 24px 8px 24px; color: #3380F5; border-radius: 24px; font-size: 16px; cursor: pointer;"-->
    <!--    (click)="navbarButtonClick()"-->
    {{ determineButtonFunctionality() }}
  </button>

  <!-- toggle hide show for back button and display back to estate or go back options-->
  <button  *ngIf="[

  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',

    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification'

    ].includes(router.url)"
           style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 8px 24px 8px 24px; color: #3380F5; border-radius: 24px; font-size: 16px; cursor: pointer;"
           backButton>GO BACK</button>

  <!--back to estate option for levy /century estates toggle hide/show-->
  <button  *ngIf="[
    '/estate-picker/lev-est-select-package',
   '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-signup-success',
     '/estate-picker/lev-est-signup-success'
  ].includes(router.url)"
           style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 8px 24px 8px 24px; color: #3380F5; border-radius: 24px; font-size: 16px; cursor: pointer;"
           (click)="backToEstates()">BACK TO ESTATES</button>

</div>
<div *ngIf="smallScreen" style="position: fixed;  z-index: +1; width: 100%;  height: 48px; padding: 6px; color: white; background: transparent linear-gradient(180deg, #3380F5 0%, #69E5F5 100%) 0% 0% no-repeat padding-box; display: flex; justify-content: space-around; align-items: center;">
  <div style="display: flex; flex-direction: column; font-size: 14px; margin-bottom: 0;">
    <!--hide or show residential tex on small screens-->
    <p style="margin-bottom: 0;" *ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)">Residential Fibre</p>


    <!--estate fibre text small screen-->
    <!--estate fibre text please select-->
    <p style="margin-bottom: 0;" *ngIf="[
  '/estate-picker'
    ].includes(router.url)"> Estate Fibre  <span style="font-weight: 600; font-size:10px">| Please select an estate </span> </p>
    <!--    Estates Fibre  |-->



    <p style="margin-bottom: 0;" *ngIf="[
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)"> {{selectedEstate}}</p>
    <!--hide or show switch to business fibre on small screens-->
    <p style="margin-bottom: 0;"*ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification',
    '/estate-picker/cent-est-signup-success'
    ].includes(router.url)"><strong>{{ step ? step : 'Loading...' }}</strong>
  </div>
  <button *ngIf="!['/estate-picker',
  '/estate-picker/lev-est-select-package',
  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-signup-success',

  '/estate-picker/cent-est-select-package',
  '/estate-picker/cent-est-install-option',
  '/estate-picker/cent-est-device-options',
  '/estate-picker/cent-est-signup',
  '/estate-picker/cent-est-terms-co',
  '/estate-picker/cent-est-debitorder-mandate',
  '/estate-picker/cent-est-verification',
  '/estate-picker/cent-est-signup-success'
  ].includes(router.url)"
          [style.padding]="determineButtonFunctionality() === 'GO BACK' ? '8px 16px 8px 16px' : '4px 6px 4px 6px'"
          style="background-color: white; border: 0 !important; color: #3380F5; border-radius: 24px; font-size: 14px; cursor: pointer;"
          (click)="navbarButtonClick()"
  >
    <p style="padding: 0 !important; margin: 0 !important; max-width: 150px; font-weight: 500;">
      {{ determineButtonFunctionality() }}
    </p>
  </button>

  <button  *ngIf="[

  '/estate-picker/lev-est-device-options',
  '/estate-picker/lev-est-signup',
  '/estate-picker/lev-est-terms-co',
  '/estate-picker/lev-est-debitorder-mandate',
  '/estate-picker/lev-est-verification',
  '/estate-picker/lev-est-verification',


    '/estate-picker/cent-est-install-option',
    '/estate-picker/cent-est-device-options',
    '/estate-picker/cent-est-signup',
    '/estate-picker/cent-est-terms-co',
    '/estate-picker/cent-est-debitorder-mandate',
    '/estate-picker/cent-est-verification'

    ].includes(router.url)"
           style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 4px 8px 4px 8px; color: #3380F5; border-radius: 24px; font-size: 12px; cursor: pointer;"
           backButton>GO BACK</button>
  <!--back to estate option for levy /century estates toggle hide/show small screen-->
  <button  *ngIf="[
  '/estate-picker/lev-est-select-package',
   '/estate-picker/cent-est-select-package',
    '/estate-picker/cent-est-signup-success',
     '/estate-picker/lev-est-signup-success'].includes(router.url)"
           style="font-family: Montserrat, sans-serif !important; background-color: white; border: 0 !important; padding: 4px 8px 4px 8px; color: #3380F5; border-radius: 24px; margin-right: 12px; font-size: 10px; cursor: pointer;"
           (click)="backToEstates()">BACK TO ESTATES</button>
</div>


