import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appAutoFocusDirective]'
})
export class AutoFocusDirective {

  constructor(private el: ElementRef) {}

  @HostListener('submit')

  // tslint:disable-next-line:typedef
  onFormSubmit() {
    const invalidInputControl = this.el.nativeElement.querySelector(
      '.ng-invalid'
    );

    if (invalidInputControl) {
      invalidInputControl.focus();
    }
  }
}
