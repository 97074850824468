import { Component, OnInit } from '@angular/core';
import {EstatePickerComponent} from '../estate-picker/estate-picker.component';
import {EstCustomerInterface} from '../../../interfaces/estCustomerInterface';
import {EstCustomerService} from '../../../services/estCustomer.service';
import {SharedService} from '../../../services/shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CentEstSignupComponent} from '../cent-est-signup/cent-est-signup.component';
import {SmsOTPGenerateService} from '../../../services/smsOTPGenerate.service';

@Component({
  selector: 'app-cent-est-verification',
  templateUrl: './cent-est-verification.component.html',
  styleUrls: ['./cent-est-verification.component.scss']
})
export class CentEstVerificationComponent implements OnInit {
  zero: any;
  // tslint:disable-next-line:typedef
  name: any;
  // tslint:disable-next-line:typedef
  pin: any;

  OTPMatch: boolean;

  newSubscription: EstCustomerInterface;
  verifySpinnerShow = false;

  form: any;
  generatedOTP: string;
  resentOTP: any;

  inputOTP: string;
  codeOne: number;
  codeTwo: number;
  codeThree: string;
  codeFour: string;
  codeFive: string;


  constructor(
    private pick: EstatePickerComponent,
    private centEstSignupComponent: CentEstSignupComponent,
    private estCustomerService: EstCustomerService,
    private Shared: SharedService,
    public router: Router,
    private route: ActivatedRoute,
    private smsOTPGenerateService: SmsOTPGenerateService,
  ) {
    // set or carry over state of previous page form data from signup page after routing
    this.form = this.router.getCurrentNavigation().extras.state.newSubscription;
  }

  ngOnInit(): void {

    this.Shared.getNewSubscription();
    this.Shared.mandateInfo.subscribe(
      data =>
        this.Shared.setFormMandate(this.newSubscription)
    );
    this.newSubscription = this.Shared.getNewSubscription();
    this.generatedOTP = this.Shared.generatedOTP;
  }

  // GET RECIEVED SMS  OTP INPUT FROM USER and Compare with Sent OTP
  // tslint:disable-next-line:typedef
  getOTP(smsOTPForm){
    this.inputOTP = smsOTPForm.codeOne + '' + smsOTPForm.codeTwo + '' + smsOTPForm.codeThree + '' +  smsOTPForm.codeFour + '' + smsOTPForm.codeFive;

    if (this.inputOTP !== this.generatedOTP) {
      alert('Invalid OTP , please re-enter or use resend option');
    }
    else{
      this.verifySpinnerShow = true;
      // SUBMIT FORM INPUT DATA FROM SIGNUP
      this.estCustomerService.postFormData(this.form).subscribe((data) => {
        this.Shared.setFormMandate(smsOTPForm);
        this.pick.goToNextCentury();
      });
    }
  }

//  Resend OTP Code pulled from signup page
  // tslint:disable-next-line:typedef
  resendCode() {
    this.smsOTPGenerateService.resendOTP(this.form.cellNumber, this.form.firstName, this.form.lastName);
    this.generatedOTP = this.Shared.generatedOTP;
    alert('Your code has been resent');
  }

  // Auto Tab input
  // tslint:disable-next-line:typedef
  next(pin, length, curId) {
    if (pin.toString().length === length && curId !== 3) {
      document.getElementById((curId + 1).toString()).focus();
    }
  }
}


