import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  public termsAndConditionsDocument: Subject<File> = new Subject<File>();
  public debitOrderMandateDocument: Subject<File> = new Subject<File>();

  constructor() { }

  setTermsDocument(document: File): void {
    this.termsAndConditionsDocument.next(document);
  }

  setDebitOrderMandate(document: File): void {
    this.debitOrderMandateDocument.next(document);
  }
}
