import { Component, OnInit } from '@angular/core';
import {SharedService} from '../../../services/shared.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavbarComponent} from '../../navbar/navbar.component';
import {EstGetEstateService} from '../../../services/estGetEstateService';


@Component({
  selector: 'app-estate-picker',
  templateUrl: './estate-picker.component.html',
  styleUrls: ['./estate-picker.component.scss']
})
export class EstatePickerComponent implements OnInit {

  public estateOptions = [];

  constructor(
    private estGetEstateService: EstGetEstateService,
    private Shared: SharedService,
    public router: Router,
    private route: ActivatedRoute,
    private nav: NavbarComponent,
  ) {
  }

  ngOnInit(): void {
    // get data and name of estate to display during navigation and ad to mandate as address
    this.estGetEstateService.getEstateOptionInfo()
      .subscribe(data => {
        this.estateOptions = data;
        // set subrcibed data to add to Debit mandate address
        this.Shared.setEstateAddress(this.estateOptions);
      });
  }

  // tslint:disable-next-line:typedef
  onClicked(estate) {
    this.returnEstate();
    // setDevice set selected device value to be displayed on form
    this.Shared.setSelectedEstate(estate);
    this.estateType(estate);
  }

  //  return  string format data from server
  returnEstate(): string {
    return `${this.estateOptions[1].img}-${this.estateOptions[1].heading}-${this.estateOptions[1].description}`;
  }

  // tslint:disable-next-line:typedef
  goToNextCentury() {
    if (this.router?.url.toLowerCase() === '/estate-picker') {
      this.router.navigate(['cent-est-select-package'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-select-package') {
      this.router.navigate(['cent-est-install-option'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-install-option') {
      this.router.navigate(['cent-est-device-options'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-device-options') {
      this.router.navigate(['cent-est-signup'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-signup') {
      this.router.navigate(['cent-est-verification'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/cent-est-verification') {
      this.router.navigate(['cent-est-signup-success'], {relativeTo: this.route});
    }
  }

  // tslint:disable-next-line:typedef
  goToNextLev() {
    if (this.router?.url.toLowerCase() === '/estate-picker') {
      this.router.navigate(['lev-est-select-package'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-select-package') {
      this.router.navigate(['lev-est-signup'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-signup') {
      this.router.navigate(['lev-est-verification'], {relativeTo: this.route});
    } else if (this.router?.url.toLowerCase() === '/estate-picker/lev-est-verification') {
      this.router.navigate(['lev-est-signup-success'], {relativeTo: this.route});
    }
  }

  // tslint:disable-next-line:typedef
  estateType(estate: any) {
    if (estate.type === 'levy') {
      this.goToNextLev();
    } else {
      this.goToNextCentury();
    }
  }

}
