/* tslint:disable:prefer-const */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material/dialog';
// import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import { ProviderStateModel } from 'src/app/components/shared/map.state';
import { Tariff } from 'src/app/interfaces/Tariff';
import { BreakpointService } from 'src/app/services/breakpoint/breakpoint.service';
import { StepService } from 'src/app/services/step.service';
import { TariffsStateModel } from 'src/app/store/tariffs/tariffs.state';
import {EvotelPromoComponent} from '../../../../evotel-promo/evotel-promo.component';
import dayjs from 'dayjs';
import {OctotelPromoComponent} from '../../../../octotel-promo/octotel-promo.component';

@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrls: ['./select-package.component.scss']
})
export class SelectPackageComponent implements OnInit, OnDestroy {

  // @Select('providers') providers$: Observable<ProviderStateModel>;
  @Select('tariffs') tariffs$: Observable<TariffsStateModel>;
  private destroy: Subject<boolean> = new Subject<boolean>();
  tariffsObservable$: Observable<TariffsStateModel>;
  providers: ProviderStateModel;
  tariffs: TariffsStateModel;
  tariffsSplit: Tariff[][] = [[]];
  selectedTariff: Tariff;
  connectivityProviderPromo = [];

  hasPromoDisplayed = false;

  public smallScreen = false;
  public breakpointSubscription: Subscription;

  // DOUBLE UP PROMO TEMPORARY
  // public isDoubleUpPromo = false;

  constructor(
    private store: Store,
    private dialogRefTwo: MatDialogRef<OctotelPromoComponent>,
    private dialogRefThree: MatDialogRef<EvotelPromoComponent>,
    private dialogRef: MatDialogRef<SelectPackageComponent>,
    private stepService: StepService,
    private breakpointService: BreakpointService,
    private dialog: MatDialog,
    private dialogOctotel: MatDialog,
    private dialogEvo: MatDialog,
  ) {
    this.smallScreen = breakpointService.isSmallScreen;
    this.breakpointSubscription = this.breakpointService.smallScreen
      .subscribe({
        next: (isSmallScreen: boolean) => this.smallScreen = isSmallScreen,
        error: (err) => console.error(err)
      });

    // Size
    // console.log('@@@@@@ BEGIN @@@@@');
    // console.log('Dialog ref,', dialogRef);
    this.stepService
      .setStep('Select Your Package');

    this.tariffs$.pipe(takeUntil(this.destroy)).subscribe((tariffs) => {

      if (tariffs && tariffs.byArray && tariffs.byArray.length > 0) {
        this.tariffs = tariffs;

        let providerName;
        let tariffsSplit: Tariff[][] = [[]];
        let tariff: Tariff[] = [];
        // this.connectivityProviderPromo = [];

        // split by provider
        for (const elt of tariffs.byArray) {
          if (providerName && elt.connectivity_provider === providerName) {
            // Same as previous
            tariff.push(elt);
            // check if last element
            if (tariffs.byArray.indexOf(elt) === (tariffs.byArray.length - 1)) {
              // Last element of array
              tariffsSplit.push(tariff);
              tariff = [];
            }
          } else {
            // New provider
            // Push into old
            if (tariff && tariff.length > 0) {
              if (tariffsSplit.length === 1) {
                tariffsSplit = [tariff];
              } else {
                tariffsSplit.push(tariff);
              }
            }
            // Make new
            tariff = [];
            tariff.push(elt);
          }
          providerName = elt.connectivity_provider;
        }
        tariffsSplit = tariffsSplit.filter(t => t.length > 0);
        this.tariffsSplit = tariffsSplit;
        // console.log(this.tariffsSplit, 'THIS IS TARRIF SPLIT #######');

        // If clear access remove the rest
        for (const t of tariffsSplit) {
          console.log('Tariff split is clear access', t.filter(prov => prov.connectivity_provider.toLowerCase() === 'clear access'));
          if (t.filter(prov => prov.connectivity_provider.toLowerCase() === 'clear access').length > 0) {
            this.tariffsSplit = [t.filter(prov => prov.connectivity_provider.toLowerCase() === 'clear access')];
            console.log('CLEAR ACCESS IS PROVIDED:', this.tariffsSplit);
          }
        }
      }
    });


    let counter = 0;
    this.tariffs$.subscribe((currentTarrifs) => {
      this.connectivityProviderPromo = [];
      if (currentTarrifs.selectedProvider != null && counter > 1) {
        currentTarrifs.byArray.forEach(currentTariff => {
          let provider = currentTariff.connectivity_provider;
          if (!this.connectivityProviderPromo.includes(provider)) {
            this.connectivityProviderPromo.push(provider);
          }
        });
        this.openPromoDialog();
      }
      counter++;
    });

  }
  // PROMO POP UP LOGIC BELOW
  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  openPromoDialog() {
    // OPEN Octotel POP UP PROMO

    if ((dayjs().isBefore(dayjs( '2023-02-29'))) && this.connectivityProviderPromo.includes('Octotel') && !this.hasPromoDisplayed){
      this.hasPromoDisplayed = true;
      this.dialogRefTwo = this.dialogOctotel
          .open(OctotelPromoComponent, {
            disableClose: true,
            maxWidth: '950px',
          });
      }

    // OPEN EVOTEL POP UP
    // else if ((dayjs('2022-11-30').isBefore(dayjs( '2022-12-01'))) && this.connectivityProviderPromo.includes('EVOTel') && !this.hasPromoDisplayed) {
    else if ((dayjs().isBefore(dayjs( '2022-12-01'))) && this.connectivityProviderPromo.includes('EVOTel') && !this.hasPromoDisplayed) {
      this.hasPromoDisplayed = true;
      this.dialogRefThree = this.dialogEvo
        .open(EvotelPromoComponent, {
          disableClose: true,
          maxWidth: '950px',
        });
    }
  }


  chooseTariff(tariff: Tariff): void {
    // console.log(tariff, this.dialogRef);
    this.selectedTariff = tariff;
    this.dialogRef.close(tariff);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkElementMod(index: number): boolean {
    console.log(index);
    return index % 2 === 0;
  }

  ngOnDestroy(): void {
    console.log('Destroy select package...');
    this.breakpointSubscription.unsubscribe();
    this.destroy.next(true);
    this.destroy.unsubscribe();
    this.dialogRef.close(this.selectedTariff);
  }

}
