import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxsModule } from '@ngxs/store';

import { ProviderState } from 'src/app/components/shared/map.state';

// material
import { MatCardModule } from '@angular/material/card';
import { environment } from 'src/environments/environment';
import { SetProviders } from './components/shared/map.actions';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BusinessComponent } from './components/business/business.component';
import { GenericFormComponent } from './generic-form/generic-form.component';
import { TariffsState } from './store/tariffs/tariffs.state';
import { AddressState } from './store/address/address.state';
import { AppState } from './store/app/app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ErrorComponent } from './error/error.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CheckCoverageComponent } from './components/check-coverage/check-coverage.component';
import { SelectPackageComponent } from './components/residential/residential/select-package/select-package.component';
import { SignUpComponent } from './components/residential/residential/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/residential/residential/terms-conditions/terms-conditions.component';
import { DebitOrderComponent } from './components/residential/residential/debit-order/debit-order.component';
import { SignUpCompleteComponent } from './components/residential/residential/sign-up-complete/sign-up-complete.component';
import { ResidentialComponent } from './components/residential/residential/residential.component';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StepService } from './services/step.service';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { DocumentsService} from './services/documents-service/documents.service';
import { BreakpointService } from './services/breakpoint/breakpoint.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { LevEstSelectPackageComponent } from './components/estates/lev-est-select-package/lev-est-select-package.component';
import { LevEstDeviceOptionsComponent } from './components/estates/lev-est-device-options/lev-est-device-options.component';
import { LevEstSignupComponent } from './components/estates/lev-est-signup/lev-est-signup.component';
import { LevEstTermsCoComponent } from './components/estates/lev-est-terms-co/lev-est-terms-co.component';
import { LevEstDebitorderMandateComponent } from './components/estates/lev-est-debitorder-mandate/lev-est-debitorder-mandate.component';
import { LevEstVerificationComponent } from './components/estates/lev-est-verification/lev-est-verification.component';
import { LevEstSignupSuccessComponent } from './components/estates/lev-est-signup-success/lev-est-signup-success.component';
import { CentEstSelectPackageComponent } from './components/estates/cent-est-select-package/cent-est-select-package.component';
import { CentEstInstallOptionComponent } from './components/estates/cent-est-install-option/cent-est-install-option.component';
import { CentEstDeviceOptionsComponent } from './components/estates/cent-est-device-options/cent-est-device-options.component';
import { CentEstSignupComponent } from './components/estates/cent-est-signup/cent-est-signup.component';
import { CentEstTermsCoComponent } from './components/estates/cent-est-terms-co/cent-est-terms-co.component';
import { CentEstDebitorderMandateComponent } from './components/estates/cent-est-debitorder-mandate/cent-est-debitorder-mandate.component';
import { CentEstVerificationComponent } from './components/estates/cent-est-verification/cent-est-verification.component';
import { CentEstSignupSuccessComponent } from './components/estates/cent-est-signup-success/cent-est-signup-success.component';
import { OnlineMaintenanceComponent } from './components/online-maintenance/online-maintenance.component';
import {AutoTabDirective} from './auto-tab-directive';

import {EstCustomerService} from './services/estCustomer.service';
import {SharedService} from './services/shared.service';
import { EstatePickerComponent } from './components/estates/estate-picker/estate-picker.component';
import {BackButtonDirective} from './Directives/backButtonDirective';
import {AutoFocusDirective} from './Directives/auto-focus-directive';

import {EvotelPromoComponent} from './evotel-promo/evotel-promo.component';
import {OctotelPromoComponent} from './octotel-promo/octotel-promo.component';


@NgModule({
  declarations: [AppComponent, GenericFormComponent, ErrorComponent,
    CheckCoverageComponent, SelectPackageComponent, SignUpComponent, TermsConditionsComponent, DebitOrderComponent,
    SignUpCompleteComponent, ResidentialComponent, NavbarComponent,
    LevEstSelectPackageComponent, LevEstDeviceOptionsComponent,
    LevEstSignupComponent, LevEstTermsCoComponent, LevEstDebitorderMandateComponent,
    LevEstVerificationComponent, LevEstSignupSuccessComponent, CentEstSelectPackageComponent,
    CentEstInstallOptionComponent, CentEstDeviceOptionsComponent, CentEstSignupComponent,
    CentEstTermsCoComponent, CentEstDebitorderMandateComponent, CentEstVerificationComponent,
    CentEstSignupSuccessComponent, AutoTabDirective, BackButtonDirective,  OnlineMaintenanceComponent,
    EstatePickerComponent, BusinessComponent, EvotelPromoComponent, OctotelPromoComponent, AutoFocusDirective],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    HttpClientModule,
    MatSnackBarModule,
    MatExpansionModule,
    FlexLayoutModule,
    NgxsModule.forRoot([ProviderState, TariffsState, AddressState, AppState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ScrollingModule,
    MatDialogModule,
    // AgmCoreModule.forRoot({
    //   apiKey: '',
    // }),
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    },
    StepService,
    SnackBarService,
    DocumentsService,
    BreakpointService,
    NavbarComponent,
    LevEstTermsCoComponent,
    LevEstDebitorderMandateComponent,
    LevEstSignupComponent,
    CentEstSignupComponent,
    CentEstTermsCoComponent,
    CentEstDebitorderMandateComponent,
    EstCustomerService,
    SharedService,
    EstatePickerComponent
  ],
  bootstrap: [AppComponent],
  entryComponents: [LevEstTermsCoComponent, LevEstDebitorderMandateComponent,
    CentEstTermsCoComponent, CentEstDebitorderMandateComponent, CentEstSignupComponent,
   ]
})
export class AppModule {}
