import { Geometry } from 'src/app/interfaces/Providers';

export class Address {
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  geometry?: Geometry;

  constructor(
    streetNumber: string,
    streetName: string,
    suburb: string,
    city: string,
    province: string,
    postalCode: string,
    country: string,
    geometry?: Geometry
  ) {
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.suburb = suburb;
    this.city = city;
    this.province = province;
    this.postalCode = postalCode;
    this.country = country;
    if (geometry) {
      this.geometry = geometry;
    }
  }

  // public getStreet_number(): string {
  //   return this.streetNumber;
  // }

  // public setStreet_number(streetNumber: string): void {
  //   this.streetNumber = streetNumber;
  // }

  // public getStreet_name(): string {
  //   return this.streetName;
  // }

  // public setStreet_name(streetName: string): void {
  //   this.streetName = streetName;
  // }

  // public getSuburb(): string {
  //   return this.suburb;
  // }

  // public setSuburb(suburb: string): void {
  //   this.suburb = suburb;
  // }

  // public getCity(): string {
  //   return this.city;
  // }

  // public setCity(city: string): void {
  //   this.city = city;
  // }

  // public getProvince(): string {
  //   return this.province;
  // }

  // public setProvince(province: string): void {
  //   this.province = province;
  // }

  // public getPostal_code(): string {
  //   return this.postalCode;
  // }

  // public setPostal_code(postalCode: string): void {
  //   this.postalCode = postalCode;
  // }

  // public getCountry(): string {
  //   return this.country;
  // }

  // public setCountry(country: string): void {
  //   this.country = country;
  // }

  // public getGeometry(): Geometry {
  //   return this.geometry;
  // }

  // public setGeometry(geometry: Geometry): void {
  //   this.geometry = geometry;
  // }
}
